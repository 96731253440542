.module-back-to-top {
  .module {
    &__action {
      background: $color-secondary;
      &:hover {
        border-color: $color-secondary;
        background: $color-secondary;
      }
    }
  }
}
