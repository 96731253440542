.table {
  .table-caption {
    @include sr-only();
  }
  th, td {
    width: auto;
  }
  th {
    border-top: none;
    border-bottom: 1px solid $color-grey;
  }
}

@include media-breakpoint-down(md) {
  .table {
    &:not(.totals):not(.cart):not(.table-comparison) {
      > thead > tr > th {
        display: none;
      }
      > tbody {
        > tr {
          padding-bottom: 15px;
          padding-top: 15px;
          //margin-top: 20px;
          & + tr {
            border-top: 1px solid $color-grey-light;
          }
          > td {
            display: table-cell;
            border-top: none;
            padding: 10px;
            &:last-child {
              padding-bottom: 10px;
            }
            &:before {
              //padding-right: 10px;
              //content: attr(data-th) ': ';
              //display: inline-block;
              //color: $color-black;
              //font-weight: bold;
            }
            & + td {
              &:first-child {
                padding-top: 15px;
                border-top: 1px solid;
              }
            }
          }
          > th{
            &:after{
              content: ':';
            }
          }
        }
      }
    }
  }
}
