.search {
    &.results {
        width: 100%;
    }
}

.page-header {
    position: static;

    .header__top {
        position: fixed;
        background-color: $color-white;
        z-index: 80;
        left: 50%;
        transform: translate(-50%, 0);

        &--left {
            @include make-col-ready();
            display: flex;
            padding-left: 0;
            padding-right: 0;
            align-items: center;

            .block-search {
                display: inline-flex;
                border: none;
                width: 100%;
                max-width: 460px;
                padding-bottom: 2px;

                .block-content {
                    width: 100%;
                    position: relative;

                    .simple {
                        border-bottom: 1px solid $color-border-search;
                        padding: 0;

                        .searcher-text {
                            order: 2;
                            padding-left: 10px;
                        }

                        .icon-search {
                            margin: auto 0 12px 5px;
                            order: 1;
                        }
                    }

                    .radius {
                        border: 1px solid $color-border-search;
                        border-radius: 2rem;
                        justify-content: space-between;
                        padding: 0 10px;

                        .searcher-text {
                            order: 1;
                        }

                        .icon-search {
                            margin: auto 5px;
                            order: 2;
                        }
                    }

                    .topSearchBig {
                        display: flex;
                        height: 40px;
                        color: $color-primary;

                        width: 100%;
                        text-align: left;
                        font-size: 19px;
                        background-color: transparent;
                        font-family: $font-primary;

                        span {
                            display: block;
                            margin: auto 0;
                        }

                        .icon-search:before {
                            content: "\e904";
                            color: $color-primary;
                        }
                    }

                    .minisearch {
                        &__content {
                            position: fixed;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.9);
                            z-index: $zindex-fixed;
                            opacity: 0;
                            visibility: hidden;
                            transform: scale(0.7);
                            @include transition(all, 1s);

                            &-inner {
                                @include make-row();
                                @extend .align-items-center;
                                justify-content: center;
                                height: 100%;

                                .form {
                                    @include make-col-ready();
                                    @include make-col(8);
                                    text-align: center;
                                }
                            }

                            .minisearch__logo {
                                position: absolute;
                                left: 140px;
                                top: 27px;
                                width: 100%;
                                max-width: 200px;
                            }

                            .minisearch__close {
                                position: absolute;
                                right: 20px;
                                top: 20px;
                                cursor: pointer;
                                display: block;
                                color: $color-white;
                                font-family: $font-primary;
                                font-size: 14px;

                                span {
                                    margin-right: 5px;
                                }

                                i {
                                    font-size: 18px;
                                }
                            }

                            .field {
                                .control {
                                    display: flex;
                                    align-items: center;
                                    border-bottom: 1px solid $color-grey;
                                }

                                label {
                                    font-size: 28px;

                                    i {
                                        margin-right: 15px;
                                    }
                                }

                                input {
                                    width: 100%;
                                    border: none;
                                    font-size: 15px;
                                    background-color: transparent;
                                    padding: 15px;
                                    color: $color-white;
                                    font-weight: $font-weight-light;

                                    &:focus {
                                        outline: none;
                                        -webkit-box-shadow: none;
                                        -moz-box-shadow: none;
                                        box-shadow: none;
                                    }
                                }

                                .search-autocomplete {
                                    color: $color-white;
                                    top: 65px;
                                    padding-left: 30px;

                                    ul li {
                                        padding: 5px;
                                        cursor: pointer;

                                        + li {
                                            border-top: 1px solid $color-grey;
                                        }

                                        .amount {
                                            margin-left: 10px;

                                            &:after {
                                                content: ")";
                                                margin-left: 3px;
                                            }

                                            &:before {
                                                content: "(";
                                                margin-right: 3px;
                                            }
                                        }
                                    }
                                }
                            }

                            .actions {
                                .search {
                                    @extend .fa;
                                    @extend .fa-search;
                                    background-color: transparent;
                                    border: none;
                                    font-size: 22px;
                                    color: $color-white;

                                    span {
                                        display: none;
                                    }
                                }
                            }

                            &.active {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }

                        &__trigger {
                            cursor: pointer;
                            margin: 0 5px 0 0;
                        }
                    }
                }
            }
        }
    }

    .header__heading {
        position: relative;

    }
}

@include media-breakpoint-down(lg) {
    .page-header .header__top--left {
        .searcherBig .block-content {
            max-width: 100%;

            .simple .icon-search {
                margin: auto 0 10px 5px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .page-header .header__top--left {
        padding: 0 15px;

        .block-search {
            .block-content {
                .minisearch {
                    &__trigger {
                        height: 35px;
                        line-height: 35px;
                    }
                }
            }
        }
    }
}

@media (max-width: 592px) {
    .page-header .header__top--left {
        max-width: 16.6%;
        padding: 0;

        .searcherBig {
            width: fit-content;

            .block-content {
                .topSearchBig {
                    border: none;
                    margin: 0;
                    padding: 3px 5px;

                    .icon-search {
                        font-size: 30px;
                        margin: auto 5px;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .block-search {
        .block-content {
            .minisearch__content {
                .minisearch__logo {
                    left: 20px;
                    top: 20px;
                }
            }
        }
    }
}
