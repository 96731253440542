body._has-modal {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.modals-overlay {
    z-index: 899;
}

.modal-slide,
.modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;

    .modal-inner-wrap {
        background-color: $color-white;
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
        opacity: 1;
        pointer-events: auto;
    }

    &._show {
        visibility: visible;

        .modal-inner-wrap {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
}

.modal-custom, .modal-popup, .modal-slide {
    .action-close {
        @extend .fa;
        @extend .fa-times;
        border: none;
        background: none;
        font-size: 20px;
        padding: 0;
        position: absolute;
        top: 15px;
        right: 20px;

        > span {
            @extend .sr-only;
        }

        &:before {
            -webkit-font-smoothing: antialiased;
            font-size: 32px;
            line-height: 32px;
            color: #808080;
            content: '\e616';
            font-family: 'Blank-Theme-Icons';
            margin: 0;
            vertical-align: top;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }
    }
}

.modal-slide {
    left: 44px;
    z-index: 900;

    .page-main-actions {
        margin-bottom: -12.9rem;
        margin-top: 2.1rem;
    }

    .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        transition-duration: .3s;
        -webkit-transition-property: -webkit-transform, visibility;
        transition-property: transform, visibility;
        transition-timing-function: ease-in-out;
        width: auto;
    }

    .modal-header, .modal-content, .modal-footer {
        padding: 0 2.6rem 2.6rem;
    }

    .modal-header {
        padding-top: 20px;
        padding-bottom: 15px;
    }

    &._show {
        .modal-inner-wrap {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    &._inner-scroll {
        .modal-inner-wrap {
            overflow-y: visible;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .modal-header, .modal-footer {
            -webkit-flex-grow: 0;
            flex-grow: 0;
            -webkit-flex-shrink: 0;
            flex-shrink: 0;
        }

        .modal-content {
            overflow-y: auto;
        }

        .modal-footer {
            margin-top: auto;
        }
    }
}

.modal-popup {
    z-index: $zindex-modal;
    left: 0;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.5);

    .modal-body {
        text-align: justify;

        .in {
            display: block;
        }
    }

    .modal-title {
        font-weight: 300;
        font-size: 26px;
        margin-bottom: 0;
        min-height: 1em;
        word-wrap: break-word;
        text-align: center;
    }

    .modal-header, .modal-footer {
        -webkit-flex-grow: 0;
        flex-grow: 0;
        -webkit-flex-shrink: 0;
        flex-shrink: 0;
        min-height: 60px;
    }

    .modal-header {
        padding: 0 3rem 15px;

        .modal-title-subtitle {
            margin: auto
        }
    }

    .modal-content {
        padding: 3rem 3rem 2rem;
    }

    .modal-footer {
        margin-top: auto;
        padding: 20px 3rem 2rem;
        display: block;

        &-actions {
            text-align: right;
        }

        .action, .action-accept, .action-dismiss {
            @extend .btn;

            &.primary {
                order: 2;
                margin: 0 0 0 10px;
            }

            &.btn--tertiary {
                @extend .btn--tertiary;
                border-radius: 5px;
            }
        }

        .modal-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                margin-bottom: 10px;
            }
        }

        .modal-footer-text {
            display: block;
            text-align: center;
            margin-bottom: 20px;
        }

        .btn {
            border-radius: 5px;
        }
    }

    .modal-inner-wrap {
        margin: 5rem auto;
        width: 60%;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        box-sizing: border-box;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        -webkit-transform: translateY(-200%);
        transform: translateY(-200%);
        transition-duration: .2s;
        -webkit-transition-property: -webkit-transform, visibility;
        transition-property: transform, visibility;
        transition-timing-function: ease;
    }

    &._show {
        .modal-inner-wrap {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }

    &._inner-scroll {
        overflow-y: visible;

        .modal-inner-wrap {
            max-height: 90%;
        }

        .modal-content {
            overflow-y: auto;
        }
    }

    .form-shipping-address {
        max-width: 85%;
    }
}

.modals-overlay {
    background-color: rgba(51, 51, 51, 0.55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

body._has-modal-custom .modal-custom-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 899;
}

.ie10 .modal-popup._inner-scroll,
.ie9 .modal-popup._inner-scroll {
    overflow-y: auto;

    &.modal-inner-wrap {
        max-height: none;
    }
}

@include media-breakpoint-down(md) {
    .modal-popup {
        .modal-inner-wrap {
            width: 95%;
        }

        .form-shipping-address {
            max-width: 100%;
        }

        .modal-header, .modal-content, .modal-footer {
            padding: 2rem;
        }
    }
}
