.fieldset {
  .field {
    &.note {
      //font-size:.8em;
      margin-bottom: 20px;
    }
    &.required, &._required {
      & > .label:after, & > .form-group > .label:after {
        content: '*';
        color: $color-red;
        font-size: inherit;
        margin: 0 0 0 5px;
      }
      .label--legend ~ .required,
      .label--legend ~ ._required {
        & > .label:after, & > .form-group > .label:after {
          display: none;
        }
        & > .form-group {
          margin-bottom: 0;
        }
      }
    }
  }
  & + .fieldset {
    .legend {
      padding-top: 40px;
    }
  }
}

.form-group {
  &._with-tooltip {
    .input-text {
      width: 95%;
      float: left;
    }
    .field-tooltip {
      width: 5%;
      float: left;
      position: relative;

      &-action {
        border: 1px solid;
        display: block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        font-size: 17px;
        margin: 4px 10px;
        padding-left: 7px;
        outline: none;
        cursor: pointer;
        &:before {
          content: '?';
        }
      }
      &-content {
        display: none;
        background-color: $color-white;
        border: 1px solid;
        border-radius: 1px;
        font-size: 14px;
        padding: 12px;
        width: 210px;
        display: none;
        left: -210px;
        position: absolute;
        text-transform: none;
        top: -9px;
        word-wrap: break-word;
        z-index: 2;
      }
      &._active {
        .field-tooltip-content {
          display: block;
        }
      }
    }
    .note {
      float: left;
    }
  }
  .form-control {
    &.qty {
      min-width: 45px;
      max-width: 75px;
      text-align: center;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
  }
}

.form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 12px;
}

.custom-checkbox {
  margin-top: 0.5rem;
  cursor: pointer;
  .custom-control-indicator {
    border: 1px solid $color-grey-dark;
    border-radius: 0;
    background: none;
    width: 20px;
    height: 20px;
    padding: 0 2px;
    display: block;
    float: left;
  }
  .custom-control-input {
    &:checked ~ .custom-control-indicator {
      //background-color: $color-grey-dark;
      @extend .fa-check;
      font-family: $font-icons;
    }
  }
  .custom-control-description {
    font-size: 12px;
    //padding-top: 4px;
    margin-left: 5px;
    line-height: 20px;
    a {
      text-decoration: underline;
    }
  }
  + .mage-error {
    text-align: left;
    padding-left: 25px;
    margin-top: 0;
  }

  .custom-control-label {
    display: inline-flex;
    align-items: center;
    &:before,
    &:after {
      top: unset;
    }
  }
}

.custom-radio {
  cursor: pointer;
  .custom-control-indicator {
    border: 1px solid $color-grey-dark;
    border-radius: 50%;
    background: none;
    width: 15px;
    height: 15px;
    display: block;
    float: left;
  }
  .custom-control-input {
    &:checked ~ .custom-control-indicator {
      //border: 4px solid $color-grey-dark;
      background-color: $color-grey-dark;
      box-shadow: inset 0px 0px 1px 2px white;
    }
  }
  .custom-control-description {
    font-size: 12px;
    padding-top: 2px;
    margin-left: 5px;
    line-height: 20px;
  }
}

.input-text {
  @extend .form-control;
}

.form-inline {
  .label {
    margin-right: 10px;
  }
}

//To show up/down qty selector (Only Chrome)
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

.mage-error[generated] {
  color: $color-red;
  font-size: 12px;
  margin-top: 5px;
}

.password-strength-meter {
  background-color: #f4f4f4;
  height: 32px;
  line-height: 32px;
  padding: 0 9px;
  position: relative;
  z-index: 1;
  margin-top: 5px;
}
.password-strength-meter:before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.password-strength-meter-0 .password-strength-meter:before {
  background-color: #f4f4f4;
  width: 100%;
}
.password-strength-meter-1 .password-strength-meter:before {
  background-color: #ffafae;
  width: 25%;
}
.password-strength-meter-2 .password-strength-meter:before {
  background-color: #ffd6b3;
  width: 50%;
}
.password-strength-meter-3 .password-strength-meter:before {
  background-color: #c5eeac;
  width: 75%;
}
.password-strength-meter-4 .password-strength-meter:before {
  background-color: #81b562;
  width: 100%;
}
