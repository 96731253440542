.customer-account-login, .customer-account-create, .customer-account-forgotpassword, .customer-account-createpassword {
    .columns {
        .main {
            a {
                text-decoration: underline;
            }

            a.btn {
                text-decoration: none;
            }

            form {
                width: 80%;
            }
        }
    }
}

.customer-account-forgotpassword {
    .columns {
        .main {
            .new-container {
                @include make-col-ready();
                @include make-col(12);
                display: flex;
            }

            form {
                @include make-col-ready();
                @include make-col(6);
            }
        }
    }
}

.customer-account-login {
    .login-container {
        @include make-row();

        .block {
            @include make-col-ready();
            @include make-col(6);

            &-title {
                @extend .h2;
                font-weight: $font-weight-regular;
                padding-bottom: 12px;
                margin-bottom: 15px;
                border-bottom: 1px solid $color-grey-light;
            }

            &-content {
                .login-info {
                    padding-top: 25px;
                }

                .warning {
                    color: $color-red;
                }
            }
        }
    }
}

.customer-account-create {
      .mandatory-msg {
        margin-bottom: 30px;

        .customer-form-before {
            color: $color-red;

            a {
                color: $color-red;
            }
        }
    }

    .legend {
        @extend .h2;
        margin: 0 0 25px;

        &:after {
            display: none;
        }
    }

    .register-info {
        float: left;
        width: 100%;
        padding-top: 55px;
    }

    .register-description {
        float: left;
        width: 100%;
    }

    .field {
        margin: 0 0 29px;
    }

    &.account {
        margin-bottom: 0;
    }

    .required-fields {
        color: $color-red;
        float: right;
        width: 100%;
        margin: 30px 0;
        text-align: right;
    }

    .control {
        &.field {
            .mage-error {
                position: absolute;
                top: 25px;
                left: 0;
            }
        }

        &.captcha {
            .nested {
                margin-top: 20px;
            }

            .captcha-reload {
                margin-left: 20px;
            }
        }
    }
}

.customer-account-createpassword {
    .actions-toolbar {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .columns .main form {
        display: block;
    }
}
.customer-account-forgotpassword,
.customer-account-createpassword {
    .forget, .forget-helper, .reset {
        @include make-row();

        .fieldset {
            @include make-col-ready();
            @include make-col(7);

            .legend {
                @extend .h2;
                margin: 0 0 25px;

                &:after {
                    display: none;
                }
            }

            .field {
                margin: 0 0 29px;
            }

            &.account {
                margin-bottom: 0;
            }
        }

        .captcha {
            .control {
                margin-top: 10px;
            }

            .captcha-reload {
                margin-left: 15px;
            }
        }
    }
}

.customer-account-forgotpassword {
    .forget-helper {
        .fieldset {
            border-left: 1px solid #ccc;
        }
    }
}

.customer-account-forgotpassword {
    .forget, .forget-helper, .reset {
        .fieldset {
            @include make-col(12);
        }
    }

    .forget {
        #found-emails li {
            list-style: disclosure-closed;
            padding-left: 5px;
            margin: 7px 0 7px 30px;
        }
    }

    .forget-helper {
        #dataHelper {
            display: none;
        }

        #buttonHelper {
            width: 100%;

            fieldset {
                height: 100%;

                #showFormHelper {
                    padding: 15px 30px;
                }
            }
        }
    }
}

.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword {
    .page-main {
        .columns {
            .column.main {
                @extend %row-disable;
            }
        }
    }

    .actions-toolbar {
        padding-top: 25px;

        .primary {
            float: left;
        }

        .secondary {
            float: right;
        }
    }

    .register-info {
        .list-advantages {
            padding: 20px 0 30px;
            float: left;

            li {
                padding: 5px 0;
                float: left;

                img {
                    float: left;
                    margin-top: 5px;
                    margin-bottom: 15px;
                    margin-right: 13.5px;
                }

                span {
                    line-height: 1.71;
                    display: flex;
                }
            }
        }

        .rectangle {
            float: left;
            width: 100%;
            background-color: $color-grey;
            border: solid 1px $color-grey-dark;
            padding: 24px 25px 29px 25px;
            margin-bottom: 40px;
        }
    }
}

@include media-breakpoint-down(md) {
    .customer-account {
        &-login {
            .login-container {
                .block {
                    @include make-col(12);

                    + .block {
                        margin-top: 25px;
                    }
                }
            }
        }

        &-create,
        &-forgotpassword,
        &-createpassword {
            .form-create-account,
            .forget, .reset {
                .actions-toolbar {
                    -webkit-flex: 1; /* Safari 6.1+ */
                    -ms-flex: 1; /* IE 10 */
                    flex: 1;
                    max-width: 100%;

                    .secondary {
                        line-height: 35px;
                    }
                }

                .fieldset {
                    @include make-col(12);
                }
            }
        }

        &-login,
        &-create,
        &-forgotpassword,
        &-createpassword  {
            .columns {
                .main {
                    form {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .customer-account-forgotpassword {
        .columns {
            .main {
                .new-container {
                    display: block;

                    form {
                        @include make-col(12);

                        &.forget-helper {
                            margin-top: 70px;

                            .fieldset {
                                border-left: none;
                                border-top: 1px solid #ccc;
                                padding-top: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}
