.products-grid {

    .product-items {
        @include make-row();

        .product-item {
            @include make-col-ready();
            @include make-col(6);
            z-index: 0;

            .details-modules {
                height: 36px;
            }
        }

        &.slick-initialized {
            position: relative;
            width: 100%;
            display: block;
        }
    }

    &--wide {
        .product-items {
            .product-item {
                flex: 25%;
                max-width: 25%;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .products-grid {
        .product-items {
            .product-item {
                @include make-col(6);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .products-grid {
        .product-items {
            .product-item {
                @include make-col(4);
            }
        }

        &.product-grid-mode- {
            &mini {
                .product-items {
                    .product-item {
                        @include make-col(3);
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .products-grid {
        .product-items {
            .product-item {
                @include make-col(3);
            }
        }

        &.product-grid-mode- {
            &mini {
                .product-items {
                    .product-item {
                        width: 20%;
                        flex: 0 0 20%;
                    }
                }
            }
        }
    }
}
