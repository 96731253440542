// ==========================================================================
// $FONT-FACE
// ==========================================================================

@if variable-exists(google-fonts-url) {
    @import url($google-fonts-url);
}

@if variable-exists(fonts) {
    @each $font-face in $fonts {
        @font-face {
            font-family: $font-face;
            src: url('../fonts/#{$font-face}.eot'); // IE9 Compat Modes
            src: url('../fonts/#{$font-face}.eot?#iefix') format('embedded-opentype'), // IE6-IE8
            url('../fonts/#{$font-face}.woff') format('woff'), // Modern Browsers
            url('../fonts/#{$font-face}.ttf') format('truetype'), // Safari, Android, iOS
            url('../fonts/#{$font-face}.svg##{$font-face}') format('svg'); // Legacy iOS
            font-style: normal;
            font-weight: normal;
        }
    }
}

@font-face {
    font-family: 'juinsatech-icons';
    src: url('../fonts/icomoon.eot?u7mbk9');
    src: url('../fonts/icomoon.eot?u7mbk9#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?u7mbk9') format('truetype'),
    url('../fonts/icomoon.woff?u7mbk9') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'juinsatech-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
