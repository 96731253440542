.product-important {
  .product__media {
    .gallery-placeholder {
      .fotorama {
        &__nav {
          margin-top: 20px ;
        }

        .fotorama__wrap {
          .fotorama__stage {
            max-height: 700px;
          }
        }

        .fotorama-video-container {
          &:not(.fotorama__product-video--loaded) {
            &:after {
              background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom right;
              bottom: 0;
              content: '';
              height: 100px;
              left: 0;
              margin: auto;
              position: absolute;
              right: 0;
              top: 12px;
              width: 100px;
            }
          }
        }

        .product-video {
          bottom: 0;
          height: 75%;
          left: 0;
          margin: auto;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
        }

        .video-thumb-icon {
          &:after {
            background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom left;
            bottom: 0;
            content: '';
            height: 40px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 15px;
            top: 10px;
            width: 49px;
          }
        }
      }
    }
  }
}

.fotorama__fullscreen {
  .fotorama__nav {
    &.fotorama__nav--thumbs {
      margin-top: 0 !important;
      text-align: center;
    }
  }
}