.module-related--slider{
 .slick-arrow{
   position: absolute;
   z-index: 9;
   opacity: 0;
   //transition: visibility 0s, opacity 1s linear;
   transition: opacity 1s ease-out;
   &.slick-prev{
     left: -14px;
     background: transparent;
     color: transparent;
     width: 50px !important;
     height: 50px !important;
     border: none;
     //margin-left: -10px;
     &:before{
       font-family: $font-icons;
       content: "\f104";
       display: block;
       color: $color-black;
       background: $color-white;
       border-radius: 25px;
       width: 40px;
       height: 40px;
       font-size: 20px;
       font-weight: 100;
       line-height: 40px;
       -webkit-box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
       box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
     }
   }
   &.slick-next{
     right: -14px;
     background: transparent;
     color: transparent;
     width: 50px !important;
     height: 50px !important;
     border: none;
     //margin-right: 30px;
     &:before{
       font-family: $font-icons;
       content: "\f105";
       display: block;
       color: $color-black;
       background: $color-white;
       border-radius: 25px;
       width: 40px;
       height: 40px;
       font-size: 20px;
       font-weight: 100;
       line-height: 40px;
       margin-right: 30px;
       -webkit-box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
       box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
     }
   }
 }
  .product-items{
    margin-left: 0;
    margin-right: 0;
    &:hover{
      .slick-arrow{
        opacity: 1;
        &.slick-prev{
          &:before{
            //margin-left:-10px;
          }
        }
        &.slick-next{
          &:before{
            //margin-right: 20px;
          }
        }
      }
    }
    .product-item{
      .product.details {
        .product-item-actions {
          .actions-primary {
            .field {
              .control.qty{
                padding-bottom: 2px;
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .module-related--slider{
    .slick-arrow{
      &.slick-prev{
        left: -15px;
      }
      &.slick-next{
        right: -15px;
      }
    }
    .slick-initialized{
      .slick-arrow {
        &.slick-prev {
          opacity: 1;
        }
        &.slick-next {
          opacity: 1;
        }
      }
    }
  }
}