.toolbar {
  margin-bottom: 30px;
  padding: 13px 0;
  text-align: center;
  border-top: 1px solid $color-grey-btn;
  border-bottom: 1px solid $color-grey-btn;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  label {
    font-size: 12px;
    margin-bottom: 0;
  }

  .right-toolbar {
    display: flex;
  }

  &-sorter {
    .action {
      &.sort-asc {
        &:before {
          content: '\e613';
          font-family: $font-icons-alt;
          font-size: 28px;
          line-height: 32px;
        }
      }

      &.sort-desc {
        &:before {
          content: '\e614';
          font-family: $font-icons-alt;
          font-size: 28px;
          line-height: 32px;
        }
      }

      span {
        @extend .sr-only;
      }
    }
  }

  &--top {
    .pages {
      display: none;
    }
  }

  &--bottom {
    .toolbar {
      border: none;
      justify-content: center;

      .modes {
        display: none;
      }
    }

    .sorter,
    .limiter {
      display: none;
    }
  }

  .mode-selector {
    display: none;

    &__option {
      width: 35px;
      height: 35px;
      border: 1px solid $color-grey-dark;
      color: $color-grey-dark;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-icons;
      font-size: 24px;
      cursor: pointer;

      &.--maxi {
        &:before {
          content: "\f009";
        }
      }

      &.--mini {
        &:before {
          content: "\f00a";
        }
      }

      &:hover,
      &.--current {
        border-color: #000;
        color: #000;
      }
    }
  }

  .filters-trigger {
    display: none;
  }
}
.toolbar--top {
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;

  .available-products {
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    padding: 13px 0;
    width: 100%;

    .switch-label {
      margin-bottom: 0;
    }

    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 24px;
      margin-right: 10px;
      margin-bottom: 0;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-grey-dark;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: $black;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $black;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
.toolbar--bottom {
  .toolbar {
    .available-products,
    .filters-trigger {
      display: none;

    }
  }
  .loader-spinner {
    display: block;
    position: static;
  }
  .next-page-btn {
    display: block;
    margin: 0 auto;
  }
}

.top-pagination {
  margin-bottom: 20px;
  overflow: hidden;

  .loader-spinner {
    display: block;
    position: static;
  }
  .prev-page-btn {
    display: block;
    margin: 23px auto;
  }
}

.toolbar .pages {
  margin-top: 20px;
}

.products.wrapper ~ .toolbar .sorter {
  display: none;
}

.toolbar-sorter {
  gap: 5px;
  flex-wrap: nowrap;
}

.sorter-options {
  width: auto;
}

.limiter-options {
  margin: 0 5px 0 7px;
  width: auto;
}

.limiter-label {
  font-weight: 400;
}

.limiter .control {
  display: inline-block;
}

@include media-breakpoint-up(lg) {
  .toolbar {
    &--top {
      .mode-selector {
        display: flex;
      }
    }

    .filters-trigger {
      display: block;
      margin-right: 10px;

      strong {
        height: 35px;
      }
    }
  }
}
