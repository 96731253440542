// sass-lint:disable-all"
//
// Screenreaders
//

.sr-only {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}
