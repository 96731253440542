.page-layout-2columns-left {
  .columns {
    @extend .container;
    width: 100%;

    .columns-inner {
      @include make-row();

      .column.main {
        @include make-col-ready();
        @include make-col(12);
        @include flex-order(1);
      }

      .sidebar {
        @include make-col-ready();
        @include make-col(12);
        @include flex-order(0);

        &-main {
          @include flex-order(1);
        }

        &-additional {
          @include flex-order(2);
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-layout-2columns-left {
    .columns {
      .columns-inner {
        .column.main {
          @include make-col(9);
        }
        .sidebar {
          &-container {
            @include make-col(3);
      }
    }
      }
    }
  }
}