.module-newsletter {
  @include make-container();
  background-image: url(../images/newsletter/bg_newsletter.jpg);
  background-repeat: no-repeat;
  background-position: 5% center;
  -webkit-background-size: contain;
  background-size: contain;
  background-color: $color-white;
  padding-top: 130px;
  padding-bottom: 130px;
  .module {
    &-inner {
      @include make-row();
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &__content {
      @include make-col-ready();
      @include make-col(7);
      text-align: center;
    }
    &__title {
      &>.h2--full {
        border-bottom: none;
      }
    }
    &__subtitle {
      font-size: 12px;
      padding-top: 30px;
    }
    &__form {
      margin-top: 40px;
      padding: 0 100px;
      .form-inline {
        justify-content: center;
        .field {
          width: 70%;
          .control, .form-control {
            width: 100%;
            height: 50px;
            a {
              text-decoration: underline;
            }
          }
        }
        .actions {
          width: 30%;
          .btn {
            height: 50px;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
          }
        }
        + .form-inline {
          margin-top: 15px;
          .field {
            width: 100%;
            text-align: left;
          }
        }
        &.captcha-block {
          flex-flow: row wrap;
          align-items: center;
          margin-top: 40px;
          .control{
            height: auto;
          }
          label{
            justify-content: left;
            font-size: 12px;
          }
          .fiend {
            width: 70%;
          }
          .nested{
            margin-top: 15px;
          }
          .captcha-reload{
            &:before{
              content: "\f01e";
              font-family: FontAwesome;
              color: $color-brown;
              font-size: 20px;
              margin-left: 20px;
            }
            span{
              display: none;
            }
          }
        }
      }
      .mage-error{
        text-align: left;
        font-size: 10px;
      }
      .custom-control.custom-checkbox{
        padding-left: 0;
      }
    }
  }
}

#newsletter-validate-detail .form-inline {
    width: 450px;
}

@include media-breakpoint-down(md) {
  .module-newsletter {
    background-image: none !important;
    padding-top: 70px;
    padding-bottom: 70px;
    .module {
      &__content {
        @include make-col(12);
      }
      &__form {
        padding: 0 0;
        .form-inline {
          .field {
            width: 60%;
          }
          .actions {
            width: 40%;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
    #newsletter-validate-detail .form-inline {
        width: 100%;
    }
}
