%button-primary {
    border: 1px solid $color-best-new-light-green;
    border-radius: 2rem;
    background-color: $color-best-new-light-green;
    color: $color-white;
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }

    &:hover {
        background-color: $color-best-new-hover;
        border: 1px solid $color-best-new-hover;
        color: $color-white;
    }

    span {
        text-transform: lowercase;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}

%button-secondary {
    color: $button-secondary-color;
    border-color: $button-primary-border;
    border-radius: 2rem;
    background: transparent;
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }

    &:hover {
        background-color: $color-best-new-hover;
        border: 1px solid $color-best-new-hover;
        color: $color-white;
    }

    span {
        text-transform: lowercase;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}

%color-secondary {
    color: $color-best-new-dark-grey;
}

%border-dark {
    border: 1px solid $color-best-new-dark-grey;
    border-radius: 2rem;
}

%border-light {
    border: 1px solid $color-best-new-light-green;
    border-radius: 2rem;
}

%btn-hover {
    background-color: $color-best-new-hover;
    border: 1px solid $color-best-new-hover;
}

%button-products {
    border: 1px solid $color-best-new-light-green;
    border-radius: 2rem;
    margin: 10px 0 15px 0;
    padding: 6px 18px;
    font-size: 17px;
    color: $color-white;
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }
    &:hover {
        background-color: $color-best-new-hover;
        border: 1px solid $color-best-new-hover;
        color: $color-white;
    }

    span {
        text-transform: lowercase;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}

%button-gradient {
    background: $color-best-new-gradient;
    border-radius: 2rem;
    color: $color-white;
    border: none;
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }

    &:hover {
        background: $color-best-new-hover;
        color: $color-white;
    }

    span {
        text-transform: lowercase;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}

%button-toolbar {
    border-color: $color-best-new-light-green;
    color: $color-best-new-light-green;
}

%background-color-primary {
    background-color: $color-white;
}

%border-secondary {
    border: 2px solid $color-secondary;
}

@mixin color-primary {
    color: $color-best-new-light-green;
}

@mixin background-color-and-border ($color) {
    background-color: $color;
    border: 1px solid $color;
}

@mixin product-label ($distance) {
    .products-grid {
        .item-inner {
            .product-item-details {
                .product-item-actions {
                    .product-item-price {
                        .product__label {
                            right: $distance
                        }
                    }
                }
            }
        }
    }
}
