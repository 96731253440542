.module-slider {
  @include make-container();
  padding-bottom: 30px;
  background-color: $color-white;
  .module {
    &-inner {
      @include make-row();
    }
    &__list {
      @include make-col(12);

      .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2%;
        text-align: center;
        li {
          display: inline-block;
          button {
            display: block;
            width: 10px;
            height: 11px;
            margin: 5px;
            filter: alpha(opacity=100);
            opacity: 1;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            background: $color-white;
            font-size: 0;
            border: none;
          }
          &.slick-active button {
            background: $color-orange;
          }
        }
      }
    }
  }
  &.desktop{
    display: block;
  }
  &.mobile{
    display: none;
  }
}

.category-slider {
  margin-top: -15px;
}
@include media-breakpoint-down(sm) {
  .module-slider {
    &.desktop {
      display: none !important;
    }

    &.mobile {
      display: block !important;
    }
  }
}
@include media-breakpoint-down(md) {
  .module-slider {
    .rev_slider {
      &_wrapper {
        left: -15px !important;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .category-slider {
    margin-top: -10px;
  }
}

@include media-breakpoint-up(lg) {
  .category-slider {
    margin-top: -20px;
  }
}