// sass-lint:disable-all"
@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
  }
}

@mixin box-shadow-forced($shadow...) {
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}