.page-layout-1column {
  .page-main {
    .columns {
      @if $site-fluid {
        @include make-container();
      }
      @else {
        @extend .container;
      }
      .column.main {
        @include make-row();
        max-width: initial;
      }
    }
  }
  @if $home-fluid {
    &.cms-index-index {
      .page-main .columns {
        @extend %container-disable;
        .column.main {
          @extend %row-disable;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .page-layout-1column {
    .page-main {
      .columns {
        width: 100%;
      }
    }
  }
}