.cms-index-index {
    .page-wrapper {
        .main {
            background-color: #FFF;
        }

        .page-header {
            color: $color-dark;
            margin-bottom: 0;
            padding-bottom: 0;

            &.fixed {
                .header {
                    &__content {
                        background-color: $color-white;
                    }
                }
            }
        }

        .block-search {
            .block-content {
                .minisearch__trigger {
                    color: $color-primary;
                }
            }
        }

        .module-minicart {
            .action.showcart:before {
                color: $color-primary;
            }
        }

        .header__settings {
            .settings__toggle {
                color: $color-primary;
            }
        }
    }

    &.catalog-product-view {
        .page-top {
            padding-top: 115px;
            padding-bottom: 0;
        }
    }
}

.cms-home {
    #maincontent {
        padding-top: 0;
    }
}

.grid {
    padding-top: 10px;

    &__small {
        border: 1px solid #cbcbcb;

        &-content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;

            h2 {
                margin-bottom: 0;
                text-align: center;
                font-size: 36px;
                width: 100%;
            }

            .btn-img {
                padding: 20px !important;
            }
        }
    }

    &__container-element {
        height: 380px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border: 1px solid #D5D5D5;

        &.item-big {
            height: 760px;
        }
    }
}

#maincontent {
    padding-top: 20px;
}

.module__fullwidth {
    height: 550px;
    margin: 100px 0;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    .fullwidth__content {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }

    .grid__content-title {
        width: 100%;
        font-size: 40px;
        text-align: center;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .button-image {
        height: auto;
    }
}

@include media-breakpoint-down(xs) {
    .grid {
        &__container-element {
            &.item-small {
                height: 240px;
            }
        }
    }
}
