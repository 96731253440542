.header__settings {
  color: $color-dark;
  .settings {
    &__toggle {
      @extend .fa;
      @extend .fa-user;
      margin-left: 5px;
      cursor: pointer;
      position: relative;
      font-size: 20px;
      color: $color-white;
      vertical-align: top;
      span {
        display: none;
      }
    }
    &__content {
      position: absolute;
      overflow: hidden;
      overflow-y: auto;
      right: 0;
      top: 100%;
      margin-top: 5px;
      display: none;
      max-height: 430px;
      border: 1px solid $color-grey-btn;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
      -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
      -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
      -ms-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
      -o-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
      background-color: $color-white;
      z-index: $zindex-popover;
      .module {
        &-inner {
          position: relative;
          overflow: hidden;
          min-width: 230px;
          max-width: 230px;
          padding: 20px 20px 15px;
          text-align: left;
        }
        &__title {
          text-transform: uppercase;
          font-weight: $font-weight-regular;
          border-bottom: 1px solid $color-grey;
          font-size: 11px;
          margin-bottom: 5px;
        }
        &__links {
          font-size: 12px;
          text-transform: capitalize;
          li {
            padding: 5px 0;
            &.compare {
              display: none;
            }
          }
        }
      }
    }
  }
  .module__language {
    font-size: 12px;
    margin-bottom: 15px;
    .language__toggle {
      color: $color-brown;
      span:after {
        font-family: $font-icons;
        content: "\f078";
        margin-left: 5px;
      }
    }
    .language__content {
      display: none;
      .item {
        padding: 5px 0;
      }
      &--active {
        display: block;
      }
    }
  }
}
