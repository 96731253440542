.catalog-category-view,
.catalogsearch-result-index,
.catalog-product-view,
.cms-index-index,
.cms-page-view {
  .toolbar, .toolbar--top, .toolbar--bottom {
    width: 100%;

    .loader-container {
      justify-content: center;
      display: flex;
      padding: 50px;

      .loader {
        border: 16px solid #000;
        border-top: 16px solid #97928e;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

  }

  .column.main .products.wrapper {
    width: 100%;
  }

  .category-description {
    margin-top: 15px;
    text-align: center;
  }

  .products-list {
    .product-item {
      @extend .module-product;
      margin-bottom: 40px;
      border-bottom: 2px solid $color-grey;
      padding-bottom: 10px;

      .item-inner {
        flex-direction: row !important;

        .product-item-container {
          margin: 0 !important;
          width: auto !important;
        }

        .product-item-details {
          padding: 0 20px !important;
          max-width: 100% !important;
          width: 100%;

          .product-item-sku, .product-item-status {
            float: left;
            width: 100%;
          }

          .product-item-actions {
            float: left;
            width: auto;
            clear: both;

            .product-item-price {
              .price-box {
                margin-bottom: 0 !important;

                .price-wrapper {
                  padding-right: 20px;
                }
              }
            }

            .actions-primary {
              padding-left: 0 !important;
            }
          }
        }
      }

      .product-item-info {
        display: flex;
        justify-content: flex-start;

        .product-item-details {
          padding-left: 20px;

          .name {
            margin-bottom: 10px;
            float: left;
          }

          .product-item {
            &-actions {
              .product-item-price {
                display: inline-block;

              }

              .actions-primary {
                display: inline-block;
                padding-left: 20px;

                form {
                  display: flex;
                  justify-content: flex-start;
                }

                .qty {
                  max-width: 45px;
                  text-align: center;
                  font-size: 12px;
                  padding: 5px;
                  margin: 0;
                  line-height: 13px;
                  margin-right: 5px;
                  display: initial;
                }
              }
            }

            &-price {
              padding: 15px 0 25px 0;

              .price {
                font-size: 18px;
              }
            }
          }
        }
      }

      &-photo {
        max-height: 150px;
        max-width: 150px;

        .product-image-wrapper {
          height: 150px;
          width: 150px;
          display: block;

          img {
            height: auto;
          }
        }
      }
    }
  }

  .product-grid-mode-maxi {
      .product-item-container {
          max-width: 100%;
      }
  }

  .product-item-container {
    padding: 0;
    display: flex;
    position: relative;
    //max-width: 255px;

    .product-item-photo {
      position: relative;
      //width: 255px;

      &.do-hover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        opacity: 1;

        img {
          &:hover {
            opacity: 0;
            transition: opacity .5s ease-out;
            -moz-transition: opacity .5s ease-out;
            -webkit-transition: opacity .5s ease-out;
            -o-transition: opacity .5s ease-out;
          }
        }
      }

      &.hover-img {
        width: 100%;

        img {
          position: relative;
          z-index: 1;
          opacity: 1;
          height: auto;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .catalog-category-view, .catalogsearch-result-index {
    .products-list {
      .product-item {
        margin-bottom: 20px;
        border-bottom: 2px solid $color-grey;
        padding-bottom: 10px;

        .product-item-info {
          display: flex;
          justify-content: flex-start;

          .product-item-details {
            padding-left: 20px;

            .name {
              font-size: 13px;
              margin-bottom: 10px;
              float: left;
            }

            .product-item {
              &-actions {
                .product-item-price {
                  display: inline-block;

                }

                .actions-primary {
                  display: inline-block;
                  padding-left: 20px;

                  form {
                    display: flex;
                    justify-content: flex-start;
                  }

                  .qty {
                    max-width: 45px;
                    text-align: center;
                    font-size: 12px;
                    padding: 5px;
                    margin: 0;
                    line-height: 13px;
                    margin-right: 5px;
                    display: initial;
                  }
                }
              }

              &-price {
                padding: 15px 0 25px 0;

                .price {
                  font-size: 13px;
                }
              }
            }
          }
        }

        &-photo {
          max-height: 60px;
          max-width: 60px;

          .product-image-wrapper {
            height: 60px;
            width: 60px;
            display: block;

            img {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.product-item--mock {
  .product-image-wrapper {
    background-color: $color-grey-dark;

    img {
      width: 100%;
    }
  }
  .product-item-name {
    .product-item-link {
      background-color: $color-grey-dark;
      width: 100%;
      height: 17px;
    }
  }
  .product-item-advanced {
    .product-item-sku {
      background-color: $color-grey-dark;
      width: 50%;
      height: 18px;
    }
    .product-availability {
      .product-item-labels {
        background-color: $color-grey-dark;
        height: 14px;
        margin: 2px 0;
        width: 50%;
      }
    }
  }
  .product-item-actions {
    .product-item-price {
      background-color: $color-grey-dark;
      margin-top: 2px;
    }
  }
}

.products-grid {
  .product-item--mock {
    .product-item-actions {
      .product-item-price {
        min-height: 26px;
    }
  }
}
}
