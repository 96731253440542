.module-showroon {
  //@include make-container();
  //background-image: url(../images/slider-home/showroon.jpg);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  width: 100%;
  .layer-transparent {
    //@include make-row();
    background-color: rgba(229, 184, 136, 0.6);
  }
  .module {
    &-inner {
      //@include make-row();
      align-items: center;
      width: 90%;
      margin: 0 auto;
    }
    &__content {
      //@include make-col-ready();
      text-align: center;
      width: 100%;
    }
    &__title {
      color: $color-white;
      & h2{
        border-bottom: 0;
        &:after {
          display: none;
        }
      }
      a {
        color: $color-white;
      }
    }
  }
}
