body {
    .btn-primary, .btn-secondary, .btn:not(.remind):not(.back):not(.linkall):not(.cartfade):not(.btn--tertiary):not(.fastpurchase__add):not(.btn-block):not(.btn-link):not(.btn-sm):not(.action-select-shipping-item):not(.tocart) {
        @extend %button-primary;
    }
}

.my-account-page {
    .columns {
        .main {
            .actions-toolbar {
                .btn-primary, .btn-lg, .btn-secondary, .action-default {
                    @extend %button-primary;
                }
                input.btn-primary.btn  {
                    text-transform: none !important;
                }
            }
            .fastpurchase__content {
                .btn-lg {
                    @extend %button-secondary;
                }
            }
            .fastpurchase__footer {
                .btn-lg {
                    @extend %button-primary;
                }
            }
        }
    }
}

.btn {
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }
}

.btn--tertiary {
    @extend %button-secondary;
}

.checkout-cart-index .page-main .cart-container .btn-sm {
    @extend %button-secondary;
}

.checkout-index-index .btn {
    @extend %button-primary;

    &.btn--tertiary {
        @extend %button-secondary;
    }
}

.rounded-form {
    .btn {
        @extend %button-gradient;
    }
}

.square-form {
    .btn {
        @extend %button-gradient;
        border-radius: 5px !important;
    }
}

body .buttons-container {
    padding: 0 10px;

    .product-link-btn {

        a {
            &.link {
                @extend %button-gradient;
                border: 1px solid transparent;
            }
            &.ghost {
                background: transparent;
                color: $button-primary-background;
                border-color : $button-primary-background;
            }
        }
    }
}
