.amblog-index-index, .amblog-index-search, .amblog-index-category, .amblog-index-tag {
  //Grid View
   .grid {
      .list {
        display: block;
        float: left;
        width: 100%;
        &-item {
          display: inline-block;
          width: 33%;
          .list-content {
            background: $color-white;
            .post-title {
              height: 60px;
              overflow: hidden;
              margin:10px 0;
            }
            .short-content {
              height: 60px;
              overflow: hidden;
              margin-bottom: 20px;
              &:after {
                content:'...';
              }
            }
            .actions {
              text-align:right;
              margin:10px 0;
            }
          }
        }
      }
   }
  //List View
  .amblog-post {
    background-color: $color-white;
    padding: 20px;
    .am-content {
      min-height: 110px;
      max-height: 110px;
      overflow: hidden;
    }
    .actions {
      text-align: right;
      padding-top: 30px;
      margin:10px 0;
    }
  }
}
//Blog Post View
.amblog-index-post {
  .amblog-post {
    .post-thumbnail {
      margin-bottom: 10px;
    }
    .am-header {
      margin-bottom:30px;
    }
    .am-content {
      h1 {
        margin-bottom:20px;
      }
    }
    .actions {
      text-align:right;
      margin:10px 0;
    }
    i.glyphicons {
      margin-right:10px;
    }
  }
}
//BLOG BASE
.desktop-block {
  margin-bottom:40px;
  .block-title {
    font-size: 16px;
    text-align: left;
    margin: 0;
    border-bottom: 1px solid;
    padding-bottom: 5px;
    margin-bottom: 14px;
    text-transform: uppercase;
  }
}
//Recent Posts
.amblog-recentposts {
  margin-bottom: 50px;
  .amblog-recent-post {
    padding:3px 0;
    margin:0;
    a {
      margin-top: 5px;
      display: flex;
    }
    .thumbnail {
      border:none;
      width: 80px;
      height: 80px;
      margin-top:0;
      .img {
        width: 80px;
        height: 80px;
      }
    }
  }
}
//Blog Search
.amblog-search {
  .input-text {
    min-width: 250px;
    width: 40% !important;
  }
  .button {
    border-radius: 5px !important;
    margin-left: 5px;
  }
}
@include media-breakpoint-down(md) {
  .amblog-index-index, .amblog-index-search, .amblog-index-category, .amblog-index-tag {
    .am-post-header {
      .post-title{
        margin:10px 0 20px;
      }
    }
    .amblog-list {
      .post-content {
        width: 100%;
        margin:0;
      }
    }
  }
  .amblog-index-post {
    .mobile-block {
      margin: 0 3% auto;
    }
  }
  .amblog-search {
     .input-text {
      min-width: initial;
      width: 100% !important;
    }
  }
}