.module-filter {
  width: 100%;

  .filter {
    &-content {
      .block {
        &-subtitle {
          border-bottom: 1px solid;
          display: block;
          font-size: 16px;
          margin-bottom: 20px;
          padding: 10px 0;
          text-transform: uppercase;
        }
      }
    }

    &-current {
      &-subtitle {
        border: 0;
        display: block;
        font-size: 14px;
        padding-bottom: 10px;
      }

      .items {
        font-size: 12px;
        padding: 5px 0;

        .item {
          padding-left: 17px;
          position: relative;
          z-index: 1;

          .filter {
            &-label {
              font-weight: bold;

              &:after {
                content: ':';
              }
            }
          }

          .action {
            left: 0;
            position: absolute;
            top: 4px;

            span {
              display: none;
            }

            &.remove {
              @extend .fa;
              @extend .fa-times-circle;
            }
          }
        }
      }
    }

    &-actions {
      font-size: 12px;
      margin: 20px 0;
      padding: 0;
    }

    &-options {
      display: none;
      position: relative;

      &-item {
        padding-bottom: 15px;

        a {
          display: block;
          text-transform: Capitalize;
          width: 100%;

          &.ui-slider-handle {
            background: $color-grey-bg;
            border: 3px solid $color-dark;
            cursor: pointer;
            height: 15px;
            top: -4px;
            transition: all 0s;
            width: 15px;
          }

          input {
            display: none;
          }

          &.am_shopby_link_selected {
            border-left: 2px solid $color-brown;
            color: $color-brown;
            padding-left: 5px;

            .label, .count {
              color: $color-brown !important;
            }
          }

          &:hover {
            color: $color-brown !important;

            .label, .count {
              color: $color-brown !important;
            }
          }
        }

        &.active {
          .filter-options-title {
            &:before {
              @extend .fa-chevron-up;
            }
          }
        }
      }

      &-title {
        cursor: pointer;
        display: block;
        font-weight: bold;
        margin: 0 auto;
        outline: none;
        overflow: hidden;
        padding: 10px 40px 10px 5px;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        word-break: break-all;
        z-index: 1;

        &:before {
          @extend .fa;
          @extend .fa-chevron-down;
          position: absolute;
          right: 0;
          top: 15px;
        }
      }

      &-content {
        margin: 0;
        padding: 10px 0;

        .items {
          .item {
            border-bottom: 1px solid $color-grey-light;
            padding: 6px 0;

            .count {
              //float: right;
              color: $color-grey-alt;
              font-size: 11px;
              margin-left: 5px;

              &:before {
                content: '(';
              }

              &:after {
                content: ')';
                margin-left: -2px;
              }

              .filter-count-label {
                @extend .sr-only;
              }
            }
          }
        }
      }
    }
  }

  .am_shopby_slider_container {
    .price-range {
      display: inline-block;
      padding-bottom: 10px;
      padding-top: 6px;

      .am_shopby_slider_display {
        display: inline;
        margin-right: 5px;
      }
    }
  }

  .am-slider {
    &.ui-slider-horizontal {
      display: block;
      height: 6px;
      max-width: 96%;

      .ui-slider-range {
        background: $color-dark-light;
        height: 6px;
        position: absolute;
      }
    }
  }
}

.category-filter {
  .module-filter {
    width: 100%;

    .filter-content {
      .filter-subtitle {
        display: none;
      }

      .filter-actions {
        display: none;
      }

      .filter-options-item {
        .filter-options-title {
          @extend .block-subtitle;
          font-weight: 600;

          &:before {
            content: none;
          }
        }

        .filter-options-content {
          display: block !important;
        }
      }
    }
  }
}

.catalogsearch-result-index {
  .module-filter {
    .filter-options-content {
      .items {
        & > form {
          & > .item {
            border: none;

            .level-1 {
              border-top: 1px solid $color-grey-light;
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
}

%filters-trigger-hover {
  border-color: #000;
  color: #000;
}

.filters-trigger {
  strong {
    border: 1px solid #97928e;
    color: #97928e;
    cursor: pointer;
    display: flex;
    padding: 5px 10px;

    &:hover {
      @extend %filters-trigger-hover;
    }
  }
}

.filter-active {
  .filters-trigger {
    strong {
      @extend %filters-trigger-hover;
    }
  }
}

@include media-breakpoint-down(md) {
  .sidebar {
    .module-filter {
      position: relative;

      .open-filters {
        position: absolute;
        right: 2px;
        top: 8px;
      }
    }
  }

  .module-filter {
    .filter-title {
      display: block;
      position: relative;

      strong {
        background-color: transparent;
        border: 1px solid $color-grey-dark;
        -moz-border-radius: 15px;
        -webkit-border-radius: 15px;
        border-radius: 15px;
        color: $color-black;
        cursor: pointer;
        font-size: 12px;
        font-weight: $font-weight-normal;
        line-height: 28px;
        padding: 0 18px;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        top: 8px;
        z-index: 1;

        &:active {
          background-color: $color-brown !important;
          -webkit-box-shadow: 0 0 0 0.2rem $color-brown !important;
          box-shadow: 0 0 0 0.2rem $color-brown !important;
        }

        &:hover {
          background-color: $color-grey-alt;
        }
      }
    }

    .filter-options {
      margin: 0 3%;
    }

    &.active {
      .filter-options {
        display: block;
      }
    }
  }
  .catalogsearch-result-index {
    .module-filter {
      .filter-options-content {
        .items {
          & > form {
            & > .item {
              border-bottom: 1px solid $color-grey-light;

              .level-1 {
                border-top: 1px solid $color-grey-light;
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
  }
}

$filterTitleNum: 5;
$filterColumns: 4;
$filterColumnGap: 20px;
$filterTitleMaxWidth: 225px;

@include media-breakpoint-up(lg) {
  .module-filter {
    margin-top: 15px;

    .filter {
      &-title {
        display: none;
      }

      &-options {

        .filter-options-item {
          width: #{100% / $filterTitleNum};

          &.--hidden:not(.--show-hidden) {
            display: none;
          }

          &.active {
            > .filter-options-title {
              background-color: darken($color-grey-bg, 10%);
            }
          }
        }

        &-content {
          background-color: $color-grey-bg;
          display: none;
          left: 2.5%;
          max-height: 80vh;
          overflow-y: auto;
          padding: 10px 0 15px 0 !important;
          position: absolute;
          width: 95%;
          z-index: 100;
          @include box-shadow-forced(0 10px 20px 0 rgba(0, 0, 0, 0.5));

          .items:not(.am-labels-folding),
          .items > form {
            column-gap: $filterColumnGap;
            display: grid;
            grid-template-columns: gridColumnWidth($filterColumns, $filterColumnGap);
            row-gap: 10px;

            .items {
              display: block;
            }
          }

          .item {
            > a {
              padding-left: 20px;
            }
          }
        }

        &-title {
          max-width: $filterTitleMaxWidth;

          &:before {
            right: 15px;
            top: 12px;
          }
        }
      }

      &-content {
        .filter-subtitle {
          display: none;
        }
      }
    }

    &.active {
      .filter-options {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
