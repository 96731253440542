.nav-sections {
    margin-bottom: 0;
    width: 35%;

    &-items {

    }

    &-item {
        &-title {
            display: none;
        }

        &-content {
            display: block;

            > * {
                display: none;
            }

            .navigation {
                display: block;
            }
        }
    }
}

.navigation {
    height: inherit;
    left: auto;
    overflow: inherit;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 3;
    text-transform: uppercase;
    font-weight: $font-weight-normal;

    &:empty {
        display: none;
    }

    > ul {
        padding: 25px 0;
        padding-left: 30%;
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        padding: 25px 0;
    }

    li {
        &.level0 {
            border-top: none;
        }
    }

    .level0 {
        padding-left: 35%;

        &:last-child {
            margin-right: 0;
            padding-right: 0;
        }

        > .level-top {
            color: $color-grey-dark;
            line-height: 20px;
            padding: 0 12px;
            text-decoration: none;
            box-sizing: border-box;
            position: relative;
            font-size: 12px;
            font-weight: $font-weight-regular;

            &:hover, &.ui-state-focus {
                color: $color-dark;
                text-decoration: none;
            }
        }

        &.active, &.has-active {
            > .level-top {
                font-weight: $font-weight-regular;
                color: $color-brown;
            }
        }

        &.parent {
            &:hover {
                > .submenu {
                    overflow: visible !important;
                }
            }
        }

        img.submenu {
            height: auto !important;

        }

        .submenu {
            background: $color-white;
            font-weight: 400;
            padding: 60px 25px 20px;
            position: absolute;
            z-index: 1;
            top: 0 !important;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            height: 100% !important;
            width: 100% !important;

            li {
                width: 175px;
                height: 21px;
                font-size: 12px;
                font-weight: $font-weight-regular;

                &.parent-category {
                    font-size: 13px;
                    margin-top: -35px;
                    margin-bottom: 10px;
                    color: $color-brown;
                }
            }

            > ul {
                margin-top: 11px;

                &:before, &:after {
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                }

                &:before {
                    color: #ffffff;
                    left: 20px;
                    top: -20px;
                    border: 10px solid transparent;
                    height: 0;
                    width: 0;
                    border-bottom-color: #ffffff;
                    z-index: 4;
                }

                &:after {
                    border: 11px solid transparent;
                    height: 0;
                    width: 0;
                    border-bottom-color: #cccccc;
                    color: #cccccc;
                    left: 19px;
                    top: -22px;
                    z-index: 3;
                }
            }

            a {
                display: block;
                color: $color-grey-dark;
                font-weight: 100;

                &:hover, &.ui-state-focus {
                    font-weight: $font-weight-regular;
                    text-decoration: none;
                    color: $color-brown;
                }
            }

            .active {
                > a {
                    font-weight: $font-weight-regular;
                    color: $color-brown;
                }
            }

            .submenu {
                top: 0 !important;
                left: 100% !important;
            }

            .submenu-reverse {
                left: auto !important;
                right: 100%;
            }

            &:hover {
                display: flex !important;
                margin: 0;
            }
        }

        &.more {
            position: relative;
            display: inline-block;
            text-decoration: none;

            &:after {
                -webkit-font-smoothing: antialiased;
                font-size: 26px;
                line-height: inherit;
                color: inherit;
                content: '\e607';
                font-family: 'luma-icons';
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                cursor: pointer;
                padding: 8px 12px;
                position: relative;
                z-index: 1;
            }

            &:before {
                display: none;
            }

            &:hover {
                > .submenu {
                    overflow: visible !important;
                }
            }

            li {
                display: block;
            }
        }
    }

    &__title {
        padding-left: 35%;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: block;
        color: $color-dark-light;

        span {
            padding: 0 12px 0 0;
            margin-left: 12px;
            font-weight: $font-weight-semibold;
            font-size: 13px;
            color: $color-black;
        }
    }
}

.nav-toggle {
    @extend .fa;
    @extend .fa-bars;
    display: none;
    font-size: 24px;
    padding: 5px;
    cursor: pointer;

    span {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .nav-toggle {
        display: block;
    }
    .nav-sections {
        -webkit-overflow-scrolling: touch;
        -webkit-transition: left .3s;
        -moz-transition: left .3s;
        -ms-transition: left .3s;
        transition: left .3s;
        height: 100%;
        left: calc(-1 * (100% - 54px));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        background-color: $menuBackgroundColor;
        color: $color-white;
        z-index: 99999;

        &-item {
            &-title {
                display: block;
                border-width: 0 0 1px 1px;
                box-sizing: border-box;
                float: left;
                height: 55px;
                padding-top: 15px;
                text-align: center;
                width: 50%;
                font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
                color: $color-white;
                letter-spacing: 0.5px;

                a {
                    color: $color-white;
                }

                &.active a {
                    color: $color-brown;
                }
            }

            &-content {
                box-sizing: border-box;
                float: right;
                margin-left: -100%;
                margin-top: 15px;
                width: 100%;
                position: relative;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: "";
                    height: 1px;
                    width: 100%;
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(22,22,27,.97)', endColorstr='rgba(22,22,27,.97)', GradientType=0);
                }
            }
        }

        .header__settings {
            width: 100%;
            margin: 0;
            display: block;

            .settings__content {
                position: relative;
                display: block;
                width: 100%;
                background: none;
                color: $color-white;
                box-shadow: none;
                margin-top: 0;
                border: none;

                .module {
                    &-inner {
                        padding: 0;
                        width: 100%;
                        min-width: 100%;
                    }

                    &__title {
                        text-align: center;
                        padding: 10px 40px;
                        font-size: 14px;
                        margin-bottom: 0;
                        border-bottom: 0;

                        &:after {
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: "";
                            height: 1px;
                            width: 100%;
                            background: #2f2f33;
                            background: rgba(22, 22, 27, 0.97);
                            background: -moz-linear-gradient(left, rgba(22, 22, 27, 0.97) 0, #2f2f33 52%, #2f2f33 52%, #2f2f33 52%, rgba(22, 22, 27, 0.97) 100%);
                            background: -webkit-linear-gradient(left, rgba(22, 22, 27, 0.97) 0, #2f2f33 52%, #2f2f33 52%, #2f2f33 52%, rgba(22, 22, 27, 0.97) 100%);
                            background: linear-gradient(to right, rgba(22, 22, 27, 0.97) 0, #2f2f33 52%, #2f2f33 52%, #2f2f33 52%, rgba(22, 22, 27, 0.97) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(22,22,27,.97)', endColorstr='rgba(22,22,27,.97)', GradientType=0);
                        }

                        &.account {
                            display: none;

                            & + ul {
                                li {
                                    a {
                                        font-size: 11px;
                                        float: left;
                                    }

                                    &.authorization-link {
                                        a {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &__language {
                        text-align: center;
                        padding: 10px 40px;
                        font-size: 14px;

                        .language__content {
                            a {
                                color: $color-white;
                            }
                        }

                        &-mobile {
                            display: inline-block;
                            text-transform: uppercase;
                            float: left;
                            width: 100%;
                            text-align: left;
                            font-size: 11px;
                            margin-left: 20px;

                            .language__active {
                                color: $color-white;
                            }

                            .language__active, .language__options, .switcher-option {
                                display: inline-block;
                                padding: 5px;
                            }
                        }
                    }

                    &__links {
                        text-align: center;

                        li {
                            padding: 10px 25px;
                            word-wrap: break-word;
                            position: relative;
                            display: block;
                            text-decoration: none;
                            font-size: 11px;

                            &:first-child:before {
                                position: absolute;
                                top: 10px;
                                left: 10px;
                                color: $color-brown;
                                content: "\f007";
                                font-family: $font-icons;
                            }

                            a {
                                color: $color-white;
                            }
                        }
                    }
                }

                .account-buttons {
                    text-align: left;
                    margin-left: 20px;

                    .btn--primary {
                        border-radius: 0;
                        border: 1px solid $color-brown;
                        font-weight: normal;
                        background-color: $color-brown;

                        &:hover {
                            color: $color-white;
                            border-color: $color-white;
                            background-color: $color-dark;
                        }
                    }

                    .btn--secondary {
                        color: $color-dark;

                        &:hover {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }

    .navigation {
        text-align: left;

        ul {
            padding: 15px 0;
            margin: 15px 0;
        }

        li {
            display: block !important;

            &.level0 {
                margin: 0;
                position: relative;

                > .level-top {
                    padding: 7px 25px;
                    word-wrap: break-word;
                    position: relative;
                    display: block;
                    text-decoration: none;
                    font-size: 12px;
                    font-weight: $font-weight-normal;
                    color: $color-grey-dark;
                    border-top: 1px solid $color-grey;

                    &:hover {
                        color: $color-primary;
                    }

                    &:after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        content: "";
                        height: 1px;
                        width: 100%;
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(22,22,27,.97)', endColorstr='rgba(22,22,27,.97)', GradientType=0);
                    }
                }

                &.parent {
                    .ui-menu-icon {
                        &:after {
                            content: '\f107';
                            font-family: $font-icons;
                            position: absolute;
                            right: 25px;
                        }
                    }
                }

                .submenu {
                    border: none;
                    margin: 7px 0;
                    transition: 1s;
                    -webkit-transition: 1s;

                    li {
                        padding: 10px 40px;
                        width: 100%;
                        height: auto;
                        position: relative;
                        border-top: 1px solid $color-grey;

                        &.parent-category {
                            display: none !important;
                        }

                        &.all-category {
                            font-weight: bold;
                        }

                        a {
                            color: $color-grey-dark;
                            text-transform: uppercase;

                            &:hover {
                                color: $color-primary;
                                font-weight: $font-weight-normal;
                            }

                            &:after {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                content: "";
                                height: 1px;
                                width: 100%;
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(22,22,27,.97)', endColorstr='rgba(22,22,27,.97)', GradientType=0);
                            }
                        }
                    }

                    &:hover {
                        margin: 7px 0;
                    }
                }

                &.last > .level-top {
                    border-bottom: 1px solid $color-grey;
                }
            }
        }

        .submenu:not(:first-child) {
            font-weight: lighter;
            left: auto !important;
            overflow-x: hidden;
            padding: 0;
            position: relative;
            top: auto !important;
            transition: left 0.3s ease-out;
            box-shadow: none;
            width: 100% !important;
            background: $color-white;

            &.expanded {
                display: block !important;
                padding-right: 0;
                top: 0 !important;
                left: 0 !important;
            }
        }

        &__title {
            display: none;
            padding-left: 0;

            span {
                color: $color-white;
            }
        }

        .level0 {
            padding: 0;

            > .level-top {
                color: $color-white;
            }
        }
    }

    .navigation--notlogged {
        display: block;
        text-align: center;
        padding: 10px 40px;
        text-transform: uppercase;
        word-wrap: break-word;
        position: relative;
        text-decoration: none;
        font-size: 14px;

        a {
            color: $color-white;

            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                content: "";
                height: 1px;
                width: 100%;
                background: #2f2f33;
                background: rgba(22, 22, 27, 0.97);
                background: -moz-linear-gradient(left, rgba(22, 22, 27, 0.97) 0, #2f2f33 52%, #2f2f33 52%, #2f2f33 52%, rgba(22, 22, 27, 0.97) 100%);
                background: -webkit-linear-gradient(left, rgba(22, 22, 27, 0.97) 0, #2f2f33 52%, #2f2f33 52%, #2f2f33 52%, rgba(22, 22, 27, 0.97) 100%);
                background: linear-gradient(to right, rgba(22, 22, 27, 0.97) 0, #2f2f33 52%, #2f2f33 52%, #2f2f33 52%, rgba(22, 22, 27, 0.97) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(22,22,27,.97)', endColorstr='rgba(22,22,27,.97)', GradientType=0);
            }
        }
    }

    .navigation-secondary {
        display: block;
        padding-bottom: 20px;

        li {
            display: block;
            padding: 7px 25px;
            width: 100%;
            height: auto;
            position: relative;
            text-align: left;
            font-size: 12px;

            li {
                border-bottom: 1px solid $color-grey;

                span {
                    color: $color-grey-dark;
                }

                a {
                    color: $color-grey-dark;
                    text-transform: none;

                    &:hover {
                        color: $color-primary;
                        font-weight: $font-weight-normal;
                    }

                    &:after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        content: "";
                        height: 1px;
                        width: 100%;
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(22,22,27,.97)', endColorstr='rgba(22,22,27,.97)', GradientType=0);
                    }
                }
            }
        }

        .navigation--home, .navigation--toggle {
            display: none;
        }
    }

    .nav-before-open {
        .page-wrapper {
            height: 100%;
            overflow: hidden;
        }
    }
    .page-wrapper {
        -webkit-transition: left .3s;
        -moz-transition: left .3s;
        -ms-transition: left .3s;
        transition: left .3s;
        position: relative;
        left: 0;
    }
    .nav-open {
        position: fixed;

        .nav-sections {
            -webkit-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            -moz-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            -ms-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            left: 0;
            z-index: $zindex-modal-backdrop;
            width: 40%;
        }

        .page-wrapper {
            left: 40%;
        }
    }
}

@include media-breakpoint-down(xs) {
    .nav-open {
        .nav-sections {
            width: 80%;
        }

        .page-wrapper {
            left: 80%;
        }
    }
}
