.block-collapsible-nav {
  padding: 10px 0;
  background-color: $color-white;
  border-bottom: 2px solid $color-grey;
  .title {
    display: none;
    padding: 0 10px;
    &:before {
      @extend .fa;
      @extend .fa-chevron-down;
      position: relative;
      float: right;
    }
  }
  .content {
    .nav {
      display: block;
      .item {
        a {
          width: 100%;
          display:block;
          padding: 10px;
        }
        &:hover {
          background-color:$color-grey-bg;
        }
        &.current {
          border-left: 3px solid;
          padding: 10px;
          border-color: $color-brown;
        }
      }
    }
  }
}


@include media-breakpoint-down(md) {
  .block-collapsible-nav {
    .title {
      display: block;
    }
    .content {
      display: none;
      padding: 15px 0 0;
      &.active {
        display: block;
      }
    }
  }
}