// ==========================================================================
// Helper Classes
// ==========================================================================

// Disable container styles

%container-disable {
  width: auto;
  padding: 0;
  margin: 0;
  max-width: 100%;
  &:before, &:after {
    content: none;
  }
}

// Disable row styles

%row-disable {
  margin:0 !important;
  display: block !important;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
