.breadcrumbs {
  margin-top: 10px;
  text-align: center;
  .items {
    padding: 0 15px;
    > li {
      margin: 0;
      padding: 0;
      list-style: none none;
      display: inline-block;
      vertical-align: top;
      a {
        color: $breadcrumbs-color-primary;
        &:hover {
          color: $breadcrumbs-color-hover-secondary;
        }
      }
      strong {
        color: $breadcrumbs-color-hover-secondary;
        font-weight: $font-weight-normal;
      }
    }
  }
  .item {
    margin: 0;
    &:not(:last-child) {
      display: inline-block;
      text-decoration: none;
      &:after {
        content: '>';
        margin: 0 5px;
        color: $color-light;
      }
    }
  }
  a {
    text-decoration: none;
  }
}
