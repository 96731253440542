.module-featuredimages {
  @include make-container();
  background-color: $color-white;
  max-width: #{map-get($container-max-widths, 'xl')};
  padding-bottom: 0;

  .module-inner {
    @include make-row();

    .item {
      min-height: 175px;
      overflow: hidden;
      padding-bottom: 30px;
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &--bg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        min-height: inherit;
        transition: all .3s ease-in-out;
        width: 100%;

        .link {
          height: 100%;
          position: absolute;
          width: 100%;
        }

        &:hover {
          overflow: hidden;
          transform: scale(1.1);
        }
      }

      &.responsive {

        .desktop {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .module-featuredimages {
    .module-inner {
      .item {
        min-height: 250px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .module-featuredimages {
    .module-inner {
      .item {

        &.responsive {

          .mobile {
            display: none;
          }

          .desktop {
            display: flex;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .module-featuredimages {
    padding-bottom: 30px;

    .module-inner {
      .item {
        min-height: 300px;
        padding-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
