.sidebar {
  .sidebar-additional {
    .block-compare, .block-wishlist {
      margin-bottom: 30px;
      .block-title {
        strong {
          border-bottom: 1px solid;
          padding: 10px 0;
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
}