.module-featuredimages .module-inner .item {
    overflow: visible;

    //a:hover .btn-img{
    //    background: $color-best-new-hover;
    //    border: none;
    //}

    .item-overflow{
        overflow:hidden;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .btn-img{
        @extend %button-gradient;
        //border: 0;
        //border-radius: 2rem;
        //background: $color-best-new-gradient;
        //color: white;
        padding: 10px 25px 10px;
        font-size: 25px;
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translate(-50%);
        //text-transform: uppercase;
        transition: all 0.5s ease-in-out;
        white-space: nowrap;
    }

    .btn-img.btn-down{
        bottom: -20px;
        top: unset;
    }
}

@include media-breakpoint-down(md) {
    .module-featuredimages .module-inner .item{
        margin-bottom: 10px;

        .btn-img{
            position: absolute;
            top: 50%;
            left: 50%;
            bottom: unset;
            transform: translate(-50%, -50%);
            font-size: 20px;
        }
    }
}
