.header__top--right {
    @include make-col-ready();
    text-align: right;
    padding-right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-in {
        width: 100%;

        &-grouper {
            display: inline-block;
        }
    }

    .switcher-language {
        display: inline-block;
        text-transform: uppercase;
        padding: 1px 0;
        border-right: 1px solid;
        position: relative;
        margin-right: 8px;
        color: $color-switcher-language;
        border-right: 1px solid $button-primary-border;
        font-size: 16px;

        .languages {
            display: flex;
        }
        .language__active {
            display: inline-block;
            padding: 0 5px;
            cursor: pointer;
            margin: auto;
        }

        .language__options {
            position: absolute;
            left: 0;
            background-color: #fff;
            z-index: 9;
            display: none;
            text-align: left;
            padding: 11px 11px 0 11px;
            box-shadow: 0 5px 13px 0 rgba(50, 50, 50, 0.74);
        }

        .language__options.visible {
            display: block;
        }

        .switcher-option {
            display: inline-block;

            &.item {
                margin-top: auto;
                margin-bottom: auto;
                display: block;
            }

            a:hover, .language__options a:hover {
                color: $color-primary;
            }
        }

        .switcher-option, .language__options {
            margin: 4px 5px;

            a {
                color: $color-grey-alt;

                &:hover {
                    color: $color-dark;
                }
            }
        }
    }

    &--user-actions {
        width: 260px;
        display: inline-block;
    }

    .ayudacontacto {
        display: inline-block;
        max-width: 110px;
        text-align: left;
        vertical-align: middle;
        margin: 0 10px;
        width: auto;

        .flex-content {
            display: flex;
            align-items: center;
            color: $color-primary;

            .icono::after {
                @include iconoContact;
            }

            .texto {
                margin-left: 10px;
            }
        }
    }

    .ayudacontacto .icono, .texto {
        color: $color-menu;
    }

    .btn--secondary {
        color: $button-secondary-color;
        border-color: $button-secondary-color;

        &:hover {
            color: $color-white;
            border-color: $button-primary-border-hover;
        }
    }

    .btn--primary {
        border-radius: 0;
        border: 1px solid $button-primary-border;
        font-weight: normal;
    }

    .btn--black {
        border: 1px solid $button-primary-border;
        background-color: $button-primary-background;
        color: $button-primary-color;

        &:hover {
            background-color: $button-primary-background-hover;
            color: $button-primary-color-hover;
            border: 1px solid $button-primary-border-hover;
        }
    }

    .header__settings .settings__toggle:before {
        content: '';
    }

    .icon-login:before {
        content: "\e901";
        color: $color-primary;
        font-size: 29px;
    }

    .icon-logout:before {
        content: "\e902";
        color: $color-primary;
        font-size: 30px;
    }

    .module-minicart {
        margin: 5px 10px 0 5px;

        .icon-cart:before {
            content: "\e900";
            color: $color-primary;
            font-size: 30px;
        }

        .action {
            .counter {
                border-radius: 8px;
                font-size: 7px;
                height: 14px;
                line-height: 13px;
                width: 14px;
                left: 31px;
                top: 0;
                margin: 0;

                &::before {
                    display: none
                }
            }

            &.showcart::before {
                content: '';
            }
        }
    }
}

.greet.welcome span {
    font-weight: bold;
}

@include media-breakpoint-down(lg) {
    .header__top--right {
        .ayudacontacto {
            .texto {
                display: none;
            }
        }

        .header__settings {
            .settings__toggle {
                margin-top: 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .header__top--right {
        padding: 0;

        .header__top--right-in {
            display: none;
        }

        .header__settings {
            margin: 0px;

            .settings__toggle {
                margin-top: 0;
            }

            .module-inner .module__title, .module__language {
                display: block;
            }
        }

        .switcher-language {
            font-size: 12px;
            border: none;

            .switcher-option.item {
                margin-bottom: 10px;
                display: block;
            }
        }
    }
}

@media (max-width: 592px) {
    .header__top--right {
        margin: 0;
        padding: 0;
        flex: 0 0 25%;
        max-width: 25%;

        .header__settings,.module-minicart {
            margin: 0;
        }
    }
}
