.module-minicart {
    display: inline-flex;
    text-align: right;
    position: relative;
    color: initial;

    .action {
        &.showcart {
            &:hover {
                color: inherit;
            }
        }

        .icon {
            font-size: 35px;
            height: 35px;
            line-height: 35px;
            width: 35px;
            background: url(../images/icons/icon_cart.png) left center no-repeat;
            display: inline-block;
            vertical-align: top;
        }

        .counter {
            font-size: 9px;
            font-weight: 400;
            display: block;
            height: 17px;
            line-height: 17px;
            width: 16px;
            background: $color_background_minicart;
            color: $color_text_minicart;
            border-radius: 0;
            padding: 0;
            text-align: center;
            position: absolute;
            left: 26px;
            top: 49%;
            margin: -8px 0 0;
            min-width: auto;
            overflow: visible;

            &:before {
                content: "";
                border-top: 3px solid transparent;
                border-right: 3px solid $color_background_minicart;
                border-bottom: 3px solid transparent;
                border-left: 3px solid transparent;
                position: absolute;
                left: -6px;
                top: 50%;
                margin-top: -3px;
            }

            &-label {
                @extend .sr-only;
            }
        }
    }

    .block-minicart {
        padding: 25px 20px 25px;
        list-style: none none;
        background: $menuBackgroundColor;
        border: 1px solid $color-grey-btn;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
        -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
        -ms-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
        -o-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);

        min-width: 100%;
        z-index: 100;
        box-sizing: border-box;
        display: none;

        position: absolute;
        top: 100%;
        left: auto;
        width: 390px;
        height: auto;
        margin: 10px 0 0;
        right: -15px;

        &:before {
            content: '';
            display: block;
            height: 0;
            width: 0;
            position: absolute;
            left: auto;
            top: -12px;
            right: 100px;
            border: 6px solid transparent;
            border-bottom-color: $color-white;
            z-index: 99;
        }

        .block {
            &-title {
                @extend .sr-only;
            }

            &-content {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                height: 100%;
            }
        }

        .minicart-content-wrapper {
            height: 100%;
        }

        .minicart-dummymessage {
            text-align: left;
            padding: 10px;
            display: none !important;

            &.show {
                display: block !important;
            }

            .dummymessage {
                padding: 10px;
                background-color: $color-grey-light;
                color: $color-green-msg;

                ul{
                    li{
                        list-style: initial;
                        margin-left: 15px;
                    }
                }
                .minicart-data {
                    font-size: 0.9em;
                }

                .remainingForFree{
                    font-weight: bold;
                }
            }
        }

        .items-total {
            margin: 0 10px;
            color: $color-dark;
        }

        .subtotal {
            margin: 0 10px;
            text-align: right;
            color: $color-dark;

            .amount {
                @extend .h5;
                margin-top: 15px;
            }
        }

        .actions {
            width: 100%;
            margin: 20px 0 0;
            text-align: center;

            .dummysave {
                margin-top: 10px;
                display: block;
                text-align: center;
            }
        }

        .subtitle {
            display: none;

            &.empty {
                display: block;
                width: 100%;
                text-align: center;
                color: $color-dark;
            }
        }

        .minicart-items-wrapper {
            border: 1px solid $color-grey;
            border-left: 0;
            border-right: 0;
            overflow-x: auto;
            padding: 15px 0;
            margin-top: 20px;
            width: 100%;

            .minicart-items {
                overflow-y: auto;
                padding: 0 15px;

                label {
                    margin-bottom: 0;
                }

                input[type=number] {
                    -webkit-appearance: textfield;
                    -moz-appearance: textfield;
                    appearance: textfield;
                    border: none;
                    pointer-events: none;
                    margin: 0 !important;
                    width: 55px !important;
                }

                input[type=number]::-webkit-inner-spin-button,
                input[type=number]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .product-item {
                    padding: 20px 0;
                    vertical-align: top;
                    border-bottom: 1px solid $color-grey;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    .product {
                        display: flex;
                        justify-content: space-between;

                        .product-item-photo {
                            width: 78px;
                            margin-right: 15px;
                        }

                        &-item-details {
                            flex-grow: 1;
                            text-align: left;
                        }

                        &-item-name {
                            -moz-hyphens: auto;
                            -ms-hyphens: auto;
                            -webkit-hyphens: auto;
                            display: block;
                            hyphens: auto;
                            margin: 0;
                            word-wrap: break-word;
                            font-weight: $font-weight-normal;
                        }

                        &-item-sku {
                            font-size: 10px;
                            color: $color-grey-dark;
                        }

                        &-item-pricing {
                            .price-container {
                                font-weight: $font-weight-semibold;
                                margin-bottom: 15px;
                            }

                            .label {
                                font-weight: $font-weight-normal;
                                color: $color-dark;
                            }

                            .item-qty {
                                margin-left: 10px;
                                text-align: center;
                                width: 50px;
                                background-color: transparent;
                                font-weight: bold;
                            }
                        }

                        .details-qty {
                            .update-cart-item {
                                background: none;
                                border: none;
                                font-size: 0;
                                display: inline-block;
                                @extend .fa;

                                &:before {
                                    @extend .fa-refresh;
                                    font-size: 18px;
                                    color: $color-dark-alt;
                                }

                                &:disabled {
                                    cursor: not-allowed;
                                    color: $color-grey-dark;
                                }
                            }
                        }

                        .actions {
                            float: right;
                            margin: -40px 10px 0;
                            font-size: 18px;

                            > div {
                                display: inline;

                                .action {
                                    @extend .fa;

                                    span {
                                        display: none;
                                    }

                                    &.edit {
                                        display: none;
                                        @extend .fa-pencil-square-o;
                                    }

                                    &.delete {
                                        @extend .fa-trash;
                                        margin-left: 10px;
                                        margin-top: 2px;
                                    }
                                }
                            }
                        }
                    }

                    .product-image-photo {
                        border: solid 1px $color-grey;
                        border-radius: 5px;
                    }
                }

                .update-cart-item {
                    display: none;
                }
            }
        }
    }

    &.active {
        .block-minicart {
            display: block;

            .action {
                &.close {
                    @extend .fa;
                    @extend .fa-times;
                    position: absolute;
                    padding: 0;
                    height: 40px;
                    right: 0;
                    top: 0;
                    width: 40px;

                    > span {
                        @extend .sr-only;
                    }

                    &:before {
                        -webkit-font-smoothing: antialiased;
                        font-size: 32px;
                        line-height: 32px;
                        color: #808080;
                        content: '\e616';
                        font-family: 'Blank-Theme-Icons';
                        margin: 0;
                        vertical-align: top;
                        display: inline-block;
                        font-weight: normal;
                        overflow: hidden;
                        speak: none;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .module-minicart {
        .block-minicart {

            .minicart-items-wrapper {
                max-height: 300px;

                .minicart-items {

                    .update-cart-item {
                        display: block;
                    }
                }
            }

            &:before {
                right: 26px;
            }
        }
    }
}

@media (min-height: 800px) and (max-width: 767px) {
    .module-minicart {
        .block-minicart {
            .minicart-items-wrapper {
                max-height: 350px;
            }
            #minicart-content-wrapper {
                height: 100%;
            }
        }
    }
}

@media (max-height: 800px) and (max-width: 767px) {
    .module-minicart {
        .block-minicart {
            .minicart-items-wrapper {
                max-height: 175px;
            }
            #minicart-content-wrapper {
                height: 100%;
            }
        }
    }
}

@media (max-width: 410px) {
    .module-minicart {
        .block-minicart {
            position: fixed;
            left: 0;
            min-width: calc(100% - 20px);
            margin-left: 10px;
            margin-right: 10px;
            width: calc(100% - 20px);
        }
    }
}
