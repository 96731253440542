.footer {
    padding-top: 0;

    .module-footer {
        padding-right: 25px;
        padding-left: 25px;

        @include color-primary;

        .custom-checkbox .custom-control-description a {
            @include color-primary;
        }
    }
}
