.header__top--right .module__language-alternative {
    margin-right: 25px;
    font-size: 12px;

    &.switcher-language {
        border-right: none;
        display: block;

        .languages {
            justify-content: end;

            div {
                border-right: 1px solid;
                margin: 0;
                padding: 0 5px;
            }

            &:last-child {
                border-right: none;
            }

            .switcher-option a,
            .language__options a {
                color: inherit;

                &:hover {
                    color: initial;
                }
            }
        }
    }
}
