.catalog-product-view .columns .column.main .product-important {
    .product__info {
        top: 130px;

        .btn.btn-lg{
            font-size: 1.3rem;
            @extend %button-gradient;
        }

        .page-title-wrapper .page-title {
            font-size: 24px;
            color: $color-best-new-light-green;
        }

        .product.attribute.description {
            border-color: $color-best-new-dark-grey;
        }
    }

    .product__details .module-tabs {


        .title  {
            .switch {
                text-transform: capitalize;
                color:$color-best-new-dark-grey;

                &:hover {
                    color: $color-best-new-light-green;

                    &:after {
                        border-color: $color-best-new-light-green;
                    }
                }
            }
        }

        .active {
            .switch {
                color: $color-best-new-light-green;

                &:after {
                    border-color: $color-best-new-light-green;
                }
            }
        }

        .content {
            border: none;
            tr {
                border-color: $color-best-new-light-green;

                .col {
                    border-color: $color-best-new-dark-grey;

                    &.label {
                        color: $color-best-new-light-green;
                    }
                }
            }
        }
    }
}
#product-addtocart-button{
    font-size: 1rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

@include media-breakpoint-down(lg) {
    .catalog-product-view .columns .column.main .product-important .product__details .module-tabs {
        margin: 0;

        .switch {
            width: 100%;
        }
    }
}

@include media-breakpoint-down(md) {
    .product-important{
        .btn.btn-lg {
            font-size: 1rem;
        }
    }
}

