.module-related {
  @include make-col-ready();
  @include make-col(12);
  padding-top: 50px;
  .products-grid {
    .product-items {
      .product-item {
        @include make-col(3);
        max-width:100%;
        &-name {
          height: 35px;
          overflow: hidden;
        }
        .price-box{
          .old-price{
            span{
              text-decoration: line-through;
              font-size: 11px;
              display: block;
              line-height: 11px;
              color: $color-grey-dark;
            }
          }
        }
      }
    }
  }
  &.catalog-widget{
    max-width: 1140px;
    margin: 0 auto;
  }
}

@include media-breakpoint-down(md) {
  .module-related {
    @include make-col-ready();
    @include make-col(12);
    padding-top: 50px;
    .products-grid {
      .product-items {
        .product-item {
          @include make-col(3);
          max-width:100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .module-related {
    @include make-col-ready();
    @include make-col(12);
    padding-top: 50px;
    .products-grid {
      .product-items {
        .product-item {
          @include make-col(6);
          max-width:100%;
        }
      }
    }
  }
}