.module-socialshare {
  text-transform: uppercase;
  color: $color-grey-dark;
  .module {
    &-inner {
      display: flex;
    }
    &__title {
      margin-right: 10px;
      font-size: 14px;
      line-height: 28px;
    }
    &__list {
      @extend .list-inline;
      li {
        @extend .list-inline-item;
        a {
          @extend .fa;
          padding: 0 10px;
          color: $color-grey-dark;
          &:hover {
            color: $color-brown;
          }
          span {
            display: none;
          }
          &.facebook {
            @extend .fa-facebook;
          }
          &.twitter {
            @extend .fa-twitter;
          }
          &.googleplus {
            @extend .fa-google-plus;
          }
          &.pinterest {
            @extend .fa-pinterest;
          }
          &.linkedin {
            @extend .fa-linkedin;
          }
          &.whatsapp {
            @extend .fa-whatsapp;
          }
        }
      }
    }
  }
}
