%button-cookieconsent{
    background-color: $button-tertiary-background !important;
    color: $button-tertiary-color !important;
    border: 1px solid $button-tertiary-border !important;
    border-radius: 5px !important;

    &:hover{
        background-color: $button-primary-background-hover !important;
        color: $button-primary-color-hover !important;
        border-color: $button-primary-border-hover !important;
        @include transition(all, 0.3s);
    }
}

.klaro {
    .cookie-notice .cn-body {
        flex-direction: column !important;
        width: 100%;

        div.cn-ok {
            .cn-buttons {
                order: 2;
            }
            .cn-learn-more {
                order: 1;
                text-decoration: none;
            }
            .cookiesNewEvent {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
                z-index: 2;
                margin-top: 0;
            }
        }
        .cm-btn {
            @extend %button-cookieconsent;
        }

        &-text{
            #id-cookie-title {
                display:none;
            }
        }
    }

    .cookie-modal .cm-modal{
        .cm-footer .cm-footer-buttons {
            justify-content: center;

            .cm-btn {
                @extend %button-cookieconsent;
                z-index: 1;


                &.cn-decline, &.cm-btn-accept-all {
                    display: none;
                }
            }

            .cookiesNewEvent {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
                z-index: 2;
                margin-top: 0;
            }
        }
        .content .content-{
            &left li:first-child{
              display: none;
            }
            &right{
                .cm-services {
                    #required {
                        display: none;
                    }
                }
            }
        }
        .cm-list-input:checked  + .cm-list-label .slider, .cm-list-input.required:checked  + .cm-list-label .slider {
            background-color: $button-primary-background;
        }
        .cm-header {

            a {
                color: $button-primary-background;
            }

            .title{
                display: none;
            }

        }
    }
}

.cn-buttons {
    position: relative;
}

.cm-btn {
    position: relative;
    z-index: 1;
}

#cookie-consent-settings-button-container {
    position: fixed;
    bottom: 0;
    right: 60px;
    z-index: 1000;

    #cookie-consent-settings-button {
        @extend %button-cookieconsent;
        background-color: $color-white !important;
        padding: 5px 10px 0 10px;
        border-bottom: none !important;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0 !important;
        border-bottom: none !important;
        border-bottom-right-radius: 0 !important;
        display: flex;
        align-items: center;

        &::before{
            font-family: 'FontAwesome';
            content: "\f013";
            color: $color-grey-alt;
            font-size: 20px;
            padding-right: 5px;
        }

        &:hover::before{
            color: $color-white;
        }
    }
}

@media (max-width: 768px) {

    .klaro .cookie-notice .cn-body .cn-ok {
        margin-top: 1.5em !important;
    }
}

@media (max-width: 576px) {

    #cookie-consent-settings-button-container {
        right: 40px;
        position: absolute;

        #cookie-consent-settings-button {
            font-size: 12px;

            &::before{
                font-size: 15px;
            }
        }
    }
}

