// sass-lint:disable-all"
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .float#{$infix}-left  { @include float-left; }
    .float#{$infix}-right { @include float-right; }
    .float#{$infix}-none  { @include float-none; }
  }
}
