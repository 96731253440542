a {
    &:hover {
        @include color-primary;
    }
}

h2 {
    @extend %color-secondary;
    text-transform: initial;
}

.filters-trigger strong {
    @extend %background-color-primary;
    @extend %border-dark;

    &:hover {
        @include color-primary;
        border: 1px solid $color-best-new-light-green;
    }
}

.toolbar--top {
    .toolbar-products {
        .right-toolbar {
            .form-control {
                @extend %border-dark;

                &:hover {
                    @include color-primary;
                    border: 1px solid $color-best-new-light-green;
                }
            }
        }
    }

    .available-products input:checked + .slider {
        background-color: $color-best-new-light-green;
    }
}

.cms-page-view .columns .main a {
    text-decoration: none;
}
