.logoContainer {
    justify-content: center;
    padding: 0;

    .header__logo {
        flex-direction: column;
        display: flex;
        justify-content: center;

        .logo {
            display: flex;
            max-height: 100px;
            max-width: 300px;
            justify-content: center;

            img {
                width: auto;
                height: 100%;
            }
        }
    }
}
@media (max-width: 992px) {
    .logoContainer .header__logo .logo {
        max-height: 40px;
        justify-content: left;

        img {
            object-fit: contain;
            object-position: left center;
        }
    }
}

@include media-breakpoint-down(md) {
    .logoContainer {
        justify-content: left;
    }
}

@media (max-width: 592px) {
    .logoContainer {
        flex: 0 0 50%;
        max-width: 50%;
        justify-content: center;

        .header__logo .logo {
            justify-content: center;

            img {
                object-position: center center;
            }
        }
    }
}
