.footer {
    //padding-top: 15px;
    //border-top: 1px solid;
    padding-top: 50px;

    &-inner {
        @include make-container();
    }

    .module-footer .module-inner .footer-container {
        width: 100%;
        padding-top: 40px;

        h2 {
            border-bottom: none;
            margin-bottom: 10px;
        }

        .footer-content {
            display: flex;
            margin: 0 auto;

            .social__media {
                margin: auto auto;

                .footer__links {
                    margin: auto;

                    ul {
                        width: auto;
                    }
                }
            }

            .footer__content {
                display: flex;
                text-align: left;
                padding: 10px 0 10px;

                h2 {
                    font-size: 20px;
                }

                ul {
                    margin: auto;
                    width: 1200px;
                    padding-left: 20px;
                    padding-right: 20px;
                    justify-content: center;

                    .footer__links {
                        padding: 0;
                        display: inline-block;

                        .active {
                            .mobile-plus {
                                @extend .fa;
                                @extend .fa-minus;
                            }
                        }

                        ul {
                            width: auto;
                            padding: 0;
                            justify-content: left;

                        }

                        .list-inline {
                            display: grid;

                            .list-inline-item {
                                margin: 0;

                                a {
                                    line-height: 1.8;
                                }
                            }
                        }
                    }
                }

                .social-networks {
                    display: inline-block;
                    padding: 0 20px 20px;

                    span {
                        color: $color-dark-alt;
                        margin-bottom: 15px;
                        display: block;
                    }

                    &__item {
                        display: inline-flex;
                        margin: auto 5px;

                        a {
                            margin-right: 5px;
                        }

                        .fa {
                            color: $color-grey-dark;
                            font-size: 30px;

                            &:hover {
                                color: $color-brown;
                            }
                        }
                    }
                }
            }

            .footer__logo {
                margin-top: 80px;
                width: 10%;

            }

            .module__content {
                padding: 0 20px 30px 20px;
                width: 45%;

                .module__subtitle {
                    padding-top: 30px;
                }

                .module__form {
                    display: grid;
                    justify-content: center;
                    margin-top: 40px;

                    button {
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid white;
                    }
                }
            }
        }

        .copyright {
            .copyright__content {
                display: inline-flex;
                width: 100%;

                .copyright__content__images {
                    width: 70%;

                    img {
                        float: left;
                    }
                }

                .list-inline {
                    width: 100%;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer {

        .module-footer .module-inner .footer-container .footer-content {
            display: block;

            .content {
                display: block;
            }

            .footer__content {
                width: 100%;

                ul {
                    flex-direction: column;
                    width: 95%;
                    margin: auto;
                    display: flex;

                    .footer__links {
                        padding: 0;

                        .title {
                            border-top: 1px solid $color-dark-alt;
                            border-bottom: 1px solid $color-dark-alt;

                            .content-title {
                                display: flex;

                                h2 {
                                    margin-bottom: 10px;
                                    margin-top: 10px;
                                    padding: 0;
                                }
                            }
                        }

                        .links {
                            margin-top: 10px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid $color-dark-alt;
                        }
                    }
                }
            }
        }
    }
}

.cms-page-view footer {
    .links a {
        font-size: inherit;
    }

    h1, h2, h3 {
        padding: 0;
    }
}

@include media-breakpoint-down(xs) {
    .footer {

        .module-footer .module-inner .footer-container .footer-content {
            display: block;

            .content {
                display: block;
            }

            .footer__content {
                width: 100%;

                ul {
                    margin: 0 auto;
                }
            }

            .module__content {
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .footer {
        margin-top: 20px;

        &-inner {
            width: 100%;
        }

        .module-footer .module-inner .footer-container .footer-content {
            display: block;

            .footer__content {
                width: 100%;

                ul {
                    margin: 0 auto;
                }
            }

            .module__content {
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .footer-content {
        .footer__content {
            width: 60%;
        }

        .module__content {
            width: 40%;
        }
    }
}

@media (min-width: 768px) {
    .footer__content {
        ul {
            width: auto;
            display: flex;

            .footer__links {
                padding: 0;
                width: 20%;

                .mobile-plus {
                    display: none;
                }
            }
        }
    }
}
