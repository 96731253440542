.contact-index-index {
  .column.main {
    .contact {
      @include make-col-ready();
      @include make-col(6);
      .actions-toolbar {
        margin-top: 20px;
        .primary {
          float: right;
        }
      }
      &.extra-info {
        padding-left: 40px;
      }
      .custom-control{
        &.custom-checkbox{
          padding-left: 0;
        }
      }
      .captcha {
        &.field {
          margin-top:15px;
        }
        &-image {
          flex-wrap: wrap;
          display: flex;
          width: 100%;
          justify-content: space-between;
          .btn-link {
            display:flex
          }
        }
      }
    }
  }
}


@include media-breakpoint-down(md) {
  .contact-index-index {
    .column.main {
      .contact {
        @include make-col(12);
        &.extra-info {
          padding-left: 15px;
          margin-top: 20px;
        }
      }
    }
  }
}
