.store-locator {
  width: 100%;

  &__filters {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 10px;
    row-gap: 10px;
    margin: 0 auto 20px auto;
    max-width: 640px;
  }
  &__submit-button {
    border-radius: 0;
  }
  &__search-address-founded {
    text-align: center;
    margin-bottom: 5px;
  }
  &__loader {
    .loader-spinner {
      display: block;
      position: static;
    }
  }
  &__no-results {
    text-align: center;
    font-size: 25px;
    max-width: 80%;
    margin: 0 auto;
  }
  &__store-list {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    column-gap: 20px;
  }
  &-store {
    margin-bottom: 50px;
    width: 100%;
    max-width: 270px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    transition: background-color 200ms linear;

    &:hover {
      background-color: darken($color-grey-bg, 10%);
    }
    &__fulladdress {
      flex-grow: 1;
    }
    &__address {
      display: block;
    }
    &__country {
      display: block;
    }
    &__email {
      display: block;
    }
    &__distance {
      font-weight: bold;
    }
    &__actions-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;

      > * {
        margin-bottom: 10px;
      }
    }
    &__website {
      display: block;
    }
    &__map-link {
      display: block;
    }
  }
}

@include media-breakpoint-up(sm) {
  .store-locator {
    &__store-list {
      grid-template-columns: 1fr 1fr;
    }
    &__filters {
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }
    &__filter-container {

    }
    &__submit-button {
      line-height: inherit;
    }
  }
}

@include media-breakpoint-up(md) {
  .store-locator {
    &__store-list {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@include media-breakpoint-up(lg) {
  .store-locator {
    &__store-list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

@include media-breakpoint-up(xl) {
  .store-locator {
    &__store-list {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}