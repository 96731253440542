.breadcrumbs {
  .items {
    > li {
      a {
        &:hover {
          color: $color-tertiary;
        }
      }
      strong {
        color: $color-light;
      }
    }
  }
}
