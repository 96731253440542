.module-footer {
    @extend %background-color-primary;
    > .module {
        &-inner {
            .footer {
                &__links {
                    .list-inline {
                        &-item {
                            a {
                                @include color-primary;
                            }
                        }
                    }
                }
            }

            .copyright {
                &__content {
                    .list-inline {
                        &-item {
                            color: $color-black;

                            a {
                                @include color-primary;
                            }
                        }
                    }
                }
            }

            .social-networks {
                @include color-primary;

                .fa {
                    @include color-primary;
                }
            }
        }
    }

    .custom-checkbox{
        .custom-control-description {
            a {
                color: $color-best-new-dark-grey !important;
                text-decoration: none;
            }
        }
        .custom-control-indicator{
            border: 1px solid $color-best-new-light-green;
        }
    }

    .custom-control-description {
        @extend %color-secondary;
    }

    .module-showroon {
        //display: none;
    }


    .control{
        input::placeholder{
            @include color-primary;
        }
    }
    #newsletter-validate-detail{
        .form-inline {
            .actions {
                margin-left: 0;
            }

            button {
                @extend %button-secondary;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 0;
                height: 35px;
                margin-left: 0;
            }
        }
        .form-control{
            height: 35px;
            border: 1.5px solid $color-best-new-light-green;
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
            @extend %color-secondary;
        }
    }
}

