.page-main .columns .column.main .module-related {
    padding: 50px 0 0 0;

    .block-title {
        @extend %color-secondary;
        text-align: center;

        .h2 {
            margin: 15px;
            text-transform: lowercase;

            &:first-letter {
                text-transform: uppercase;
            }
        }
    }

    .products-grid .product-items .product-item-name {
        height: 42px;
    }

    .autorelated-filters {
        color: $color-best-new-light-green;
        margin-bottom: 25px;
        padding-bottom: 0;

        li, li.active {
            font-size: 12px;
            padding: 0 10px;

            .filter-attribute {
                color: $color-best-new-light-green;

                &:after {
                    border-color: $color-best-new-light-green;
                }

                &:hover {
                    color: $color-best-new-light-green;

                    &:after {
                        border-color: $color-best-new-light-green;
                    }
                }
            }

            &.autorelated-filters__delimiter {
                padding: 0;
            }

            &:hover:not(.active) {
                color: $color-best-new-light-green;

                &:after {
                    border-color: $color-best-new-light-green;
                }
            }
        }
    }

    .block-content {
        .products-autorelated {
            .item-inner {
                .product-item-container {
                    .product-image-wrapper {
                        img {
                            max-height: 255px;
                        }
                    }
                }
            }
        }
    }
}
