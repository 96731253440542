#dffullscreen {
    .buyBox {
        .special-price {
            .df-card__price--new {
                color: $color-red;
            }
        }
    }
}

.products-grid,
#dffullscreen {

    &.product-grid-mode-maxi,
    & .df-results:not(.tight-mode) {
        .item {
            &-inner {
                .product {
                    &-item {
                        &-details {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    .item {
        &-inner {
            display: flex;
            flex-direction: column;
            height: 100%;

            .product {
                &-item {
                    &-container {
                        text-align: center;
                        position: relative;
                        margin: 0 auto;
                        display: block;
                        width: 100%;

                        .product-image-wrapper {
                            img {
                                -webkit-box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
                                box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    &-labels {
                        .product {
                            &__label {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                color: $color-white;
                                background-color: $color-brown;
                                padding: 5px 10px;
                                line-height: 18px;
                                border-radius: 4px;
                                text-transform: uppercase;
                                font-size: 10px;
                                font-weight: $font-weight-regular;
                                text-align: center;
                                display: inline-block;
                                min-width: 55px;
                                z-index: 3;

                                &:before {
                                    content: "\f111";
                                    font-family: $font-icons;
                                    font-size: 4px;
                                    margin: 0 8px 0 -8px;
                                    display: inline-block;
                                    vertical-align: middle
                                }

                                &:after {
                                    content: "";
                                    border: 6px solid transparent;
                                    border-right: 6px solid $color-brown;
                                    position: absolute;
                                    left: -11px;
                                    top: 50%;
                                    margin-top: -6px;
                                }

                                &--new {
                                    background-color: $color-green;

                                    &:after {
                                        border-right: 6px solid $color-green;
                                    }
                                }
                            }
                        }
                    }

                    &-photo {
                        &.lazy {
                            height: 300px;
                            width: 100%;
                            display: block;
                            color: transparent;
                            background-image: url('../images/loader-2.gif');
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-color: $color-white;
                            border: none;
                            outline: none;
                        }
                    }

                    &-extra {
                        position: absolute;
                        top: 50%;
                        margin-top: -80px;
                        right: 15px;
                    }

                    &-price {
                        flex: 1;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        min-height: 28px;

                        &.--is-offer {
                            .special-price {
                                .price {
                                    color: $color-red;
                                }
                            }
                        }

                        .price-box {
                            font-weight: 600;
                            font-size: 16px;
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            padding-right: 15px;
                            flex-grow: 1;


                            .old-price {
                                .price {
                                    text-decoration: line-through;
                                    color: $color-grey-dark;
                                    font-size: 14px;
                                }
                            }
                        }

                        .product__label {
                            font-size: 15px;
                            font-weight: bold;
                            padding: 2px 5px;
                            white-space: nowrap;

                            &--discount {
                                background-color: $button-primary-background;
                                color: $button-primary-color;
                            }
                        }

                        &.grouped {
                            .price-box {
                                .price-label {
                                    font-size: 15px;
                                }
                            }
                        }
                    }

                    .grouped {
                        .price-box {
                            .price-label {
                                font-size: 15px;
                            }
                        }
                    }

                    &-name {
                        font-size: 14px;
                        letter-spacing: .6px;
                        text-transform: capitalize;
                        font-weight: $font-weight-regular;
                        margin-bottom: 2px;
                        display: flex;
                        overflow: hidden;

                        a {
                            font-weight: 100;
                            line-height: 14px;
                        }
                    }

                    &-sku {
                        font-size: 12px;
                        color: $color-black;
                    }

                    &-status {
                        font-size: 12px;
                        color: $color-grey-dark;

                        .stock {
                            color: $color-grey-dark;

                            &.available {
                                color: $color-green;
                            }

                            &.unavailable {
                                color: $color-red;
                            }
                        }

                        &.not-today {
                            color: $color-red;

                            .stock {
                                color: $color-red;
                            }
                        }
                    }

                    &-actions {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;

                        .actions-primary {
                            max-width: 100%;
                            flex: 1 100%;

                            .tocart-container {
                                width: 100%;
                            }

                            .visible {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                flex-grow: 1;
                            }

                            .unavailable.disabled {
                                font-size: 10px;
                            }

                            .field {

                                .qty {
                                    &.control {
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        justify-content: flex-start;


                                        .qty-button {
                                            border: none;
                                            background-color: transparent;
                                            font-size: 25px;
                                            padding: 0 3px;
                                        }

                                        .qty {
                                            border: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: textfield;
                                            text-align: center;
                                            font-size: 15px;
                                            width: 40px;
                                            height: 30px;
                                            padding: 0 5px;

                                            &::-webkit-outer-spin-button,
                                            &::-webkit-inner-spin-button {
                                                -webkit-appearance: none;
                                                -moz-appearance: textfield;
                                                margin: 0;
                                            }

                                            &:focus {
                                                border-color: $color-grey;
                                                outline: 0;
                                                box-shadow: none;
                                            }
                                        }

                                        #qty-error {
                                            position: absolute;
                                            bottom: 30px;
                                            left: 0;
                                        }
                                    }
                                }
                            }

                            form {
                                display: flex;
                            }
                        }

                        .action {
                            &.tocart {
                                border-radius: 0;
                                font-size: 14px;
                                min-height: 30px;
                                flex-grow: 1;
                                text-align: center;
                            }
                        }
                    }

                    &-details {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        justify-content: space-between;
                        margin: 0 auto;
                        //max-width: 255px;
                        padding: 15px 0 40px;
                        position: relative;
                        width: 100%;

                        .product-reviews-summary {
                            .reviews-actions {
                                display: none;
                            }
                        }
                    }
                }

                &-image {
                    &-wrapper {
                        display: block;
                        padding: 0;
                    }

                    &-photo {
                        height: auto;
                        max-width: 100%;
                        width: 100%;
                    }
                }

                &-availability {
                    position: relative;
                }
            }

            &:hover {
                .product {
                    &-item {
                        &-container {
                            .product-item-photo {
                                &:not(.do-hover) {
                                    .product-image-wrapper {
                                        img {
                                            -webkit-box-shadow: 7px 7px 15px 0 rgba(0, 0, 0, 0.1);
                                            box-shadow: 7px 7px 15px 0 rgba(0, 0, 0, 0.1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .product-item--mock {
        .product-item-actions {
            .qty-button {
                cursor: default;
            }

            .action {
                &.tocart {
                    background-color: $color-grey-dark !important;
                    box-shadow: none !important;
                    color: $color-grey-dark;
                    cursor: default;
                }
            }
        }
    }
}

.product__details__category_image {
    display: grid;

    #tab-label-category-image {
        width: min-content;
    }

    .container {
        display: block;

        .item-block {
            width: 100%;
            padding: 10px;
            text-align: center;
            float: left;

            img {
                height: 70px;
                width: 400px;
                margin-bottom: 20px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .product__details__category_image {
        .container {
            .item-block {
                width: 280px;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .products-grid {
        .item {
            &-inner {
                .product-item {
                    &-name {
                        min-height: 42px;
                    }
                }
            }
        }
    }
}

@media (min-width: #{map-get($container-max-widths, 'xl')}) {
    .products-grid {
        .item {
            &-inner {
                .product-item {
                    &-name {
                        min-height: 34px;
                    }

                    &-price {
                        .price-box {
                            font-size: 17px;

                            .old-price {
                                .price {
                                    font-size: 15px;
                                }
                            }
                        }
                    }

                    .product__label {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
