.my-account-page {
    .page-title-wrapper {
        @extend %container-disable;
        margin: 20px 0;

        .page-title {
            display: inline-block;
        }
    }

    .columns {
        margin: 0 auto;

        .main {
            .acount-information {
                padding-bottom: 40px;

                a {
                    text-decoration: underline;
                }
            }

            .message-warning {
                float: left;
                display: block;
                width: 100%;
                margin-top: 0;

                a {
                    float: right;
                    text-decoration: underline;
                }
            }

            .addresses-list {
                color: #0a0a0a;
            }

            .block-title {
                margin-bottom: 15px;
            }

            .table-wrapper {
                tr {
                    &:nth-child(even) {
                        background: $color-white-dirty;
                    }
                }

                .col {
                    &.actions {
                        //float: right;
                        text-align: right;
                        padding-right: 0;
                    }

                    &.status {
                        min-width: 100px;
                    }

                    &.category {
                        min-width: 140px;
                    }
                }
            }

            .block {
                .block-title {
                    @extend .h2;
                    font-weight: $font-weight-light;
                    margin-bottom: 20px;

                    .action {
                        font-size: small;
                        font-weight: $font-weight-light;
                        margin-left: 15px;
                    }

                }

                .block-content {
                    @include make-row();
                    width: 100%;

                    .table-wrapper {
                        @include make-col-ready();
                        @include make-col(12);

                        .table {
                            th {
                                white-space: nowrap;
                            }

                            tbody {
                                > tr {
                                    > td {
                                        &.actions {
                                            white-space: nowrap;

                                            .action + .action {
                                                &:before {
                                                    content: '|';
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .addresses {
                        max-width: 100%;
                        flex: max-content;
                        margin: 0;

                        .actions {
                            font-size: 12px;

                            .action {
                                + .action {
                                    &:before {
                                        content: '|';
                                    }
                                }
                            }
                        }
                    }

                    .box {
                        @include make-col-ready();
                        @include make-col(6);
                        margin-bottom: 20px;
                        overflow-x: auto;
                        padding-bottom: 10px;

                        &-title {
                            @extend .h3;
                            margin-bottom: 10px;
                            display: block;
                        }

                        &-actions {
                            font-size: 12px;

                            .action {
                                margin-right: 5px;
                                text-decoration: underline;

                                + .action {
                                    &:before {
                                        content: '|';
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }

                    .empty {
                        @include make-col-ready();
                        @include make-col(12);
                    }
                }

                + .block {
                    margin-top: 40px;
                }

                &.block-addresses-list {
                    .block-content {
                        margin: 0;
                        .item {
                            padding: 10px 10px 10px 0;

                            address {
                                margin: 0;
                            }

                            .temporary_address {
                                @include temporaryAddress;
                            }
                        }
                    }
                }
            }

            .form {
                @include make-row();
                display: block;

                .form-inner {
                    @include make-col-ready();
                    @include make-col(6);
                }

                .fieldset {
                    @include make-col-ready();
                    @include make-col(12);

                    .legend {
                        @extend .block-dashboard-title;
                        margin: 0 0 25px;
                    }

                    .field {
                        margin: 0 0 29px;

                        select {
                            @extend .form-control;
                        }

                        .nested {
                            margin-top: 10px;
                        }

                        .street_other {
                            display: none;
                        }
                    }
                }

                .actions-toolbar {
                    @include make-col-ready();
                    @include make-col(12);
                    margin: 25px 0;

                    .primary {
                        float: left;
                    }

                    .secondary {
                        float: right;
                    }
                }

                .contact-us-form {
                    text-decoration: underline;
                }

                &.form-addbysku {
                    width: 100%;

                    .additional {
                        border-top: 2px solid #dee2e6;
                        display: inline-block;
                        width: 100%;
                        padding-top: 20px;

                        &:first-child {
                            border-top: 0;
                        }

                        .field {
                            legend {
                                margin-bottom: 10px;
                            }

                            .control {
                                padding: 0;
                                display: inline-block;
                                width: 100%;

                                &:last-child {
                                    float: right;
                                }

                                .qty {
                                    max-width: none;
                                }
                            }
                        }
                    }
                }

                .upload.skus {
                    .actions-toolbar {
                        padding: 0;
                    }

                    input:focus {
                        outline: none;
                    }

                    .note {
                        margin-top: 20px;
                    }

                    .custom-file-label::after {
                        content: "\f093";
                        font-family: $font-icons;
                    }
                }
            }

            .actions-toolbar {
                .toolbar-top {
                    padding: 0;
                    margin: 0;
                    border: none;

                    .pages {
                        display: none;
                    }
                }
            }

            .bottom.toolbar {
                .limiter {
                    display: none;
                }

                .pager {
                    width: 100%;
                }
            }

            .fieldset {
                .field {
                    margin: 0 0 29px;
                }
            }

            .actions-toolbar {
                margin: 10px 0 20px;
                float: left;
                width: 100%;
                display: block;

                .primary {
                    float: left;
                }

                .secondary {
                    float: left;

                    .secondary-buttons {
                        .update-data {
                            padding: 30px 0;
                        }

                        .pay-info {
                            padding-bottom: 30px;

                        }

                        .field {
                            padding-bottom: 10px;
                            font-weight: bold;
                        }
                    }
                }

                .action-default {
                    cursor: pointer;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    background-color: $button-primary-background;
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 38px;
                    padding: 0 18px;
                    color: $color-white;
                    font-weight: $font-weight-semibold;
                    border: 0;

                    &:hover {
                        background-color: $button-primary-background-hover;
                    }

                    &:active {
                        background-color: $color-brown !important;
                        -webkit-box-shadow: 0 0 0 0.2rem $color-brown !important;
                        box-shadow: 0 0 0 0.2rem $color-brown !important;
                    }
                }
            }

            .info-payment {
                text-align: right;
                margin: 20px 0;
            }

            .dashboard-static-block-predebt {
                float: left;
                width: 100%;
                display: block;
                margin-bottom: 25px;
            }

            .block-dashboard-orders {
                margin-bottom: 40px;
            }

            .dashboard-resume {
                margin-bottom: 40px;

                .block-dashboard-title {
                    text-transform: uppercase !important;
                    font-size: 24px !important;
                    line-height: 30px;;
                    font-weight: 600;
                    margin: 20px 0;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }

                .block {
                    &-content {
                        margin: 10px 0;
                        float: left;
                        width: 100%;
                    }

                    &-title {
                        margin: 30px 0 15px;
                        text-transform: Capitalize;
                        font-size: 20px;
                        float: left;
                        width: 100%;
                        display: block;
                    }
                }

                .info {
                    padding: 0;
                }

                .linkall {
                    z-index: 1;
                    position: relative;
                    float: right;
                    margin-top: -55px;
                }

                .table-wrapper {
                    margin-bottom: 40px;
                }
            }

            .multiplier-container {
                margin: 20px 0;
                float: left;
                width: 100%;
                display: block;

                .control {
                    max-width: 170px;
                }

                .actions-toolbar {
                    margin-top: 0;
                }
            }

            .table-invoice-items {
                th {
                    white-space: normal;
                    vertical-align: middle !important;
                }
            }

            .block-dashboard-info {
                float: left;
                display: block;
                width: 100%;
            }

            .block-dashboard-addresses {
                margin-bottom: 50px;
                float: left;
                display: block;
                width: 100%;
            }

            .toolbar {
                .limiter-options {
                    @extend .form-control;
                    width: 65px;
                    display: inline;
                }
            }
        }
    }

    .reorder-quote-popup {
        .text-secondary {
            display: none;
        }

        .actions-toolbar {
            .action {
                margin-left: 10px;
            }

            .primary {
                float: right;
            }

            .secondary {
                display: none;
            }
        }
    }

    .sidebar--floating {
        min-width: 255px;
        max-width: 255px;
    }

    .interblock {
        margin: 20px 0 !important;
    }
}

.sales-order-view {
    .page-title-wrapper {
        .order-status {
            @extend .badge;
            @extend .badge-primary;
            font-size: 18px;
            margin-left: 15px;
            transform: translateY(-5px);
        }

        .order-actions-toolbar {
            margin: 20px 0;

            .actions {
                .reorder {
                    margin-right: 20px;
                }

                .print {
                    float: right;
                    display: block;
                }
            }
        }
    }

    .order-links {
        @include clearfix();

        .item {
            float: left;
            margin: 0 -1px 0 0;
            line-height: 40px;

            strong {
                margin-bottom: -1px;
                border: 1px solid $color-black;
                background-color: $color-white;
                border-bottom: 0;
                display: block;
                padding: 0 20px;
            }
        }
    }

    .order-details-items {
        margin-top: -1px;
        padding: 25px;
        border: 1px solid $color-black;
        margin-bottom: 40px;

        .order-title {
            display: none;
        }

        .order-items {
            .table {
                tfoot .amount, tfoot .mark {
                    text-align: right;
                }
            }
        }
    }
}

.quickorder-index-index .columns .main .block-addbysku {
    width: 100%;

    .form-addbysku {
        .additional {
            border: 0 !important;

            &.new-block {
                display: inline-block;
                width: 100%;
                padding-top: 20px;
                border: 0;

                .field {
                    legend {
                        margin-bottom: 10px;
                    }
                }

                .control {
                    padding: 0;
                    display: inline-block;
                    width: 100%;

                    & + .control {
                        float: right;
                    }

                    .qty {
                        max-width: none;
                    }
                }
            }
        }
    }
}

.newsletter-manage-index {
    .columns {
        .main {
            .form {
                .form-inner {
                    .fieldset {
                        & > .legend {
                            @extend .h2;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

.company-profile-index {
    .company-profile-payment-methods {
        @extend .box;
    }
}

.company-index-index {
    .columns {
        .main {
            .block {
                .block-content {
                    .box-actions {
                        @include make-col-ready();
                        @include make-col(12);
                        display: flex;
                        justify-content: center;
                        order: 2;
                        margin-top: 25px;

                        .action {
                            padding-left: 0;
                            padding-right: 0;
                            color: $color-grey-alt;
                            background: none;
                            border: none;

                            &:hover {
                                color: $color-brown;
                                text-decoration: none;
                            }

                            &:before {
                                padding: 0 10px;
                                color: $color-grey-alt !important;
                            }
                        }
                    }

                    .tree {
                        @include make-col-ready();
                        @include make-col(12);

                        .jstree-clicked {
                            &:before {
                                content: '\e610';
                                font-family: 'Blank-Theme-Icons';
                            }
                        }
                    }
                }
            }
        }
    }
}

.myaccount-operations-index {
    .info347 {
        .block-content {
            margin: 0 !important;
            padding-bottom: 40px;
            display: block !important;

            .box-title {
                strong {
                    line-height: 40px;
                }

                .btn {
                    margin-left: 30px;
                }
            }
        }

        .table {
            .total-row {
                border-top: 1px solid;

                .total {
                    font-weight: bold;
                }
            }
        }
    }
}

#myaccount-deliveries {
    a {
        text-decoration: underline;
    }
}

.quickorder-block {
    .block-title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0;
    }

    .block-content {
        margin: 0 !important;
    }

    .box {
        width: 100%;
        display: block;
        max-width: 100% !important;
    }

    form {
        margin: 0 !important;

        .orderby-sku {
            .box-items {
                width: 100%;
                max-width: 100% !important;

                .fieldset {
                    .field {
                        display: inline-block;
                        margin: 0 0 15px !important;

                        input {
                            padding-left: 10px;
                        }

                        &.sku {
                            width: 60%;

                            input {
                                width: 90%;
                                max-width: 230px;
                            }
                        }

                        &.qty {
                            width: 20%;
                        }
                    }

                    .actions-toolbar {
                        display: inline-block;
                        float: right;
                        width: auto !important;
                        margin: 0 !important;

                        .remove {
                            border: none;
                            background: none;

                            &.no-display {
                                display: block;
                            }
                        }
                    }

                    .fields.additional {
                        padding: 0 !important;
                        border-top: none !important;
                    }
                }

                .actions-toolbar {
                    width: 100%;
                    display: block;
                    text-align: left;

                    .secondary {
                        float: left !important;
                    }
                }
            }
        }

        .upload.skus {
            display: block;
            flex: 100%;

            .input-text {
                width: 80%;
                display: inline-block;
                overflow: hidden;
            }

            .action {
                width: 20%;
                float: right;
                display: inline-block;
            }
        }

        .action.reset {
            font-size: 80%;
            font-weight: 400;
            border: none;
            margin-top: 8px;
            background: none;

            .fa {
                margin-right: 8px;
            }

            &:hover {
                color: $color-brown;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .my-account-page {
        .columns {
            .sidebar--floating {
                max-width: 220px;
                min-width: 220px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .my-account-page {
        .columns {
            .main {
                .table {
                    th {
                        display: none;
                    }

                    td {
                        display: block !important;
                        padding: 2px !important;

                        &:before {
                            content: attr(data-th) ":";
                            display: inline-block;
                            padding-bottom: 10px;
                            padding-right: 5px;
                            font-weight: bold;
                        }
                    }

                    .col {
                        &.actions {
                            float: none;

                            &:before {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .my-account-page {
        .columns {
            .main {
                .message-warning {
                    a {
                        float: none;
                    }
                }

                .message.empty {
                    clear: both;
                    width: 100%;
                    margin-top: 0;
                }

                .block {
                    .block-content {
                        .addresses {
                            @include make-col(12);
                        }

                        .box {
                            @include make-col(12);
                        }
                    }
                }

                .form {
                    .form-inner {
                        @include make-col(12);
                    }
                }

                .dashboard-resume {
                    margin-bottom: 40px;

                    .block-dashboard-info {
                        .block-title {
                            font-size: 18px;
                            margin-top: 20px !important;
                        }
                    }

                    .info {
                        padding: 0;
                    }

                    .linkall {
                        float: left;
                        margin-top: 0;
                    }

                    .table-wrapper {
                        margin-bottom: 40px;
                    }
                }

                .info-payment {
                    .btn {
                        margin-bottom: 20px;
                    }
                }
            }

            .sidebar--floating {
                margin-bottom: 25px;
                min-width: 100%;
                max-width: 100%;
                -webkit-transition: background-color 2s ease-out;
                -moz-transition: background-color 2s ease-out;
                -o-transition: background-color 2s ease-out;
                transition: background-color 2s ease-out;

            }
        }
    }
    .myaccount-operations-index {
        .info347 {
            .table {
                .total-row {
                    border-top: 1px solid;

                    .total-text {
                        display: none !important;
                    }
                }
            }
        }
    }
    .secondary {
        text-align: right;

        .action-default {
            margin: 5px 0;
        }
    }
    .toolbar-top {
        width: 100%;
        margin-top: 40px !important;
    }
    .quickorder-block {
        form {
            margin: 0 !important;

            .toggle-content {
                padding: 0 !important;
            }

            .fieldset {
                padding: 0 !important;
            }

            .orderby-sku {
                .box-items {
                    .fieldset {
                        .field {
                            &.sku {
                                width: 50%;
                            }

                            &.qty {
                                width: 30%;

                                input {
                                    width: 100%;
                                }
                            }
                        }

                        .actions-toolbar {
                            padding: 5px !important;

                            .remove {
                                &:before {
                                    content: "\f00d";
                                    font-family: $font-icons;
                                }

                                span {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .upload.skus {
                display: block;
                flex: 100%;

                input {
                    display: none;
                }

                .input-text {
                    width: 100%;
                    display: block;
                    text-align: left;
                }

                .action {
                    width: 100%;
                    float: right;
                    display: block;
                    margin: 50px 0 20px;
                    text-align: center;
                }
            }
        }
    }
}
