
// ==========================================================================
// = Global Variables
// ==========================================================================

/**
 * CSS layout debugging
 * @link http://pesticide.io/
 * @type boolean
 */

$pesticide-debug              : false;

/**
 * Define width for browsers w/out media query support
 * @link http://jakearchibald.github.com/sass-ie/
 * @type boolean
 */

$fix-mqs                      : false !default;

/**
 * Turn on/off IE specific styles
 * @link http://jakearchibald.github.com/sass-ie/
 * @type boolean
 */

$old-ie                       : false !default;

// Color Scheme
// =============

// Descriptive colors:
$color-black      : #000000;
$color-dark       : #444343; //Bestproducts
$color-dark-light : #3a3a3a;
$color-dark-alt   : #575757; //ixia
$color-white      : #fff;
$color-white-dirty: #f5f5f5;
$color-light      : #d3d3d3; //ixia
$color-grey-dark  : #97928e;
$color-grey       : #eaeaea; //ixia
$color-grey-alt   : #adadad; //ixia
$color-grey-bg    : #f2f2f2; //ixia
$color-grey-btn   : #ebebeb; //ixia
$color-beige      : #f5f5ed;
$color-orange     : #ff974e;
$color-blue       : #006fc4;
$color-blue-light : #0280ef;
$color-cyan       : #76b6bf;
$color-brown      : #deb283;

//Bestproducts
$color-best-blue  : #1F6395;
$color-best-grey  : #434A4E;
$color-best-new-light-grey: #d3dce0;
$color-best-new-dark-grey: #82919a;
$color-best-new-light-green: #45b3b8;
$color-best-new-gradient-green: #8FC9AE;
$color-best-new-gradient-blue: #45B3B8;
$color-best-new-gradient: linear-gradient(90deg, $color-best-new-gradient-green 30%, $color-best-new-gradient-blue 70%);
$color-best-new-hover: #ACBCC5;


//
$color-grey-light : #e4e4e4;
$color-orange-light: #c07600;
$color-red        : #fe3f3f;
$color-red-light  : #fae5e5;
$color-green-msg  : #82A91A;
$color-green      : #90e1cf;

// Special theme color changes
$color-background  : $color-white;
$color-primary     : $color-best-new-light-green;
//$color-text        : $color-dark;
$color-text        : $color-best-new-dark-grey;
$color-secondary   : $color-best-grey;
$color-brown       : $color-best-grey;
//$color-tertiary    : $color-best-grey;
$color-tertiary    : $color-best-new-dark-grey;
$color-menu        : $color-primary;
$color-text-header-secondary: $color-best-new-dark-grey;
$color-border-search: $color-best-new-dark-grey;
$color-switcher-language: $color-best-new-dark-grey;
$color_background_minicart    : $color-best-new-dark-grey;
$color_text_minicart    : $color-white;
$background-color-header    : $color-white;
$breadcrumbs-color-primary: $color-secondary;
$breadcrumbs-color-hover-secondary: $color-primary;

$menuBackgroundColor: #f8f8f8;
$color-close: #808080;

// Buttons

$button-primary-background: $color-best-new-light-green;
$button-primary-color: $color-white;
$button-primary-border: $color-best-new-light-green;

$button-primary-background-hover: $color-best-new-hover;
$button-primary-color-hover: $color-white;
$button-primary-border-hover: $color-best-new-light-green;

$button-secondary-background: $color-white;
$button-secondary-color: $color-best-new-light-green;
$button-secondary-border: $color-best-new-light-green;

$button-tertiary-background: transparent;
$button-tertiary-color: $color-black;
$button-tertiary-border: $color-grey-dark;

$button-tertiary-background-hover: $color-brown;
$button-tertiary-color-hover: $color-white;
$button-tertiary-border-hover: $color-brown;

$breadcrumbs-link: $color-light;

$btn-link-color: $color-best-new-light-green;
$btn-link-color-hover: $color-secondary;

$welcome-text: $color-grey-alt;

// Base Sizes
// ==========

/**
 * Base font size in used in _mixins.scss
 * @type number
 */

$base-font-size               : 16;

/**
 * Base line-height in used in _mixins.scss
 * @type number
 */

$base-line-height             : 24;


// Font Stacks
// ===========

/**
 * (optional) URL for Google Fonts import
 * @type string
 */

$google-fonts-url          : 'https://fonts.googleapis.com/css?family=Lato|Poppins:400,600,700,900';

/**
 * (optional) names of webfonts imports
 * @type array
 */

$font-primary: "Poppins", Helvetica, Arial, sans-serif;
$font-secondary: "Poppins", sans-serif;
$font-icons: FontAwesome;
$font-icons-alt: 'Blank-Theme-Icons';

$fonts                     : $font-icons-alt;

// Font Weights
// ============

$font-weight-thin             : 100;
$font-weight-light            : 300;
$font-weight-normal           : 400;
$font-weight-regular          : 600;
$font-weight-semibold         : 700;
$font-weight-bold             : 900;


// Breakpoints
// There are no common breakpoints; examples based on the official Sass styleguide
// You'll need to define your own breakpoints to suit your design
// ============

// Bootstrap Breakpoints  . Go to Bootstrap docs

/**
 * Define z-indexes for various elements
 * @link http://www.sitepoint.com/using-sass-maps/
 * @type number
 */

// z-index reference
// Bootstrap Z-index. Go to Bootstrap docs
