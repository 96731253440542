/*-----------------------
   GLOBAL CMS
 -----------------------*/
.cms-page-view {
  h1, h2, h3 {
    padding:20px 0 10px;
    margin-bottom:30px;
    border-bottom:1px solid $color-grey-dark;
    width:100%;
    &.page-title {
      border-bottom: none;
    }
    &.background-white{
      background: $color-white;
      margin: 0;
      padding: 10px;
      margin-bottom: 10px;
      border-bottom: 0;
    }
  }
  .columns {
    .main {
      a {
        text-decoration: underline;
      }
      .btn {
        text-decoration: none !important;
        &.square{
          border-radius: 0;
        }
      }
      .background-white-content{
        padding: 0 10px;
      }
    }
  }
  .links {
    li {
      list-style: circle;
      display: block;
      line-height: 16px;
    }
    a {
      color: $color-grey-dark;
      font-size: 12px;
    }
  }
  .blocks {
    padding-bottom: 20px;
  }
  .module-cms{
    width: 45%;
    margin: 0 auto;
    &.flickr{
      h2.h2--full{
        &:before{
          background: url(../images/icons/ico_GalleryFlickr.png) center center no-repeat;
          -webkit-background-size: 100%;
          background-size: 100%;
          width: 40px;
          height: 40px;
        }
        &:after{
          border-color: #c1c1c1;
        }
      }
      .module__subtitle{
        margin: 25px auto;
        float: left;
      }
    }
    a{
      text-decoration: none !important;
    }
    h2{
      border: 0;
      padding-top: 10px;
      text-decoration: none;
      color: #212529;
    }

  }
}
/*-----------------------
   PRIVACY POLICY
 -----------------------*/
.privacy-policy ol  {
  margin-bottom: 20px;
}
.privacy-policy ol li {
  list-style-type: decimal;
  padding-left: 7px;
  margin-left: 30px;
  margin-bottom: 5px;

}
/*-----------------------
   ZONAS COMERCIALES
 -----------------------*/
.cms-zonas-comerciales {
  .country-name {
    padding:20px 0 10px;
    margin-bottom:30px;
    border-bottom:1px solid $color-grey-dark;
    width:100%;
  }
  .agents {
    &__area{
      &-item {
        min-height:330px;
        .contact {
          padding-left:10px;
        }
        h3 {
          background:$color-white;
          margin:0;
          padding:10px;
          margin-bottom: 10px;
          border-bottom:0;
        }
        .faq-txt {
          padding:10px 0 0 20px;
        }
        .agent-name {
          color:$color-grey-dark;
          font-size: 20px;
          margin:10px 0;
        }
        .job-title {
          color: $color-dark-alt;
          font-size:16px;
          margin-bottom:0;
          strong {
            font-size: 13px;
          }
        }
        h5 {
          color: $color-dark-alt;
          font-size: 13px;
          margin-bottom: 0;
          padding-top: 8px;
        }
        .phone, .mailto,  .fax {
          margin:0;
        }
        .phone a, .mailto a, .fax {
          font-size: 16px;
          color: $color-dark-alt;
        }
      }
      .short-item {
        min-height: 200px;
      }
    }
  }//agents
}//zonas comerciales
/*-----------------------
   SHOWROOM
 -----------------------*/
.showroom {
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
  a {
    width: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    text-decoration: none;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    img {
      width: 100%;
    }
  }
  a:hover {
    opacity: 0.8;
    transition: all 0.5s ease;
    text-decoration: none;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  .intergift {
    margin-top: 4px;
    margin-bottom: 90px;
  }
  .btns-bajo {
    margin-bottom: 90px
  }
  .col-inner {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }

  a.btns-landing {
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 218px;
    font-size: 23px;
    font-weight: bold;
    color: $color-grey-dark !important;
    padding-top: 55px;
    padding-left: 35px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    line-height: 1.3;
    text-align: left;
  }
  .btns-landing span {
    color: white !important;
  }

  .btn-novedades-land {
    background: $color-dark url("http://news.ixia.es/showroom/banners-footer-1.png");
    background-size: cover;
    margin: 0 15px 0 0;
  }
  a:hover.btn-novedades-land {
    text-decoration: none!important; opacity: 0.8;
  }

  .btn-agente-land {
    background: $color-dark url("http://news.ixia.es/showroom/banners-footer-base2.jpg");
    background-size: cover;
    background-position: right center;
    margin: 0 0 0 15px;
  }
  a:hover.btn-agente-land {
    text-decoration: none!important;
    opacity: 0.8;
  }
  .flickr{
    &>iframe {
      width: 101.5%;
      height: 633px;
      float: left;
      margin: 0 -8px 20px;
    }
    .btn-container{
      width: 100%;
      float: left;
      .btn.btn--primary.btn-small{
        display: inline-block;
        margin: 0 auto 90px;
        width: auto;
      }
    }
  }
}
//Cierre de sesión
.customer-account-logoutsuccess{
  p{
    padding: 15px;
  }
}
.curriculum-block{
  @include make-row();
  max-width: none;
  .extra-info {
    @include make-col-ready();
    @include make-col(3);
    padding-left: 40px;
  }
  .izqda {
    @include make-col-ready();
    @include make-col(9);
    .field{
      &.authorization{
        position: relative;
        background: #fff;
        padding: 8px 8px 8px 8px;
        border: 1px solid $gray-400;
        margin-bottom: 1rem;
        .form-group{
          margin-bottom: 0;
        }
        label{
          width: 100%;
          outline: none;
          cursor: pointer;
          &:after{
            content: "\f078";
            font-family: $font-icons;
            float: right;
          }
          &[aria-selected="true"]{
            &:after{
              content: "\f077";
            }
          }
        }
        table{
          border: 1px solid #ddd;
          tr{
            th, td{
              border: 1px solid #ddd;
              padding: 10px;
            }
          }
          table{
            border: 0;
            th, td{
              padding: 0;
              border: 0;
            }
          }
        }
      }
    }
    .actions-toolbar {
      margin-top: 20px;
      .primary {
        float: right;
      }
    }
  }
}

.detail-content-block-about-us {
    ul {
        padding: revert;

        li {
            list-style: revert;
        }
    }
}

@include media-breakpoint-down(md) {
   .curriculum-block{
     margin-left: 15px;
     margin-right: 0;
     .extra-info, .izqda {
       @include make-col(12);
       padding-left: 0;
     }
   }
 }
@include media-breakpoint-down(sm) {
  .showroom{
    .btn-novedades-land {
      margin: 15px 0;
    }
    .btn-agente-land {
      margin: 15px 0;
    }
    .flickr {
      & > iframe {
        width: 100%;
        height: 633px;
        float: left;
        margin: 0 0 90px;
      }
    }
  }
  .cms-page-view{
    .module-cms{
      width: 90%;
    }
  }
}

