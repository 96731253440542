.catalog-product-view {
    .columns .column.main {

        .product-important {
            @include make-col-ready();
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;

            .product__media {
                @include make-col-ready();
                @include make-col(12);
                @include flex-order(1);
                margin-bottom: 30px;
                z-index: 0;
            }

            .product__info {
                @include make-col-ready();
                @include make-col(12);
                @include flex-order(2);
                position: static;

                .page-title-wrapper {
                    .page-title {
                        margin-top: 0;
                        font-size: 18px;
                        text-align: left;
                        margin-bottom: 10px;
                    }
                }

                .page-title-wrapper {
                    width: 80%;
                }

                .product.attribute.description {
                    float: left;
                    margin-top: 10px;
                    border-top: solid 1px $color-grey;
                    border-bottom: solid 1px $color-grey;
                    padding: 15px 0;
                    width: 100%;
                }

                .product-info {
                    &__short {
                        @include make-row();
                        flex-direction: column;

                        .attribute {
                            margin-top: 5px;
                            @include make-col-ready();
                            @include make-col(4);
                            display: flex;
                            font-style: italic;

                            .type {
                                font-weight: $font-weight-light;
                                margin-right: 5px;
                            }

                            .value {
                                font-weight: $font-weight-regular;
                            }

                            &.overview {
                                @include make-col(12);
                            }

                            &.product-info__stock {
                                display: block;
                                @include make-col(8);

                                .value {
                                    display: inline;
                                    font-weight: $font-weight-normal;
                                }

                                .no-today {
                                    color: $color-red;

                                    .value {
                                        color: $color-red;
                                    }
                                }
                            }
                        }

                        .details-modules {
                            padding: 0 0 0 15px;
                        }
                    }

                    &__price {
                        @include make-row();
                        margin-top: 20px;

                        .price-box {
                            @include make-col-ready();
                            @include make-col(8);
                            font-size: 24px;
                            line-height: 30px;
                            font-weight: $font-weight-regular;

                            .price-label {
                                display: none;
                            }

                            .price-from, .price-to {
                                @extend .h4;

                                .price-container {
                                    .price-label {
                                        display: inline-block;
                                    }
                                }
                            }

                            .old-price {
                                .price {
                                    text-decoration: line-through;
                                    font-size: 75%;
                                    font-weight: $font-weight-light;
                                    color: $color-grey-dark;
                                }
                            }
                        }

                        .product-info__stock-sku {
                            @extend .h5;
                            @include make-col-ready();
                            @include make-col(4);
                            display: none;
                            text-align: right;
                        }
                    }

                    &__bundle-actions {
                        text-align: right;
                    }

                    &__shortdescription {
                        border-top: 1px solid $color-grey;
                        padding-top: 25px;
                        margin-bottom: 22px;

                        .type {
                            display: none;
                        }
                    }

                    &__social {
                        display: none;
                        margin-top: 15px;
                        padding-top: 20px;
                        text-align: right;
                        border-top: 1px solid $color-grey;
                        font-size: 20px;

                        .action {
                            @extend .fa;

                            &.towishlist {
                                @extend .fa-heart;
                            }

                            &.tocompare {
                                @extend .fa-bar-chart;
                            }

                            &.mailto {
                                @extend .fa-envelope
                            }

                            span {
                                display: none;
                            }

                            + .action {
                                margin-left: 15px;
                            }
                        }
                    }

                    &__previousnext {
                        margin-top: 15px;
                        padding-top: 25px;
                        display: flex;
                        justify-content: space-between;
                        float: left;
                        width: 100%;

                        .prev {
                            margin-right: 10px;
                        }

                        .btn {
                            width: 175px;

                            &--prev {
                                &:before {
                                    content: '< ';
                                }

                                &:hover {
                                    background-color: $color-brown;
                                    border-color: $color-brown;

                                    &:before {
                                        color: $color-white;
                                    }
                                }
                            }

                            &--next {
                                &:after {
                                    content: ' >';
                                }

                                &:hover {
                                    background-color: $color-brown;
                                    border-color: $color-brown;

                                    &:after {
                                        color: $color-white;
                                    }
                                }
                            }
                        }
                    }
                }

                .product-add-form {
                    margin: 20px 0;

                    .fieldset {
                        display: flex;
                        justify-content: flex-start;

                        .field {
                            .control {
                                display: flex;
                                margin-right: 20px;

                                &.grouped-qty {
                                    margin-right: 0;
                                }

                                button, a {
                                    width: 45px;
                                    height: 39px;
                                    border: 1px solid $color-grey;
                                    background-color: $color-grey;
                                    font-size: 20px;
                                }

                                input {
                                    text-align: center;
                                    width: 60px;
                                    outline: none;
                                    border: 1px solid #eaeaea;

                                    &::-webkit-outer-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0
                                    }

                                    &::-webkit-inner-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0
                                    }
                                }

                                input[type='number'] {
                                    -moz-appearance: textfield;
                                }

                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                .label {
                                    margin-right: 10px;
                                    margin-top: 10px;
                                    display: none;
                                }

                                input[type=number]::-webkit-inner-spin-button,
                                input[type=number]::-webkit-outer-spin-button {
                                    opacity: 0;
                                }
                            }
                        }

                        .actions {
                            display: flex;
                            align-items: flex-end;
                            width: 100%;
                            text-align: center;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    .swatch-opt {
                        margin-top: 0;
                    }

                    .grouped {
                        .col.qty {
                            text-align: center;
                        }
                    }
                }
            }

            .product__details {
                @include make-col-ready();
                @include make-col(12);
                @include flex-order(3);
                margin-top: 30px;
                z-index: 0;
            }
        }


        .product__bundle-container {
            @include make-col-ready();
            @include make-col(12);
            @include flex-order(3);
            margin-top: 30px;

            .product-add-form {
                display: none;
            }
        }

        .module-related {
            @include make-col(12);
            @include flex-order(10);

            .autorelated-filters {
                text-align: center;
                margin-bottom: 20px;
                white-space: nowrap;
                overflow: auto;
                padding-bottom: 20px;

                li {
                    display: inline-block;
                    padding-right: 15px;

                    .filter-attribute {
                        color: $color-dark-light;
                        text-transform: capitalize;
                        margin-bottom: 10px;

                        &:hover {
                            color: $color-black;
                        }
                    }

                    &:hover:not(.active) {
                        &:after {
                            content: '';
                            display: block;
                            border: solid 1px $color-black;
                            margin-top: 10px;
                            transform: scaleX(1);

                            .filter-attribute {
                                color: $color-black;
                            }
                        }
                    }

                    &:last-child {
                        .filter-attribute {
                            margin-right: 0;
                            padding-right: 0;
                        }
                    }

                    &.active {
                        .filter-attribute {
                            color: $color-black;

                            &:after {
                                content: '';
                                display: block;
                                border: solid 1px $color-black;
                                margin-top: 10px;
                            }
                        }
                    }

                    &:after {
                        display: block;
                        content: '';
                        transform: scaleX(0);
                        transition: transform 250ms ease-in-out;
                    }
                }

                &__delimiter:last-child {
                    display: none;
                }
            }

            .products-autorelated {
                padding: 0;
            }
        }

        .product__aside {
            @include make-col-ready();
            @include make-col(3);
            @include flex-order(3);

            .module-related {
                @include make-col(12);
                padding: 0;

                .block-title {
                    .h2 {
                        font-size: 16px;
                        text-align: left;
                        margin: 0;
                        border-bottom: 1px solid;
                        padding-bottom: 5px;
                        margin-bottom: 14px;

                        &:before, &:after {
                            content: none;
                        }
                    }
                }

                .block-content {
                    max-height: 453px;
                    overflow: hidden;

                    .products-grid {
                        padding: 0;
                    }
                }

                .products-grid {
                    .product-items {
                        .product-item {
                            @include make-col(12);
                            margin-bottom: 10px;

                            .product-image-wrapper {
                                padding: 0;
                            }

                            &-info {
                                display: flex;
                                flex-direction: row;
                            }

                            &-labels {
                                display: none;
                            }

                            &-container {
                                display: flex;
                                width: 30%;
                                align-items: center;
                                line-height: 0;
                                align-self: flex-start;
                            }

                            &-details {
                                display: flex;
                                width: 70%;
                                padding: 0;
                                padding-left: 10px;
                                flex-direction: column;
                            }

                            &-name {
                                height: auto;
                                max-height: 65px;
                                font-weight: 100;
                            }

                            &-sku, &-status {
                                display: none;
                            }

                            &-actions {
                                margin-top: 5px;
                            }

                            .actions-primary {
                                display: none;
                            }

                            &-price {
                                &.grouped {
                                    flex: 1 50%;

                                    .special-price {
                                        display: flex;
                                        flex-direction: row;

                                        .price-container {
                                            display: flex;
                                            flex-direction: row;

                                            .price-label {
                                                font-size: 15px;
                                                margin-right: 10px;
                                            }
                                        }

                                        .old-price {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fotorama {
        &__img {
            cursor: zoom-in;
        }

        &__thumb-border {
            border: 1px solid $color-brown;
        }

        &--fullscreen {
            z-index: 1030 !important;
        }
    }

    &.page-product-grouped {
        .columns {
            .column.main {
                .product__info {
                    .product-add-form {
                        .grouped {
                            .col.item {
                                line-height: 17px;

                                .product-item-name {
                                    font-size: 13px;
                                    line-height: 17px;
                                }

                                .product {
                                    &.sku {
                                        font-size: 12px;
                                    }
                                }

                                .product-availability {
                                    font-style: italic;
                                    font-size: 12px;

                                    .type {
                                        font-weight: normal;
                                    }

                                    .product-info__stock {
                                        display: block;
                                        @include make-col(8);

                                        .value {
                                            display: inline;
                                            font-weight: $font-weight-normal;
                                        }

                                        .no-today {
                                            color: $color-red;

                                            .value {
                                                color: $color-red;
                                            }
                                        }
                                    }
                                }
                            }

                            .price-box {
                                text-align: left;
                                margin-top: 10px;

                                .price-label {
                                    display: none;
                                }

                                .special-price {
                                    span.price {
                                        font-size: 18px;
                                        font-weight: 600;
                                    }
                                }

                                .old-price {
                                    span.price {
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.page-product-grouped {
        .columns {
            .column.main {
                .product__info {
                    .product-info__price {
                        display: none;
                    }
                }
            }
        }
    }
}

.module-product {
    .item {
        &-inner {
            .product {
                &-item {
                    &-actions {
                        .actions-primary {
                            a.visible {
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

$videoButtonSize: 70px;

.product {
    &__media {
        &__video {
            &__anchor {
                position: absolute;
                top: 5px;
                right: 20px;
                z-index: 1;

                &:hover {
                    @include opacity(0.6);
                }

                &.fa {
                    display: flex;
                    font-size: 31px;
                    position: relative;
                    left: 80%;
                }
            }

            &__anchor,
            &__player__thumb__play {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 5px;

                color: #FFF;
                background-color: #000;

                border-radius: #{$videoButtonSize/2};

                cursor: pointer;
            }

            &__player {
                margin-top: 70px;
                padding-top: 56%; //16:9 aspect ratio
                position: relative;

                &__container {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                }

                &__thumb {
                    position: relative;

                    &__play {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 5;

                        margin-left: -#{$videoButtonSize/2};
                        margin-top: -#{$videoButtonSize/2};

                        overflow: hidden;

                        &.fa.fa-play{
                            display: flex;
                            font-size: 31px;
                        }

                    }

                    img {
                        width: 100%;
                        top: -17%;
                        position: absolute;
                    }

                    &:hover {
                        .product__media__video__player__thumb__play {
                            @include opacity(0.6);
                        }
                    }
                }

                iframe,
                .product__media__video__player__thumb {
                    border: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .catalog-product-view {
        .columns {
            .column.main {
                .product-important {
                    .product {
                        &__info, &__media, &__details {
                            .page-title-wrapper {
                                width: 100%;

                                .page-title {
                                    margin-top: 15px;
                                }
                            }
                        }

                        &-info {
                            &__previousnext {
                                .btn {
                                    width: 125px !important;
                                }
                            }
                        }

                        &__media {
                            @include flex-order(-1);
                        }

                        &__aside {
                            @include make-col(12);
                            order: 20;
                        }
                    }
                }

                .module-related {
                    @include make-col(12);

                    .autorelated-filters {
                        li {
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
    }
    .product__media__video {
        &__anchor,
        &__player__thumb__play {
            width: 50px;
            height: 50px;
            font-size: 35px;
        }
    }
}

@include media-breakpoint-up(md) {
    .catalog-product-view {
        .columns {
            .column.main {
                .product-important {
                    .product {
                        &__info, &__media, &__details {
                            @include make-col(6);
                        }

                        &__info {
                            position: sticky;
                            top: 130px;
                        }
                    }
                }
            }
        }
    }
    .product__media__video {
        &__anchor,
        &__player__thumb__play {
            width: 60px;
            height: 60px;
            font-size: 40px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .catalog-product-view {
        .columns {
            .column.main {
                .product-important {
                    .product {
                        &__details {
                            @include make-col(8);
                        }

                        &__media {
                            @include make-col(8);
                        }

                        &__info {
                            @include make-col(4);
                        }
                    }
                }

                .module-related {
                    .autorelated-filters {
                        li {
                            padding-right: 35px;
                        }
                    }
                }
            }
        }
    }
    .product__media__video {
        &__anchor,
        &__player__thumb__play {
            width: $videoButtonSize;
            height: $videoButtonSize;
            font-size: 45px;
        }
    }

    .product {
        &__media {
            &__video {
                &__anchor {
                    right: 50px;
                    font-size: 45px;
                }

                &__player {
                    margin-right: 80px;
                    padding-top: calc(56% - 45px); //16:9 aspect ratio
                }
            }
        }
    }
}

