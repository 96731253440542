.products-grid {
    .item-inner {
        .product-item-details {
            align-items: center;
            border: 1px solid $color-best-new-light-green;
            border-radius: 0 0 12px 12px;
            padding-bottom: 0;
            margin-bottom: 40px;
            position: initial;

            .product-item-actions {
                margin: 10px 18px;
                border-top: 1px solid $color-best-new-light-green;
                padding-top: 5px;
                width: calc(100% - 36px);

                .product-item-price {
                    max-width: 58%;

                    .price-final_price {
                        padding: 0;
                    }

                    .product__label {
                        position: absolute;
                        top: 10px;
                        z-index: 3;
                        right: 19%;
                    }

                    .old-price {
                        .price {
                            text-decoration-color: black;
                        }
                    }
                }

                .actions-primary {
                    .tocart-container {
                        height: 44px;

                        .visible {
                            display: flex;
                            width: 100%;
                            height: 44px;

                            .action.tocart {
                                @extend %button-gradient;
                                bottom: 30px;
                                max-width: fit-content;
                                margin: auto;
                            }

                            .visible {
                                position: relative;

                                .control {
                                    padding: 0;
                                    position: absolute;
                                    bottom: 43px;
                                    right: 0;
                                    width: fit-content;

                                    .qty-button {
                                        font-size: 20px;
                                        border: none;
                                    }

                                    .input-text {
                                        font-size: 17px;
                                        padding: 0;
                                        width: 33px;
                                        height: fit-content;
                                        border: 1px solid $color-best-new-light-green;

                                    }
                                }
                            }
                        }
                    }

                    .product-link-autorelated {
                        @extend %background-color-primary;
                        position: relative;
                        bottom: 10px;
                        justify-content: center;

                        .autorelated {
                            width: 50%;
                            margin: 3px;
                        }
                    }
                }
            }

            .product-item-name {
                padding: 0 15px;
                text-align: center;
            }

            .product-availability {
                height: 46px;
                text-align: center;
            }

            .product-item-advanced {
                width: 85%;
                text-align: center;

                .product-availability {
                    width: 100%;
                }

                .product-item-sku {
                    color: $color-best-new-dark-grey;
                    text-align: center;

                    .sku {
                        font-weight: bold;
                    }
                }
            }

            .catalog-widget {
                width: 100%;

                .product-link {
                    width: fit-content;
                    margin: 10px auto 15px;
                }
            }
        }
    }
}

.autorelated.not-logged .products-grid .item-inner .product-item-details .product-item-actions {
    border: none;
}

#df-results__wrapper__dffullscreen .df-card .btn {
    @extend %button-gradient;
}

@include media-breakpoint-down(lg) {
    @include product-label(44px);
}

@include media-breakpoint-down(md) {
    @include product-label(63px);
}

@include media-breakpoint-down(sm) {
    @include product-label(26px);

    .products-grid .item-inner .product-item-details .product-item-actions {
        .product-item-price {
            height: 48px;

            .special-price {
                width: 100%;
            }

            .old-price {
                width: 100%;
            }
        }

        .actions-primary .tocart-container .visible .visible .control {
            bottom: 52px;
        }
    }
}

@media (max-width: 440px) {
    .products-grid .item-inner {
        .product-item-details .product-item-actions {
            margin: 10px;
            width: calc(100% - 20px);
        }
    }
}
