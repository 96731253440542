.navigation {
    text-transform: none;

    .level0 {
        &.active, &.has-active {
            > .level-top {
                @include color-primary;
            }
        }

        > .level-top {
            font-weight: normal;

            &:hover {
                @include color-primary;
            }
        }

        .submenu {
            li {
                &.parent-category {
                    color: $color-tertiary;
                }
            }

            a {
                &:hover, &.ui-state-focus {
                    @include color-primary;
                }
            }

            .active {
                > a {
                    @include color-primary;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .nav-sections {
        &-item {
            &-title {
                &.active a {
                    @include color-primary;
                }
            }
        }

        .header__settings {
            .settings__content {
                .module {
                    &__links {
                        li {
                            &:first-child:before {
                                @include color-primary;
                            }
                        }
                    }
                }

                .account-buttons {
                    .btn--primary {
                        @include background-color-and-border($color-best-new-light-green);
                    }
                }

                .language__options {
                    .item {
                        a {
                            color: $color-best-new-light-green;
                        }
                    }
                }
            }
        }
    }

    .navigation {
        li {
            &.level0 {
                > .level-top {
                    &:hover {
                        color: $color-best-new-light-green;
                    }
                }

                .submenu {
                    li {
                        a {
                            text-transform: none;

                            &:hover {
                                color: $color-tertiary;
                            }
                        }
                    }
                }

                &.active, &.has-active {
                    > .level-top {
                        color: $color-best-new-light-green;
                    }
                }

                .submenu {
                    a {
                        &.ui-state-focus {
                            color: $color-best-new-light-green;
                        }

                        &:hover {
                            color: $color-best-new-light-green;
                        }
                    }
                }
            }
        }
    }

    .navigation-secondary {
        li {
            a {
                &:hover {
                    color: $color-tertiary;
                }
            }
        }
    }
}
