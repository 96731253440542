.checkout-index-index {
    .page-main {
        .main .checkout-container {
            background: transparent;

            .am-opc-wrapper {
                max-width: 100%;

                .opc {
                    .checkout-block {
                        padding: 24px 18px;

                        .checkout-shipping-address {
                            .amcheckout-content {

                                .shipping-address-items {
                                    .shipping-address-item {
                                        width: 100%;
                                        margin: 0;

                                        .action-select-shipping-item {
                                            font-size: 12px;
                                        }

                                        .edit-address-link {
                                            padding: 0 18px;
                                            margin-top: 1rem;
                                        }

                                        .temporary_address {
                                            @include temporaryAddress;
                                        }

                                        button {
                                            margin-right: 20px;
                                        }
                                    }

                                    .selected-item {
                                        border: 2px solid $color-primary;
                                    }
                                }

                                .amcheckout-button {
                                    span:before {
                                        content: '';
                                    }
                                }
                            }
                        }

                        .checkout-payment-method {
                            list-style-type: none;

                            .payment-method-content {
                                padding-right: 0;

                                .checkout-agreement {
                                    label {
                                        display: flex;
                                    }
                                }
                            }

                            .btn {
                                font-size: 12px;
                                line-height: normal;
                            }
                        }

                        .dummymessage {
                            background-color: $color-grey-light;
                            color: $color-green-msg;

                            .remainingForFree {
                                font-weight: bold;
                            }
                        }

                        .mark {
                            background: transparent;
                            font-weight: normal;

                            &:after {
                                content: none;
                            }
                        }

                        .grand.totals {
                            &.incl, &.excl {
                                .mark {
                                    font-weight: bold;
                                }
                            }
                        }

                        .step-title {
                            word-break: break-word;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .checkout-index-index .page-main .main .checkout-container .am-opc-wrapper .opc .checkout-payment-method .payment-methods .payment-method .payment-method-content .actions-toolbar .primary .btn {
        padding: 15px 30px;
    }
}


