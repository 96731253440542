.toggle-accordion {
  .toggle-item {
    .toggle-title {
      font-size: 16px;
      width:100%;
      display:block;
      border-bottom: 1px solid $color-grey-alt;
      position:relative;
      padding-bottom:5px;
      margin: 20px 0;
      cursor: pointer;
      &:after {
        content:"\f078";
        font-family: $font-icons;
        position:absolute;
        right:10px;
        bottom:5px;
      }
      &.active {
        &:after {
          content:"\f077";
        }
      }
    }
    .toggle-content {
      display: none;
      width:100%;
      overflow: hidden;
      -webkit-transition: height 0.5s linear;
      -moz-transition: height 0.5s linear;
      -ms-transition: height 0.5s linear;
      -o-transition: height 0.5s linear;
      transition: height 0.5s linear;
    }
  }
}