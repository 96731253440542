.module-newsletter {
    &.newsletter {
        .module__form {
            .form-inline {
                align-items: flex-start;

                .field {
                    .control {
                        height: auto;
                        min-height: 50px;
                        text-align: left;

                        label, div.mage-error {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}

#newsletter-validate-detail {
    margin-bottom: 10px;

    .field-recaptcha {
        display: none;
    }

    .form-inline {
        margin: auto auto;
        width: 440px;

        .privacy-policy {
            margin: auto;
        }

        .newsletter {
            width: 330px;

            .form-control {
                width: 330px;
            }
        }

        button {
            height: 35px;
            border-radius: 0;
            border: 1px solid white;
        }
    }
}


@include media-breakpoint-down(sm) {
    #newsletter-validate-detail {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;

        .form-inline {
            width: 100%;

            .newsletter {
                width: calc(100% - 118px);

                .control {
                    width: 100%;

                    .custom-checkbox {
                        width: fit-content;

                    }

                    .form-control {
                        width: 100%;
                    }
                }
            }

            .actions {
                margin-left: auto;

                button {
                    width: 115px;
                }
            }
        }
    }
}

#newsletter-title {
    margin: 0 0 20px;
    font-size: 22px;
}
