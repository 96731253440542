h1, .h1 {
  font-size: 36px;
  line-height: 30px;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  position: relative;
}

h2, .h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: $font-weight-semibold;
  //text-transform: uppercase;
  position: relative;
  margin-bottom: 50px;
  text-transform: uppercase;

  &.h2--medium {
    text-align: center;
    margin-top: 50px;
  }
}

h3, .h3 {
  font-size: 21px;
  line-height: 30px;
  font-weight: $font-weight-semibold;
  position: relative;
  margin-bottom: 5px;
}

h5, .h5 {
  font-size: 18px;
  font-weight: $font-weight-semibold;
}
