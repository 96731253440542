.module-new {
  @include make-container();
  margin-top: 15px;
  margin-bottom: 15px;
  .module {
    &-inner {
      @include make-row();
      .products-grid {
        .product-items {
          li.item {
            @extend .module-product;
          }
        }
      }
    }
    &__content {
      @include make-col-ready();
      //@include make-col(8);
      width: 100%;
      max-width: 1140px;
      margin: 0 auto;
    }
    &__title {
      position: relative;
      display: block;
      margin-bottom: 60px;
      text-align: center;
      padding-top: 34px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .module-new {
    .module {
      &__content {
        @include make-col(12);
      }
    }
  }
}