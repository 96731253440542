.toolbar {
  .pages {
    > .label {
      @extend .sr-only;
    }
    .items {
      .item {
        display: inline;
        font-size: 16px;
        padding: 0 10px;
        color: $color-dark-light;
        &.current {
          border-bottom: 3px solid;
          color: $color-dark;
        }
        .label {
          @extend .sr-only;
        }
        .action {
          margin-top: -2px;
          &.next {
            span:after {
              content: "\f178";
              font-family: $font-icons;
              margin-left: 10px;
              font-size: 16px;
            }
          }
          &.previous {
            span:before {
              content: "\f177";
              font-family: $font-icons;
              margin-right: 10px;
              font-size: 16px;
            }
          }
          span {
            margin: 0 5px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: $font-weight-regular;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .toolbar {
    .pages {
      .items {
        float: left;
        width: 100%;
        display: block;
        position: relative;
        min-width: 220px;
        .item {
          &.pages-item-previous {
            position: absolute;
            top: 35px;
            left: 0;
            padding: 0;
          }
          &.pages-item-next {
            position: absolute;
            top: 35px;
            right: 0;
            padding: 0;
          }
        }
      }
    }
  }
}