.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;
  opacity: 0.5;
  .loader {
    > img {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
      width: auto;
    }
    > p  {
      display: none;
    }
  }
}
