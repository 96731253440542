.module-back-to-top {
  .module {
    &__action {
      @extend .fa;
      @extend .fa-angle-up;
      @include transition(all, 0.3);
      background: $color-dark-light;
      bottom: 35px;
      opacity: 0;
      height: 40px;
      position: fixed;
      right: 12px;
      width: 40px;
      line-height: 32px;
      z-index: 9999;
      cursor: pointer;
      border: 2px solid $color-white;
      border-radius: 100%;
      text-align: center;
      font-size: 30px;
      color: $color-white;
      &:hover {
        border-color: $color-brown;
        background: $color-brown;
      }
      &:before {
        color: $color-white;
      }
      span {
        display: none;
      }
      &--visible {
        opacity: 1;
        @include transition(all, 0.3);
      }
    }
  }
}
