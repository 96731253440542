.rounded-form, .square-form {
    input {
        height: 35px;
        border: 1.5px solid $button-primary-border;

        &::placeholder {
            color: $color-primary;
        }
    }

    .custom-checkbox {
        margin-top: 1rem;

        .custom-control-indicator {
            border-color: $button-primary-border;
        }

        .custom-control-description {
            color: $color-primary;

            a {
                color: $color-primary;
            }
        }
    }

    .form-group {
        margin: 0;
    }

    select {
        height: 35px;
        border: 1.5px solid $button-primary-border;
        color: $color-primary;

    }

    .form-title {
        text-align: center;

        h2 {
            border: none;
            margin-bottom: 0;
        }

        h4 {
            color: $color-primary
        }
    }

    .submit-container {
        margin-top: 20px;
    }
}

.rounded-form {
    input, select, button {
        border-radius: 2rem;
    }
}
