// Hacks ------------

html:lang(it) .page-header .header__top--right--user-actions,
html:lang(pt) .page-header .header__top--right--user-actions {
    width: 285px;
}

//----------------------

#closeMobileMenu {
    @include closeButton;
    color: $color-grey-dark;
    border-color: $color-grey-dark;
    display: block;
    top: auto;
}

body.page-layout-1column .page-main .columns .column.main, body.checkout-index-index .page-main .columns .column.main {
    margin-right: 0;
    margin-left: 0;

    .table-xl {
        overflow-x: auto;
    }

    p {
        overflow-x: auto;
    }
}

.noscroll {
    overflow: hidden;
}

.page-header {
    background-repeat: repeat-x;
    background-attachment: fixed;
    background-size: contain;
    z-index: $zindex-header;
    color: $color-white;
    @include transition(all, 0.3s);
    margin: auto;
    position: fixed;
    width: 100%;
    padding: 0;
    background-color: $color-white;
    top: 0;

    .header {
        &__top {
            @include make-container();
            border-bottom: 1px solid $color-white;
            padding: 0;
            background-color: $background-color-header;

            .welcome-container {
                display: inline-block;
                vertical-align: middle;

                .welcome-text {
                    color: $welcome-text;
                    font-size: 12px;
                    margin-right: 10px;
                    text-overflow: ellipsis;
                    max-width: 120px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            .account-buttons {
                display: inline-block;
            }

            &-inner {
                @include make-row();
                margin-left: 0;
                margin-right: 0;
            }

            .links {
                @extend .list-inline;

                li {
                    @extend .list-inline-item;
                }
            }

            .container {
                max-width: 1500px;
                padding: 15px 15px 0 15px;

                .header__top-wrapper .header__top-inner {
                    padding-bottom: 15px;

                    .logoContainer {
                        order: 2;
                    }

                    .header__top--left {
                        order: 1;
                    }

                    .header__top--right {
                        order: 3;
                    }

                    .toggle-nav {
                        order: 1;
                        color: $button-primary-background;
                        padding: 0;
                        height: fit-content;
                        margin: auto 0;
                        display: none;

                        .nav-toggle {
                            width: fit-content;
                            margin: auto;
                            padding: 0;

                            &:before {
                                content: '';
                            }

                            .icon-menu:before {
                                content: "\e905";
                                color: $color-primary;
                                font-size: 26px;
                            }
                        }
                    }
                }
            }
        }

        &__content {
            padding-top: 15px;
            padding-bottom: 15px;
            top: -50px;
            z-index: $zindex-fixed;
            @include transition(all, 0.3s);
            border-top: 1.5px solid $button-primary-border;
            border-bottom: none;

            &-inner {
                @extend .row;
                @extend .align-items-center;
                position: relative;
                z-index: 2;

                .block-search .block-content .minisearch__trigger {
                    display: none;

                    &::before {
                        font-family: $font-icons;
                    }
                }
            }

            .container {
                .header__content-inner {
                    .header__secondary {
                        ul {
                            display: block;
                        }
                    }
                }
            }
        }

        &__secondary {
            @include make-col-ready();
            @include make-col(9);
            text-align: left;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            font-size: 18px;

            .navigation-secondary {
                align-items: start;
                display: flex;
            }

            .list-inline {
                &-item {
                    margin: 0 10px;
                    font-weight: normal;
                    text-transform: initial;

                    &.navigation--toggle {
                        color: $color-menu;
                        display: inherit;

                        &::before {
                            font-family: $font-icons;
                            margin-right: 5px;
                            cursor: pointer;
                            display: none;
                            color: $color-primary;
                        }

                        &::after {
                            display: inline-block;
                            content: '\f078';
                            color: $color-primary;
                            font-family: FontAwesome, serif;
                            margin-right: 5px;
                            cursor: pointer;
                        }

                        &.active {
                            color: $color-brown;
                        }
                    }

                    a , span{
                        padding: 0 5px;
                        color: $color-text-header-secondary;

                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
            }
        }

        &__right {
            @include make-col-ready();
            @include make-col(3);
            text-align: right;

            .ayudacontacto {
                display: none;
            }

            .header__minicart {
                margin-right: 0;
            }

            .header__settings {
                display: none;
            }

            .ayudacontacto .flex-content .icono::after {
                color: $color-primary;
            }
        }

        &__search {
            text-align: right;
        }

        &__settings {
            position: relative;
            display: inline-flex;
            margin-left: 5px;

            .module-inner .module__title, .module__language {
                display: none;
            }

            .settings__toggle {
                color: $color-primary;
            }
        }

        &__navigation {
            position: fixed;
            left: 0;
            z-index: $zindex-fixed;
            background: rgba(255, 255, 255, 1);
            width: 100%;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            -ms-transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            transform-origin: top;
            @include transition(all, 0.8s);
            display: none;
            height: 100vh;

            &--active {
                opacity: 1;
                visibility: visible;
                -ms-transform: scaleY(1);
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                height: 100%;
                overflow: auto;
            }

            .header__navigation-inner {
                height: 100%;
                overflow: auto;

                .closeTopMenu {
                    @include closeButton;

                }
            }

            .sections.nav-sections {
                max-width: 500px;
                height: 100%;
                background-color: $menuBackgroundColor;

                .section-items.nav-sections-items {
                    height: 100%;
                }

                .nav-sections-item-content {
                    height: auto;
                    background-color: $menuBackgroundColor;
                }

                .navigation {

                    .navigation__title {
                        margin-bottom: 25px;

                        span {
                            color: $color-menu;
                            font-size: 20px;
                        }
                    }


                    & .level0 {
                        & > .level-top {
                            font-size: 15px;
                            margin-bottom: 15px;
                            display: inline-block;
                        }

                        .submenu {
                            padding-left: 12%;
                        }

                        .submenu li {
                            height: auto;
                            width: auto;
                            margin-right: 25px;
                        }

                        .submenu li.parent-category {
                            font-size: 18px;
                            margin-bottom: 10px;
                        }

                        .submenu a {
                            font-size: 14px;
                            margin-bottom: 6px;
                        }
                    }
                }
            }
        }

        &__heading {
            padding-top: 120px;
            padding-bottom: 10px;

            .page-title-wrapper {
                padding-top: 15px;

                .page-title {
                    color: $color-menu;
                    padding: 10px;
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .page-header {
        .header__secondary {
            flex: 0 0 80%;
            max-width: 100%;
        }

        .header__content {
            .container {
                max-width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .page-header {

        .header {
            &__top {
                display: block;
            }

            &__secondary {
                @include make-col(9);
            }

            &__right {
                @include make-col(3);
            }

            &__content-inner {
                display: -webkit-inline-flex;
                float: right;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .page-header {
        -webkit-background-size: cover;
        background-size: cover;

        .menuContainer {
            max-width: inherit;
        }

        .header {
            &__top {
                &-wrapper {
                    width: 100%;
                }

                .container {
                    max-width: 100%;

                    .header__top-wrapper .header__top-inner {
                        .logoContainer {
                            order: 2;
                        }

                        .header__top--left {
                            order: 3;
                        }

                        .toggle-nav {
                            width: 48px;
                            display: block;
                        }
                    }
                }
            }

            &__content {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                display: none;
                min-height: 66px;

                &-inner {
                    justify-content: space-between;
                    display: -webkit-inline-flex;
                    float: right;

                    .header__search {

                        .block-content {
                            width: 100%;

                            .minisearch__trigger {
                                @include topSearchBig;
                                display: block;

                                &::before {
                                    @include iconoLupa;
                                }
                            }
                        }
                    }
                }
            }

            &__secondary {
                display: none;
            }

            &__right {
                @include make-col(12);
                display: flex;
                justify-content: flex-end;
                position: static;

                .ayudacontacto {
                    margin-right: 13px;
                    display: inline-block;

                    .flex-content {

                        .icono::after {
                            @include iconoContact;
                            color: $color-white;
                        }

                        .texto {
                            display: none;
                        }
                    }
                }

                .header__minicart {
                    margin-left: 15px;
                    top: 3px;
                }

                .header__settings {
                    display: inline-block;

                    .settings__toggle {
                        &::before {
                            font-size: 23px;
                        }
                    }
                }
            }

            &__search {
                padding-right: 0;
            }

            &__minicart {
                top: 0;
            }

            &__heading {
                padding-top: 76px;
            }
        }

        &.fixed, &.page-header {
            .header__settings {
                .settings__toggle {
                    &::before {
                        font-size: 23px;
                    }
                }
            }

            .header__content {
                max-width: initial;
                float: none;
            }

            .header__right {
                @include make-col(12);

                .ayudacontacto {
                    margin-top: 3px;
                }

                .block-search {
                    position: absolute;
                    margin-right: initial;
                    left: -200px;
                    width: 100%;

                    .block-content {
                        .minisearch__trigger {
                            @include topSearchBig;

                            &::before {
                                @include iconoLupa;
                            }
                        }
                    }
                }
            }
        }

        &.fixed .header__right .block-search .block-content .minisearch__trigger::before {
            color: $color-dark;
        }
    }

    .nav-sections {
        width: 100%;
    }

    html.nav-open {
        .page-wrapper {
            left: 70%;
        }

        .nav-sections {
            width: 70%;
        }

        .navigation-secondary li, .navigation li.level0 > .level-top, .navigation .level0 .submenu li {
            font-size: 15px;
        }
    }

    .header__settings .module-inner {
        min-height: 30px;
    }
}

@media (max-width: 650px) {
    .page-header {
        .header__content-inner {
            display: -webkit-inline-flex;
            float: right;
        }

        .header__content {
            min-height: 66px;
        }

        &.fixed, &.header {
            .header__right {

                .block-search {
                    left: -200px;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 592px) {
    .page-header {
        .header {
            &__content-inner {
                display: -webkit-inline-flex;
                float: right;
            }

            &__logo {
                .logo {
                    max-width: 30%;
                }
            }

            &__content {
                min-height: 66px;
            }

            &__top {
                .container {
                    .header__top-wrapper .header__top-inner {
                        .logoContainer {
                            order: 3;
                        }

                        .toggle-nav {
                            order: 1;
                        }

                        .header__top--right {
                            order: 4;
                        }

                        .header__top--left {
                            order: 2;
                        }
                    }
                }
            }
        }
    }

    .header {
        &.fixed, &.header {
            .header__right {

                .block-search {
                    position: static;
                    width: auto;
                    margin-right: 20px;

                    .block-content {
                        width: auto;

                        .minisearch__trigger {
                            background-color: transparent;
                            border: none;
                        }
                    }
                }
            }
        }

        &.header {
            .header__right {
                .block-search {
                    .block-content {
                        .minisearch__trigger {

                            &::before {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .page-header {
        margin-bottom: 0;

        .header {
            &__content {
                min-height: 66px;

                &-inner {
                    display: -webkit-inline-flex;
                    float: right;

                    .header__right {
                        @include make-col(12);
                    }
                }
            }

            &__heading {
                .page {
                    &-title {
                        .base {
                            line-height: 38px;
                        }
                    }
                }
            }

            &__logo {
                @include make-col(9);

                .logo {
                    padding: 0;
                    line-height: 30px;
                }
            }

            &__right {
                @include make-col(3);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .page-header {
        margin-bottom: 0;

        .header {
            &__content {
                width: initial;
            }

            &__content-inner {
                justify-content: space-between;
                display: -webkit-flex;
                float: none;
            }

            &__navigation {
                display: block;
            }
        }
    }
}

