.custom-login-popup {
    color: $color-dark-alt;

    .modal-content {
        display: none;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.7);
        border: none;
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: auto;

        .content-popup {
            background: white;
            width: 500px;
            margin: 100px auto 0;
            padding: 20px 20px 10px;
            border: 1px solid $color-primary;
            position: relative;
            height: 620px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;

            .close-button {
                position: absolute;
                right: 5px;
                z-index: 1;
                top: 5px;

                &:before {
                    font-size: 32px;
                    color: #808080;
                    content: '\e616';
                    font-family: 'Blank-Theme-Icons';
                }
            }

            .nav-popup {
                .nav {
                    padding: 0 10px;

                    .nav-item {
                        padding: 0;

                        .nav-link {
                            color: $color-primary;
                            font-size: 1.5em;
                            border-radius: 0;
                            border-bottom: 1px solid $color-primary;
                            padding: 10px 0;

                            &.active {
                                color: $color-primary;
                                background: transparent;
                                font-weight: bold;
                                border-bottom-width: 3px;
                            }
                        }
                    }
                }
            }

            form {
                padding-top: 20px;
                margin-bottom: 30px;

                .form-group {
                    position: relative;
                    margin-bottom: 30px;

                    label {
                        position: absolute;
                        top: -13px;
                        left: 10px;
                        background-color: $color-white;
                        padding: 0 5px;
                        color: $color-black;
                    }

                    .form-control {
                        padding: 0.6rem 0.75rem;
                    }
                }

                button {
                    margin: 20px auto 0;
                }
            }

            .footer-popup {
                border-top: 1px solid $color-primary;
                padding: 10px 10px 0;
            }
        }
    }
}

#togglePassword {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
}

#forgot-password-popup, #forgot-email-popup {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    h2 {
        color: $color-primary;
        border-color: $color-primary;
        margin: 0;
        text-align: center;
        border-bottom: 1px solid;
        padding: 0.5rem 1rem;
    }

    .form-body {
        padding: 0 30px;

        form {
            text-align: center;
            //margin-bottom: 60px;

            .recaptcha-container {
                height: 36px;
                width: 150px;
                margin: 30px 0 0 auto;

                .field-recaptcha {
                    transform: scale(0.6);
                    transform-origin: 0 0;
                    width: fit-content;

                    div {
                        width: fit-content;
                    }
                }
            }
        }

        .back-login-popup a {
            color: $color-primary;

            &:hover {
                color: $color-secondary;
            }
        }
    }
}

#forgot-email-popup .form-body p {
    margin-bottom: 30px;
}

#access-tab-content {
    padding: 30px;
}

#login-form {
    ul {
        padding-left: 30px;

        li {
            list-style: inherit;

            &:hover {
                color: $color-primary;
            }
        }
    }

    .help-content {
        margin-top: 60px;
    }
}

#register-form {
    .form-check {
        margin: 0 30px 10px;
    }

    form {
        margin: 0;
    }

    .register-button {
        margin-top: 20px;

        button {
            margin: 0 auto;
        }
    }

    .info-link {
        display:flex;
        justify-content: center;
        padding-top: 20px;
    }
}

@media (max-width: 500px) {
    .custom-login-popup .modal-content .content-popup {
        width: 100%;

        form .form-group label {
            font-size: 13px;
        }

        .nav-popup .nav .nav-item .nav-link {
            font-size: 1.2em;
        }
    }

    #access-tab-content {
        padding: 30px 0;
    }

    #forgot-password-popup, #forgot-email-popup {
        .form-body {
            padding: 30px 0;
        }
    }
}
