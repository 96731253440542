.page.messages {
  @extend .container;

  .message {
    a {
      text-decoration: underline;
    }
  }
}
.message {
  margin: 15px 0;
  padding: 12px 20px 12px 25px;
  display: block;
  padding-left: 45px;
  border-radius: 15px;
  position: relative;
  *:first-child:before {
    margin: -12px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    left: 0;
    top: 22px;
    width: 45px;
    position: absolute;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    @extend .fa;
  }
  &-error {
    overflow-x: auto;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    *:first-child:before {
      @extend .fa-ban;
    }
  }
  &-info, &-notice {
      color: #0c5460;
      background-color: #d1ecf1;
      border-color: #bee5eb;
    *:first-child:before {
      @extend .fa-info;
    }
  }
  &-warning {

    *:first-child:before {
      @extend .fa-exclamation;
    }
  }
  &-success {
    overflow-x: auto;

    *:first-child:before {
      @extend .fa-check;
    }
  }
  &>p {
    margin-bottom: 0;
  }
}

.floating-message-box{
    position: fixed;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
}

@include media-breakpoint-down(md) {
  .page.messages {
    width: 100%;
  }
}

@include media-breakpoint-down(sm){
    .floating-message-box{
        width: 90%;
        top: 20%;
    }
}
