.checkout-index-index {
    @extend .page-layout-1column;

    .page-header {
        max-width: 100%;

        .nav-toggle {
            display: none !important;
        }
    }

    .page-main {
        min-height: 400px;

        .checkout-container {
            @include make-col-ready();
            @include make-col(12);

            .authentication-wrapper {
                float: right;
                margin-top: -130px;
                max-width: 50%;
                position: relative;
                z-index: 1;

                .authentication-dropdown {
                    display: none;

                    &._show {
                        display: block;
                    }
                }
            }

            .opc {
                &-progress-bar {
                    margin-bottom: 40px;
                    counter-reset: i;
                    float: left;
                    width: 100%;

                    &-item {
                        @extend .h5;
                        display: inline;
                        float: left;
                        position: relative;
                        text-align: center;
                        vertical-align: top;

                        > span {
                            padding: 45px 25px 0;
                            display: inline-block;
                            word-wrap: break-word;
                            font-weight: 300;
                            color: $color-grey-light;

                            &:before, &:after {
                                background: $color-grey-light;
                                height: 38px;
                                margin-left: -19px;
                                width: 38px;
                                border-radius: 50%;
                                content: '';
                                left: 50%;
                                position: absolute;
                                top: 0;
                                font-size: 1.6rem;
                                line-height: 26px;
                            }

                            &:after {
                                background: #fff;
                                height: 26px;
                                margin-left: -13px;
                                top: 6px;
                                width: 26px;
                                counter-increment: i;
                                content: counter(i);
                                color: #333;
                                font-weight: 600;
                            }
                        }

                        &:before {
                            background: $color-grey-light;
                            top: 19px;
                            content: '';
                            height: 7px;
                            left: 0;
                            position: absolute;
                            width: 100%;
                        }

                        &._active {
                            font-weight: bold;

                            > span {
                                color: inherit;

                                &:before {
                                    background-color: $color-primary;
                                }

                                &:after {
                                    content: '\e610';
                                    font-family: 'Blank-Theme-Icons';
                                    font-size: 2rem;
                                }
                            }

                            &:before {
                                background-color: $color-primary;
                            }
                        }

                        &._complete {
                            cursor: pointer;

                            > span {
                                color: inherit;

                                &:after {
                                    content: '\e610';
                                    font-family: 'Blank-Theme-Icons';
                                }
                            }
                        }
                    }
                }

                &-estimated-wrapper {
                    display: none;
                }

                &-wrapper {
                    @include make-col-ready();
                    @include make-col(8);
                    float: left;
                    padding-left: 0 !important;

                    .opc {
                        //@include make-row();
                        .step-title {
                            @extend .h2;
                            font-weight: $font-weight-regular;
                            padding-bottom: 10px;
                            margin-bottom: 20px;
                            border-bottom: 1px solid $color-grey-light;
                        }

                        .actions-toolbar {
                            margin-top: 25px;
                        }

                        .step-content {
                            //padding-right: 20%;
                            margin-bottom: 40px;

                            .table-checkout-shipping-method {
                                width: 100%;

                                thead {
                                    display: none;
                                }

                                tr {
                                    padding-bottom: 15px;
                                    border-bottom: 1px solid $color-grey-light;
                                    cursor: pointer;

                                    & + tr {
                                        padding-top: 15px;
                                        cursor: pointer;
                                    }

                                    .select-method {

                                    }

                                    .method-type {
                                        font-weight: $font-weight-semibold;
                                        margin-bottom: 5px;
                                    }

                                    .method-description {

                                    }

                                    td {
                                        flex-grow: 0;
                                        flex-basis: auto;
                                        width: auto;
                                        border: none;
                                    }
                                }
                            }

                            .delivery-date {
                                .label {
                                    margin-top: 20px;
                                    width: 100%;

                                    &.comment {
                                        font-size: 16px;
                                        margin-top: 35px;
                                        padding-bottom: 0;
                                        border: none;
                                        margin-bottom: 10px;
                                    }
                                }

                                .control {
                                    .input-text {
                                        width: 140px;
                                        background-color: $color-white;
                                        border: solid 1px $gray-400;

                                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                            color: $gray-400;
                                        }

                                        &::-moz-placeholder { /* Firefox 19+ */
                                            color: $gray-400;
                                        }

                                        &:-ms-input-placeholder { /* IE 10+ */
                                            color: $gray-400;
                                        }

                                        &:-moz-placeholder { /* Firefox 18- */
                                            color: $gray-400;
                                        }
                                    }

                                    textarea {
                                        width: 100%;
                                        height: 80px;
                                        background-color: $color-white;
                                        border: solid 1px $gray-400;
                                        padding: 10px;

                                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                            color: $gray-400;
                                        }

                                        &::-moz-placeholder { /* Firefox 19+ */
                                            color: $gray-400;
                                        }

                                        &:-ms-input-placeholder { /* IE 10+ */
                                            color: $gray-400;
                                        }

                                        &:-moz-placeholder { /* Firefox 18- */
                                            color: $gray-400;
                                        }
                                    }

                                    .datepicker {
                                        position: relative;
                                        display: inline-block;
                                        float: left;

                                        .ui-datepicker-trigger {
                                            position: absolute;
                                            top: 5px;
                                            right: 7px;
                                            width: 13px;
                                            height: 21px;

                                            &:before {
                                                font-family: $font-icons;
                                                content: "\f073";
                                                display: block;
                                                color: $color-grey-light;
                                            }
                                        }
                                    }

                                    .datepickertext {
                                        float: left;
                                        width: auto;
                                        padding-right: 10px;
                                        line-height: 30px;
                                        font-size: 13px;
                                    }

                                    &.delivery_comment {
                                        position: relative;

                                        &:after {
                                            font-family: $font-icons;
                                            content: "\f24a";
                                            display: block;
                                            position: absolute;
                                            top: 5px;
                                            right: 7px;
                                            color: $color-grey-light;
                                        }
                                    }
                                }
                            }

                            .action-show-popup {
                                span:before {
                                    content: '+';
                                    padding-right: 5px;
                                }
                            }

                            .actions-toolbar {
                                .secondary {
                                    text-align: right;
                                }
                            }
                        }

                        .checkout-shipping-address {
                            .addresses {
                                width: 100%;
                                float: left;
                                max-height: 548px;
                                overflow-y: auto;
                                margin-bottom: 1rem;
                                @include clearfix();
                            }

                            .shipping-address-item {
                                float: left;
                                border: 2px solid transparent;
                                width: calc(33% - 20px);
                                margin: 1px;
                                transition: .3s border-color;
                                position: relative;
                                display: inline-block;
                                vertical-align: top;
                                margin: 10px;
                                padding: 20px;
                                border-bottom: none;

                                &:last-child {
                                    border-bottom: 2px solid transparent;
                                }

                                &-details {
                                    font-size: 12px;
                                    line-height: 18px;
                                    //font-size: 14px;
                                    //line-height: 30px;
                                    word-wrap: break-word;
                                    overflow-y: auto;
                                    height: 200px;
                                    padding: 20px;
                                }

                                &.selected-item {
                                    -webkit-appearance: unset;
                                    border-color: $color-primary;

                                    .action-select-shipping-item > span {
                                        opacity: 0;
                                    }

                                    &:after {
                                        background: $color-primary;
                                        color: #fff;
                                        content: '\e610';
                                        font-family: $font-icons-alt;
                                        height: 27px;
                                        width: 29px;
                                        font-size: 27px;
                                        line-height: 21px;
                                        padding-top: 2px;
                                        position: absolute;
                                        right: 0;
                                        text-align: center;
                                        top: 0;
                                    }

                                    .action-select-shipping-item {
                                        background: transparent;
                                        display: none;
                                    }
                                }

                                &.not-selected-item {
                                    border-color: $color-grey-light;
                                }

                                .action-select-shipping-item {
                                    bottom: 6px;
                                    right: 20px;
                                    font-size: 10px;
                                    color: $color-white;
                                    letter-spacing: 1px;
                                    margin-top: 1rem;
                                }
                            }

                            .form-login {
                                margin-bottom: 15px;
                                padding-bottom: 15px;
                                border-bottom: 1px solid;

                                .field {
                                    @include clearfix();
                                }
                            }
                        }

                        .checkout-shipping-address, .checkout-shipping-method {
                            //@include make-col-ready();
                            //@include make-col(12);
                        }

                        .checkout-payment-method {
                            margin-top: 0;

                            .custom-control {
                                padding-left: 0;
                            }

                            .payments {
                                .legend {
                                    @include sr-only();
                                }
                            }

                            .payment-methods {
                                .payment-method {
                                    .payment-method-title {
                                        padding-bottom: 10px;

                                        .custom-control-description {
                                            font-size: inherit;
                                        }
                                    }

                                    .payment-method-content {
                                        border-top: solid 1px $color-grey-light;
                                        padding-top: 10px;
                                        padding-bottom: 20px;
                                        padding-left: 20px;
                                        display: none;
                                        width: 100%;

                                        .payment-method-billing-address {
                                            display: none !important;
                                        }

                                        .actions-toolbar {
                                            .primary {
                                                text-align: left;
                                                //text-align: center;
                                                .btn {
                                                    padding: 15px 60px;
                                                }
                                            }
                                        }
                                    }

                                    + .payment-method {
                                        //margin-top: 25px;
                                        clear: both;
                                        width: 100%;
                                        border-top: solid 1px $color-grey-light;
                                        padding-top: 20px;
                                    }

                                    &._active {
                                        .payment-method-content {
                                            display: block;
                                            padding-left: 0;

                                            .actions-toolbar {
                                                margin-top: 25px;
                                            }

                                            .checkout-agreements-block {
                                                .checkout-agreement {
                                                    .custom-control {
                                                        padding-left: 0;

                                                        .custom-control-indicator {
                                                            //text-decoration: underline;
                                                            display: block;
                                                            float: left;
                                                        }

                                                        .custom-control-description {
                                                            display: block;
                                                            float: left;
                                                        }
                                                    }
                                                }
                                            }

                                            .payment-icon-content {
                                                float: left;
                                                width: 100%;
                                                display: block;
                                                margin-bottom: 15px;
                                            }
                                        }
                                    }
                                }
                            }

                            .discount-code {
                                margin-top: 30px;
                                padding-top: 15px;
                                border-top: 1px solid;

                                .payment-option-title {
                                    @extend .h6;
                                    cursor: pointer;

                                    .action {
                                        position: relative;

                                        &:before {
                                            @extend .fa;
                                            @extend .fa-chevron-down;
                                            position: absolute;
                                            right: -20px;
                                        }
                                    }
                                }

                                .payment-option-content {
                                    margin-top: 15px;
                                    @include make-col(7);

                                    .actions-toolbar {
                                        margin-left: 1rem;
                                    }

                                    #discount-code {
                                        height: 38px;
                                    }
                                }

                                &._active {
                                    .payment-option-title {
                                        .action:before {
                                            @extend .fa-chevron-up;
                                        }
                                    }
                                }
                            }

                            .giftcardaccount {
                                display: none;
                            }

                            .shipping-link {
                                margin-top: 15px;
                                float: left;
                                color: $color-brown;
                            }
                        }

                        li {
                            @include clearfix();

                            + li {
                                margin-top: 25px;
                            }
                        }
                    }
                }

                &-sidebar {
                    @include make-col-ready();
                    @include make-col(4);
                    float: left;
                    padding-right: 0px !important;
                    outline: none;

                    .modal {
                        &-header {
                            display: none;
                        }

                        &-content {
                            -webkit-border-radius: 0;
                            -moz-border-radius: 0;
                            border-radius: 0;
                            background-color: $color-white;
                            border: 1px solid $color-grey-light;
                            padding: 20px 15px;

                            .opc-block-summary {
                                > .title {
                                    @extend .h3;
                                    display: block;
                                    margin-bottom: 20px;
                                }

                                .table-totals {
                                    .table-caption {
                                        display: none;
                                    }

                                    border-top: 1px solid $color-grey-light;
                                    border-bottom: 1px solid $color-grey-light;

                                    .mark {
                                        background: none;
                                        white-space: normal;
                                        font-weight: $font-weight-normal;
                                    }

                                    .amount {
                                        border-top: none;
                                        text-align: right;
                                    }

                                    .grand.totals {
                                        border-top: 1px solid $color-grey-light;

                                        .mark, .amount {
                                            padding-top: 20px;
                                        }

                                        .dummymessage {
                                            padding: 10px;
                                            background-color: $color-grey-light;
                                            color: $color-green-msg;

                                            ul {
                                                li {
                                                    list-style: initial;
                                                    margin-left: 15px;
                                                }
                                            }

                                            .minicart-data {
                                                font-size: 0.9em;
                                            }

                                            .remainingForFree {
                                                font-weight: bold;
                                            }
                                        }
                                    }

                                    .mark {
                                        border-bottom: none;
                                    }
                                }

                                .block {
                                    margin-top: 20px;

                                    &.items-in-cart {
                                        .title {
                                            padding-bottom: 10px;
                                            border-bottom: 1px solid;
                                            //cursor: pointer;
                                            outline: none;

                                            strong {
                                                display: block;
                                                position: relative;

                                                &:before {
                                                    @extend .fa;
                                                    @extend .fa-chevron-down;
                                                    right: 0;
                                                    top: 5px;
                                                    position: absolute;
                                                    display: none;
                                                }
                                            }
                                        }

                                        .content {
                                            margin-top: 20px;

                                            .minicart-items {
                                                .minicart-items-wrapper {
                                                    margin: 0 -15px 0 0;
                                                    max-height: 370px;
                                                    padding: 0 15px 0 0;
                                                    border: 0;
                                                    overflow-x: auto;
                                                }

                                                .product-item {
                                                    padding: 10px 0;

                                                    .product {
                                                        position: relative;
                                                        @include clearfix();

                                                        .product-image-container {
                                                            float: left;
                                                            display: inline-block;
                                                        }

                                                        .product-item-details {
                                                            padding-left: 90px;

                                                            .product-item-inner {
                                                                display: table;
                                                                margin: 0 0 10px;
                                                                width: 100%;

                                                                .product-item-name-block {
                                                                    display: table-cell;
                                                                    padding-right: 5px;
                                                                    text-align: left;
                                                                    line-height: 14px;

                                                                    strong {
                                                                        font-weight: $font-weight-normal;
                                                                        font-size: 12px;
                                                                    }

                                                                    .product-item-sku {
                                                                        font-size: 10px;
                                                                        margin-top: 5px;
                                                                    }

                                                                    .details-qty {
                                                                        font-size: 10px;

                                                                        .value {
                                                                            font-weight: 600;
                                                                            margin-left: 5px;
                                                                        }
                                                                    }
                                                                }

                                                                .subtotal {
                                                                    display: table-cell;
                                                                    text-align: right;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.active {
                                            padding-bottom: 20px;
                                            margin-bottom: 20px;
                                            border-bottom: 1px solid;
                                        }

                                        .actions-toolbar {
                                            .secondary {
                                                margin-top: 10px;

                                                .action.viewcart {
                                                    color: $color-primary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .opc-block-shipping-information {
                                margin-top: 20px;

                                .shipping-information {
                                    .ship-to, .ship-via {
                                        .shipping-information-title {
                                            @extend .h5;

                                            .action {
                                                padding-top: 0;
                                            }
                                        }
                                    }

                                    .ship-via {
                                        margin-top: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .shipping-date-type-selector {
        display: flex;
        margin-bottom: 20px;

        &__option {
            .label {
                margin-left: 20px;
            }
        }
    }

    .delivery-data {
        &__selector,
        &__shipping-message {
            display: none;
        }
    }

    .js-dont-send-when-available {
        .delivery-data__selector {
            display: flex;
            align-items: center;
        }
    }

    .js-send-when-available {
        .delivery-data__shipping-message {
            display: block;
        }
    }

    .opc-block-summary {

        .totals-tax-summary .totals-tax-details {
            padding-left: 33px;

            li.mark {
                margin-top: 0 !important;
            }
        }

        .step-content.amcheckout-content {
            .amcheckout-wrapper {
                table {
                    th, td {
                        border-bottom: none !important;
                        font-weight: normal;
                        border-top: none !important;
                    }

                    tbody {
                        flex-direction: column;
                        display: flex;
                    }

                    tr {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid $color-grey-light;

                        &.sub {
                            order: 0;
                        }

                        &.excl {
                            order: 1;
                        }

                        &.totals-tax-summary {
                            order: 2;

                            .totals-tax-details {
                                padding-left: 33px;

                                li.mark {
                                    list-style: circle;
                                }
                            }
                        }

                        &.totals-tax-details {
                            order: 3;
                        }

                        &.incl {
                            order: 4;
                        }
                    }
                }
            }
        }
    }
}

.interactiv4_redsyspro-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;

    .interactiv4_redsyspro-logo-container {
        margin-right: 20px;
    }
}

@include media-breakpoint-down(md) {
    .checkout-index-index {
        .page-main {
            .checkout-container {
                .opc-estimated-wrapper {
                    display: block;
                    border-bottom: 1px solid $color-grey-light;
                    border-top: 1px solid $color-grey-light;
                    padding: 18px 15px;
                    float: left;
                    width: 100%;
                    margin-bottom: 25px;

                    .estimated-block {
                        font-size: 18px;
                        float: left;
                        font-weight: 700;

                        .estimated-label {
                            display: block;
                            margin: 0 0 5px;
                        }
                    }

                    .minicart-wrapper {
                        display: inline-block;
                        position: relative;
                        float: right;
                        margin-top: 10px;

                        .action.showcart {
                            background-color: inherit;
                            border: none;
                            cursor: pointer;

                            &:before {
                                content: '';
                                font-size: 35px;
                                height: 35px;
                                line-height: 35px;
                                width: 35px;
                                background: url(../images/icons/icon_cart.png) -40% center no-repeat;
                                display: inline-block;
                            }

                            .counter {
                                background-color: $color-orange;
                                color: $color-white;
                                display: inline-block;
                                float: right;
                                border-radius: 2px;
                                height: 25px;
                                line-height: 25px;
                                min-width: 25px;
                                padding: 0 3px;
                                margin: 5px 0;
                            }
                        }
                    }
                }

                .opc-wrapper {
                    @include make-col(12);
                }

                .opc-sidebar {
                    @include make-col(12);
                    bottom: 0;
                    left: 0;
                    min-width: 0;
                    position: fixed;
                    right: 0;
                    top: 0;
                    visibility: hidden;
                    z-index: 900;

                    .modal-inner-wrap {
                        background-color: $color-white;
                        height: 100%;
                        overflow-y: auto;
                        position: static;
                        -webkit-transform: translateX(100%);
                        transform: translateX(100%);
                        transition-duration: .3s;
                        -webkit-transition-property: -webkit-transform, visibility;
                        transition-property: transform, visibility;
                        transition-timing-function: ease-in-out;
                        width: auto;

                        .modal-header {
                            display: block;
                            position: absolute;
                            right: 0;
                            z-index: 1000;
                            border: none;
                        }
                    }

                    &._show {
                        visibility: visible;
                        -webkit-overflow-scrolling: touch;
                        overflow-x: hidden;
                        overflow-y: auto;

                        .modal-inner-wrap {
                            -webkit-transform: translateX(0);
                            transform: translateX(0);
                        }
                    }

                    .modal-content {
                        height: 100%;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .checkout-index-index {
        .page-header {

        }

        .page-main {
            .checkout-container {
                .opc {
                    &-progress-bar {
                        &-item {
                            display: inline;
                            width: 100%;
                            max-width: 50%;
                        }
                    }

                    &-wrapper {
                        .opc {
                            .step-content {
                                .delivery-date {
                                    .control {
                                        .datepickertext {
                                            max-width: 55%;
                                            line-height: 20px;
                                        }
                                    }
                                }
                            }

                            .checkout-shipping-address {
                                .shipping-address-item {
                                    width: 95%;
                                    display: block;
                                    -webkit-appearance: unset;
                                    -webkit-appearance: none;

                                    &:hover {
                                        -webkit-appearance: unset;
                                        -webkit-appearance: none;
                                    }

                                    &:active {
                                        -webkit-appearance: unset;
                                        -webkit-appearance: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
