@include media-breakpoint-down(xs) {
    .module-related--slider {
        .slick-arrow {
            &.slick-prev {
                left: 2px;
            }

            &.slick-next {
                right: 2px;
            }
        }
    }
}
