@mixin closeButton {
    color: $color-close;
    border: 1px solid $color-close;
    display: block;
    text-align: center;
    line-height: 20px;
    font-size: 19px;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    border-radius: 2rem;
    padding: 0;
    width: 24px;
    height: 24px;

    &::before {
        content: '\e616';
        font-family: 'Blank-Theme-Icons', serif;
        font-size: 30px;
        line-height: 22px;
        margin: -4px;
    }
}

@mixin topSearchBig {
    width: 100%;
    padding: 9px 15px;
    text-align: left;
    border: 1px solid;
    border-radius: 25px;
    font-size: 19px;
    background-color: #fff;
    font-family: $font-primary;
    color: $color-dark;
}

@mixin iconoLupa {
    color: $color-dark;
    top: 0;
    position: absolute;
}

@mixin iconoContact {
    content: "\f059";
    font-family: FontAwesome, serif;
    font-size: 20px;
}

@mixin temporaryAddress {
    margin: auto 0 0 auto;
    color: #ababab;
    display: flex;
    align-items: center;

    i {
        font-size: 16px;
        font-weight: lighter;
        margin-right: 3px;
    }
}
