.checkout-index-index .page-main .checkout-container .opc-wrapper .opc  {
    .checkout-shipping-address .shipping-address-item.selected-item .action-select-shipping-item {
        background: transparent;
        border: none;
    }

    .checkout-payment-method .payment-methods .payment-method .payment-method-content .actions-toolbar .primary .btn {
        padding: 14px 17px;
    }
}

.checkout-index-index {
    .df-fullscreen {
        top: 0 !important;
    }
}
