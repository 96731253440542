.page-title-wrapper {
  text-align: center;

  .page-title {
    vertical-align: top;
    margin-bottom: 0;
  }
  .print {
    display: block;
    margin-top: 10px;
    color: $color-white;
  }
}