.btn {
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: $button-primary-background;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 38px;
    padding: 0 18px;
    color: $button-primary-color;
    font-weight: $font-weight-semibold;
    border: 0;
    letter-spacing: 2px;

    &:hover {
        background-color: $color-brown;
    }

    &:active {
        background-color: $color-brown !important;
        -webkit-box-shadow: 0 0 0 0.2rem $color-brown !important;
        box-shadow: 0 0 0 0.2rem $color-brown !important;
    }

    &:focus {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        outline: none !important;
    }

    &.disabled {
        cursor: not-allowed;
        background-color: $color-dark;
    }

    &-link {
        padding-left: 0;
        padding-right: 0;
        color: $color-grey-alt;
        background: none;
        font-weight: $font-weight-normal;

        &:hover {
            background: none;
            color: $color-brown;
            text-decoration: none;
        }

        &:active, &:focus {
            background: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

    }

    &-sm {
        font-size: 10px;
        padding: 5px 15px;
        height: 31px;
        line-height: 20px;
    }

    &--primary {
        @extend .btn-primary;
    }

    &--secondary {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background-color: $button-secondary-background;
        color: $button-secondary-color;
        font-weight: $font-weight-normal;
        border: 1px solid $button-secondary-border;

        &:hover {
            background-color: $button-primary-background-hover;
            color: $button-primary-color-hover;
            border: 1px solid $button-primary-border-hover;
        }
    }

    &--tertiary {
        background-color: $button-tertiary-background;
        color: $button-tertiary-color;
        font-weight: $font-weight-normal;
        border: 1px solid $button-tertiary-border;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;

        &:hover {
            background-color: $button-tertiary-background-hover;
            color: $button-tertiary-color-hover;
            border-color: $button-tertiary-border-hover;
        }

        &.edit-address-link {
            border-radius: 5px;
        }
    }

    &--transparent {
        background: transparent;
        border: 1px solid $color-dark;
        color: $color-dark;

        &:hover {
            background: $button-tertiary-background-hover;
            color: $button-tertiary-color-hover;
            border-color: $button-tertiary-border-hover;
        }
    }

    &-link {
        color: $btn-link-color;

        &:hover {
            color: $btn-link-color-hover;
        }
    }

    &--link {
        @extend .btn-link;
        color: $btn-link-color;

        &:hover {
            color: $btn-link-color-hover;
        }
    }

    &.listtocart {
        position: relative;
        color: $color-white;
        background-color: $color-dark;
        border: none;
        border-radius: 5px;
        font-size: 10px;
        padding: 5px 15px 5px 15px;
        text-align: right;
        text-transform: uppercase;
        line-height: normal;
        font-weight: $font-weight-thin;
        letter-spacing: 1px;
        @include transition(all, 0.3s);

        &:hover {
            background-color: $color-brown;
            color: $color-white;
            @include transition(all, 0.3s);
        }
    }

    &.tocart {
        position: relative;
        color: $color-white;
        background-color: $button-primary-background;
        border: none;
        border-radius: 5px;
        font-size: 10px;
        padding: 5px 15px 5px 15px;
        text-align: right;
        text-transform: uppercase;
        line-height: normal;
        font-weight: $font-weight-thin;
        letter-spacing: 1px;
        @include transition(all, 0.3s);

        &:hover {
            background-color: $button-primary-background-hover;
            @include transition(all, 0.3s);
        }

        &.btn-lg {
            font-size: 12px;
            padding: 10px 20px 10px 20px;

            a {
                color: $color-white;
            }

            &:before {
                left: 18px;
                top: 11px;
            }
        }
    }

    &.btn-lg {
        @extend .tocart;
        padding: 5px 15px;
        text-align: unset;

        &:before {
            display: none;
        }
    }

    &.btn-small {
        font-size: 11px;
        padding: 0 14px;
    }
}

.buttons-container {
    padding-top: 15px;
    width: 100%;
    min-height: 15px;

    .one-btn-active {
        width: 100%;
    }

    .both-btn-actives {
        width: 48%;
    }

    .product-link-btn {
        margin: 10px 0 15px;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        text-transform: capitalize;

        a {
            &.link {
                color: $button-primary-color;
                background: $button-primary-background;
                display:block;
                padding: 5px 0;
                border: 1px solid transparent;
            }

            &.ghost {
                background: transparent;
                color: $button-primary-background;
                border-color : $button-primary-background;
            }
        }

        &:hover a {
            &.link {
                color: $color-white;
            }
            &.ghost {
                color: $button-primary-color;
                background-color: $button-primary-background;
                border-color: $button-primary-color;
            }
        }
    }
}

.button-image {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: column;

    &:hover .btn-img {
        color: $color-brown;
        border-color: $color-brown;
    }

    .btn-img {
        border: 3px solid;
        padding: 15px 30px 10px;
        font-size: 30px;
        text-transform: uppercase;
        cursor: pointer;
        -webkit-transition: all 300ms ease-in;
        -moz-transition: all 300ms ease-in;
        -o-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
        text-align: center;
        line-height: 35px;

        &-small {
            padding: 10px 20px;
        }
    }

    .dark {
        color: $color-dark;
        border-color: $color-dark;
    }

    .light {
        color: $color-white;
        border-color: $color-white;
    }

    .background-dark {
        background-color: #0000005c;
    }

    .background-light {
        background-color: #ffffff94;
    }

    &.left-top {
        justify-content: flex-start;
        margin-left: 20px;
        align-items: flex-start;
        padding: 20px;
    }

    &.center-top {
        margin-left: 20px;
        align-items: center;
        padding: 20px;
        justify-content: flex-start;
    }

    &.right-top {
        justify-content: flex-start;
        margin-left: 20px;
        align-items: flex-end;
        padding: 20px;
    }

    &.right-center {
        justify-content: center;
        margin-left: 20px;
        padding: 20px;
        align-items: flex-end;
    }

    &.right-bottom {
        justify-content: flex-end;
        margin-left: 20px;
        align-items: flex-end;
        padding: 20px;
    }

    &.center-bottom {
        justify-content: flex-end;
        margin-left: 20px;
        align-items: center;
        padding: 20px;
    }

    &.left-bottom {
        justify-content: flex-end;
        margin-left: 20px;
        align-items: flex-start;
        padding: 20px;
    }

    &.left-center {
        justify-content: center;
        margin-left: 20px;
        padding: 20px;
        align-items: flex-start;
    }
}

#df-results__wrapper__dffullscreen .buttons-container {
    padding: 0 16px;
}

#df-mobile__content__dffullscreen .buttons-container {
    padding: 0 8px;
}

@include media-breakpoint-down(lg) {
    .button-image {
        .btn-img {
            padding: 20px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .buttons-container .product-link-btn {
        font-size: 12px;
    }
}

@media (max-width: 470px) {
    .buttons-container .product-link-btn {
        font-size: 10px;
    }
}

@include media-breakpoint-down(xs) {
    .button-image {
        .btn-img {
            padding: 15px 30px 10px;
            max-width: 85%;
        }
    }
}
