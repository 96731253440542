.checkout-cart-index {
  .cart{
    &-empty {
      a {
        text-decoration: underline;
      }
    }
    &.item{
      &.messages{
        &.error{
          .message{
            &:before{
              margin: -12px 0 0;
              vertical-align: middle;
              display: inline-block;
              font-weight: normal;
              overflow: hidden;
              left: 0;
              top: 22px;
              width: 45px;
              position: absolute;
              text-align: center;
              font-size: 18px;
              line-height: 24px;
              content: "\f05e";
              font-family: $font-icons;
            }
          }
        }
      }
    }
  }
  .column.main {
    @extend %row-disable;
  }
  .cart-container {
    @include make-row();
    //.table-caption {
    //  display: none;
    //}
    .cart-summary {
      @include make-col-ready();
      @include make-col(4);
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
      &-content {
        background-color: $color-white;
        border: 1px solid $color-grey-light;
        padding: 20px 15px;

          .dummymessage {
              padding: 10px;
              background-color: $color-grey-light;
              color: $color-green-msg;
              width: 100%;

              ul{
                  li{
                      list-style: initial;
                      margin-left: 15px;
                  }
              }
              .minicart-data {
                  font-size: 0.9em;
              }

              .remainingForFree{
                  font-weight: bold;
              }
          }
      }
      &-dummysave {
        padding: 15px;
        text-align: right;
        .btn {
          background: transparent;
          border: 1px solid;
          color: $color-dark;
          &:hover {
            background: $button-primary-background-hover;
            color: $color-white;
          }
        }
      }
      .summary.title {
        @extend .h3;
      }
      .block {
        margin: 10px 0;
        .title[role=tab] {
          cursor: pointer;
          outline: none;
        }
      }
      .cart-totals {
        border-top: 1px solid $color-grey-light;
        table {
          th, td {
            border-bottom: none !important;
            font-weight: normal;
          }
          tbody {
            flex-direction: column;
            display: flex;
          }
          tr {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $color-grey-light;
            &.sub {
              order:0;
            }
            &.excl {
              order:1;
            }
            &.totals-tax-summary {
              order:2;
                .totals-tax-details {
                    padding-left: 33px;
                    li.mark {
                        list-style: circle;
                    }
                }
            }
            &.totals-tax-details {
              order:3;
            }
            &.incl {
              order:4;
            }
          }
        }
        .totals {
          .sub, .totals-tax {
            .mark {
              font-weight: $font-weight-normal;
            }
          }
        }
        .mark {
          background: none;
        }
        .amount {
          border-top: none;
          border-bottom: solid 1px $gray-200;
          text-align: right;
          //&:first-child{
          //  border-top: none;
          //}
        }
        .grand.totals.incl {
          .mark, .amount {
            padding-top: 20px;
          }
        }
      }
      .block.discount {
        margin: 25px 0;
        .title {
          padding-bottom: 5px;
          border-bottom: 1px solid;
          strong {
            display: block;
            position: relative;
            &:before {
              @extend .fa;
              @extend .fa-chevron-down;
              position: absolute;
              right: 0;
              top: 5px;
            }
          }
        }
        .content {
          margin-top: 10px;
        }
      }
      .checkout-methods-items {
        .multicheckout {
          margin-top: 20px;
          text-align: center;
          display: block;
        }
      }
      .printcart{
        text-align: right;
        padding-right: 5px;
        .action {
          text-transform: none;
          text-decoration: underline;
        }
      }
    }
    .form-cart {
      @include make-col-ready();
      @include make-col(8);
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
      .table-wrapper {
        thead {
          .col {
            &.price, &.qty, &.subtotal {
              text-align: right;
              .price {
                font-weight: $font-weight-normal;
              }
            }
          }
        }
        tbody {
          .item-info {
            .col {
              vertical-align: top;
              &.item {
                .product-item-photo {
                  display: table-cell;
                  max-width: 100%;
                  position: static;
                  vertical-align: top;
                  width: 1%;
                  padding-right: 0;
                  .product-image-container {
                    width: 110px;
                    display: inline-block;
                    max-width: 100%;
                    .product-image-wrapper {
                      height: 80px;
                      width: 80px;
                      padding: 0 !important;
                      display: block;
                      overflow: hidden;
                      .product-image-photo {
                        max-height: 80px;
                        max-width:80px;
                      }
                    }
                  }
                  img{
                    border-radius: 5px;
                    -webkit-box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
                    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
                    border: solid 1px $color-grey-light;
                    padding: 2px;
                  }
                }
                .product-item-details {
                  display: table-cell;
                  vertical-align: top;
                  white-space: normal;
                  width: 99%;
                  .product-item-name {
                    font-weight: $font-weight-regular;
                    margin: 5px 0;
                    float:left;
                    width: 100%;
                  }
                  .product-item-sku {
                    float: left;
                    width:100%;
                    display: block;
                    font-weight: 100;
                    font-size: 11px;
                    color: $color-grey-dark;
                  }
                }
              }
              &.qty {
                .label {
                  @extend .sr-only;
                }
                .input-text {
                  text-align: center;
                  width: 65px;
                  float:right;
                  @extend .form-control;
                }
              }
              &.price, &.qty, &.subtotal {
                text-align: right;
              }
              &.price, &.subtotal {
                font-size: 13px;
                line-height: 30px;
              }
              &.subtotal {
                font-weight: bold;
              }
            }
          }
          .item-actions {
            text-align: left;
            margin-top: -10px;
            z-index: 8;
            width: 100%;
            td {
              padding:0;
              border: 0;
            }
            .actions-toolbar {
              padding: 0 0 10px 12px;
              margin-bottom:10px;

            }
            .action.action-edit {
              display: none;
              z-index: 9;
            }
            .action.action-delete {
              font-size: 10px;
              z-index: 9;
              text-decoration: underline;
              display:inline-block;
              float:left;
            }
            .message {
              width: 76%;
              display:inline-block;
              margin: 0 0 10px 0;
            }
            .action + .action {
              &:before {
                display: none;
                content: '|';
                padding: 0 10px;
              }
            }
          }
        }
      }
      .toolbar {
        justify-content: center;
        .pages {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .main.actions {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        .primary {
          float: left;
          .continue{
            color: $color-dark;
            &:before{
              content: "\f104";
              font-family: $font-icons;
              padding-right: 10px;
              font-size: 15px;
              color: $color-dark;
              &:hover{
                color: $color-brown;
              }
            }
          }
        }
        .secondary {
          display: flex;
          align-items: center;
          .update{
            display: flex;
            align-items: center;
            &:before{
              content: "\f021";
              font-family: $font-icons;
              padding-right: 10px;
              font-size: 15px;
            }
          }
        }
        .action + .action {
          margin-left: 10px;
        }
      }
    }
    .block-cart-failed{
      @include make-col-ready();
      @include make-col(12);
      margin-bottom:40px;
      .block-title {
        padding-bottom: 20px;
      }
      .table-wrapper {
        thead {
          .col {
            &.price, &.qty, &.subtotal {
              text-align: right;
              .price {
                font-weight: $font-weight-normal;
              }
            }
          }
        }
        tbody {
          border-top: 2px solid $gray-300;
          .item-info {
            .col {
              vertical-align: top;
              padding: 0.75rem;
              margin-top:0.75rem;
              &.item {
                .product-item-photo {
                  display: table-cell;
                  max-width: 100%;
                  position: static;
                  vertical-align: top;
                  width: 1%;
                  padding-right: 0;
                  .product-image-container {
                    width: 110px;
                    display: inline-block;
                    max-width: 100%;
                    .product-image-wrapper {
                      height: 80px;
                      width: 80px;
                      padding: 0 !important;
                      display: block;
                      overflow: hidden;
                      .product-image-photo {
                        max-height: 80px;
                        max-width:80px;
                      }
                    }
                  }
                  img{
                    border-radius: 5px;
                    -webkit-box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
                    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.05);
                    border: solid 1px $color-grey-light;
                    padding: 2px;
                  }
                }
                .product-item-details {
                  display: table-cell;
                  vertical-align: top;
                  white-space: normal;
                  width: 99%;
                  .product-item-name {
                    font-weight: $font-weight-regular;
                    margin: 5px 0;
                    float:left;
                    width: 100%;
                  }
                  .product-item-sku {
                    float: left;
                    width:100%;
                    display: block;
                    font-weight: 100;
                    font-size: 11px;
                    color: $color-grey-dark;
                  }
                  .message{
                    padding: 12px 0;
                    font-size: 12px;
                    &.error{
                      color: $color-red;
                    }
                  }
                }
              }
              &.qty {
                .label {
                  @extend .sr-only;
                }
                .input-text {
                  text-align: center;
                  width: 50px;
                  float: right;
                  @extend .form-control;
                }
              }
              &.price, &.qty, &.subtotal {
                text-align: right;
              }
              &.price, &.subtotal {
                font-size: 13px;
                line-height: 30px;
              }
              &.subtotal {
                font-weight: bold;
              }
            }
          }
          .item-actions {
            text-align: left;
            margin-top: -10px;
            z-index: 8;
            width: 100%;
            td {
              padding:0;
              border: 0;
            }
            .actions-toolbar {
              padding: 0 0 10px 12px;
              margin-bottom:10px;
            }
            .action.action-edit {
              display: none;
              z-index: 9;
            }
            .action.action-delete {
              font-size: 10px;
              z-index: 9;
              text-decoration: underline;
              display:inline-block;
              float:left;
            }
            .message {
              width: 76%;
              display:inline-block;
              margin: 0 0 10px 0;
            }
            .action + .action {
              &:before {
                display: none;
                content: '|';
                padding: 0 10px;
              }
            }
          }
        }
      }
      .action-delete{
        background: transparent;
        border: 0;
        color: $color-dark-alt;
      }
      .actions-toolbar{
        float: left;
        .actions{
          margin: 20px 0;
          display:inline-block;
          margin-right:10px;
          &.remove-all {
            margin-right: 0;
          }
        }
      }
      .remove-all-form {
        width:auto;
        float:left;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .checkout-cart-index {
    .cart-container {
      .cart-summary {
        .cart-summary-content {
          .mark {
            white-space: normal;
          }
        }
      }
      .form-cart {
        .table-wrapper {
          tbody {
            .item-actions {
              .message {
                width: 69%;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .checkout-cart-index {
    .column.main {
      .cart-container {
        .cart-summary {
          @include make-col(12);
          -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;
          top: 0 !important;
          margin-bottom: 25px;
          .totals {
            .mark {
              white-space: normal;
            }
          }
        }
        .form-cart {
          @include make-col(12);
          -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;
          .main.actions {
            flex-direction: column;
            .primary {
              order: 1;
              margin: 0 auto 20px;
            }
            .secondary {
              order: 2;
              text-align: center;
              display: block;
              justify-content: space-between;
              .btn {
                font-size: 11px;
                margin-bottom: 40px;
              }
            }
          }
          .table-wrapper {
            tbody {
              .item-actions {
                .message {
                  width: 74%;
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .checkout-cart-index {
    .column.main {
      .cart-container {
        .cart-summary {
          @include make-col(12);
          -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;
          top: 0 !important;
          margin-bottom: 25px;
          .totals {
            .mark {
              white-space: normal;
            }
          }
        }
        .form-cart, .form-cart-failed {
          @include make-col(12);
          -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;
          .main.actions {
            flex-direction: column;
            .primary {
              order: 1;
              margin: 0 auto 20px;
            }
            .secondary {
              order: 2;
              text-align: center;
              display: block;
              justify-content: space-between;
              .btn {
                font-size: 11px;
                margin-bottom: 40px;
              }
            }
          }
          .table-wrapper {
            .table {
              .item .item-info .product-item-details .product-item-name {
                font-size: 13px;
              }
              thead {
                display: none;
              }
              tbody {
                .item-actions {
                  width: 100%;
                  float: left;
                  margin-top:0;
                  margin-bottom: 20px;
                  .message {
                    margin: 0;
                    width: 100%;
                  }
                }
              }
              .item {
                .item-info {
                  display: block;
                  .item {
                    display: block;
                    position: relative;
                    margin-top:0;
                    .product-item-photo {
                      display: inline-block;
                      max-width: 80px;
                      margin: 0 15px 0 0;
                      width: 80px;
                      height: auto;
                      img {
                        height: auto;
                      }
                    }
                    .product-item-details {
                      width: calc(100% - 100px);
                      display:inline-block;
                    }
                  }
                  .price {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    white-space: nowrap;
                    width: 25%;
                    font-size: 13px;
                    text-align: left;
                    &:before {
                      content: attr(data-th);
                      display: block;
                      padding-bottom: 10px;
                    }
                  }
                  .col {
                    &.price, &.subtotal {
                      line-height: inherit;
                    }
                    &.qty {
                      text-align: center;
                      box-sizing: border-box;
                      display: block;
                      float: left;
                      white-space: nowrap;
                      width: 42%;
                      font-size: 13px;
                      &:before {
                        content: attr(data-th);
                        display: block;
                        padding-bottom: 10px;
                      }
                      .input-text.qty {
                        margin: 0 auto;
                      }
                    }
                  }
                  .subtotal {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    white-space: nowrap;
                    width: 33%;
                    font-size: 13px;
                    text-align: center;
                    .price {
                      display: block;
                      width: 100%;
                      text-align: center;
                    }
                    &:before {
                      content: attr(data-th);
                      display: block;
                      padding-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
    .checkout-cart-index {
        .item {
            .messages {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }
}
