.module-footer {
    @include make-container();
    background-color: $color-black;
    text-align: center;
    > .module {
        &-inner {
            @include make-row();
            .footer {
                &__content {
                    @include make-col-ready();
                    padding: 130px 0;
                }
                &__logo {
                    height: fit-content;

                    & img {
                        max-height: 90px;
                        height: 100%;
                    }
                }
                &__links {
                    .list-inline {
                        &-item {
                            display: inline-block;
                            margin: 0 20px;
                        }
                    }
                }
            }
            .copyright {
                @include make-col-ready();
                border-top: 1px dashed rgba(255, 255, 255, 0.1);
                &__content {
                    padding: 18px 0;
                    .list-inline {
                        display: inline-block;
                        &-item {
                            margin: 0;
                            display: inline-block;
                            color: $color-dark-alt;
                            & + .list-inline-item:before {
                                content: '|';
                                margin: 0 20px;
                            }
                        }
                        & + .list-inline .list-inline-item:before {
                            content: '|';
                            margin: 0 20px;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .module-footer {
        > .module {
            &-inner {
                .footer {
                    &__content {
                        padding: 80px 0;
                    }
                }
                .copyright {
                    @include make-col-ready();
                    &__content {
                        .list-inline {
                            display: block;
                            &-item {
                                display: block;
                                padding-bottom: 5px;
                                & + .list-inline-item:before {
                                    display: none;
                                }
                            }
                            & + .list-inline .list-inline-item:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
