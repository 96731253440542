html {
  font-size: 14px;
}

body {
  color: $color-dark-light;
  font-family: $font-primary;
//  font-style: normal;
    font-weight: $font-weight-normal;
//  line-height: 1.42857;
//  font-size: 14px;
  background-color: $color-background;
  &.noscroll{
    overflow-y: hidden;
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: 1s all;
    opacity: 0.8;
  }
  .loading.show {
    opacity: 0.8;
  }
  .loading .spin {
    border: 3px solid #97928e;
    border-top-color: #000;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

ul, ol {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  li {
    list-style: none;
  }
}

img {
  max-width: 100%;
  width: auto;
}

div {
  outline: none;
}

a, button {
  outline: none;
  cursor: pointer;
  &:hover, &:focus {
    text-decoration: none;
    outline: none;
  }
}

a {
  color: $color-dark-alt;
  @include transition(color, 0.3s);
  &:hover {
    color: $color-brown;
    @include transition(color, 0.3s);
    &:before {
      color: $color-dark-alt;
    }
  }
}

strong {
  font-weight: $font-weight-regular;
}

.loader-spinner {
  display: none;
  text-align: center;
  position: absolute;
  width: 100%;

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;
    }

    div:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: $color-primary;
    }

    div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }

    div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }

    div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }

    div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }

    div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }

    div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }

    div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }

    div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }

    div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }

    div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }

    div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }

    div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

#section-form{
  .item.title {
    width: auto;
    float: left;
  }

  .item.content{
    padding-bottom: 90px;
  }
}

[v-cloak] {
  display: none !important;
}

.field-recaptcha {
    .field {
        .control {
            input {
                visibility: hidden;
            }
        }
    }
}

.mobile-plus {
    @extend .fa;
    @extend .fa-plus;
    font-size: 17px;
    margin-top: 17px;
    margin-left: auto;
    margin-right: 10px;
}
