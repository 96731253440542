.product__details {
    .module-tabs {
        .item {
            &.title {
                float: left;
                width: auto;
                margin: 0 -1px 0 0;

                .switch {
                    line-height: 40px;
                    text-decoration: none;
                    display: block;
                    position: relative;
                    z-index: 2;
                    border-bottom: none;
                    height: 40px;
                    margin: 1px 35px 1px 0px;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: $font-weight-regular;
                    color: $color-grey-dark;

                    &:after {
                        display: block;
                        content: '';
                        border-bottom: 1px solid $color-dark;
                        transform: scaleX(0);
                        transition: transform 250ms ease-in-out;
                    }

                    &:hover {
                        color: $color-dark;

                        &:after {
                            //-webkit-transform-origin: 0% 50%;
                            //transform-origin:0% 50%;
                            transform: scaleX(1);
                        }

                    }

                    .counter {
                        &:before {
                            content: '(';
                        }

                        &:after {
                            content: ')';
                        }
                    }
                }

                &.active {
                    .mobile-plus {
                        display: none;
                        @extend .fa;
                        @extend .fa-minus;
                    }

                    .switch {
                        padding-bottom: 2px;
                        color: $color-dark;

                        &:after {
                            content: '';
                            display: block;
                            border-bottom: 1px solid $color-dark;
                            transform: scaleX(1);
                        }
                    }
                }
            }

            &.content {
                margin-top: 40px;
                padding: 15px 0 0 0;
                border-top: 1px solid $color-grey;
                float: right;
                margin-left: -100%;
                width: 100%;
                font-size: 12px;

                .block {
                    &-title {
                        @extend .h4;
                    }
                }

                .additional-attributes {
                    margin-top: 0;
                    margin-bottom: 0;

                    &.table {
                        td {
                            border-top: 0;
                            border-bottom: 1px solid $color-grey;
                        }
                    }
                }
            }

            .mobile-plus {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .product__details {
        display: flex;

        .module-tabs {
            .item {
                &.title {
                    width: 100%;
                    display: inline-block;
                }

                &.active {
                    .tab-title {
                        .mobile-plus {
                            display: block;
                        }
                    }
                }

                &.content {
                    margin-top: 0;
                    padding: 0;
                    margin-left: 0;
                }

                .tab-title {
                    display: flex;

                    .mobile-plus {
                        display: block;
                        font-size: 12px;
                        margin-top: 14px;
                    }
                }
            }
        }
    }
}
